import React, { useEffect, useRef } from 'react'                // react components
import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'             // ...
import '@abb/abb-common-ux-react/styles.css'                          // ...
import '../../App.css'                                                   // ...
//import example from './Intro.jpg'
import Slider, { Range } from '../../../node_modules/rc-slider'


import { updateTopNavi, updateSimNavi, updatesSite, download } from '../../slices/siteSlice'         // ...

import * as ABB from '@abb/abb-common-ux-react'                       // ABB components

// import User_steps from './user_steps'
import { emptyStringToNull } from './../resources/utilities'

import 'rc-slider/assets/index.css'


// Import related fig
import fig_hoisting from './../mining_components/elements/fig_hoisting1.jpg'
import fig_dewatering from './../mining_components/elements/fig_dewatering.jpg'
import fig_comminution from './../mining_components/elements/fig_comminution.jpg'
import fig_crushing from './../mining_components/elements/fig_crushing.jpg'
import fig_transport from './../mining_components/elements/fig_transport.jpg'
import fig_ventilation from './../mining_components/elements/fig_ventilation.jpg'
import fig_drill from './../mining_components/elements/fig_drill.jpg'

import fig_storage from './../mining_components/elements/fig_storage.jpg'
import fig_wind from './../mining_components/elements/fig_wind.jpg'
import fig_solar from './../mining_components/elements/fig_solar.jpg'
import fig_grid from './../mining_components/elements/fig_grid.jpg'
import fig_dg from './../mining_components/elements/fig_dg.jpg'
import grid_design_map_demo from './../mining_components/elements/grid_design_map_demo.jpg'


import { GroupedBarChartTest, GroupedBarVolt, GroupedBarCurrent } from './gdGraphs'

import { load_loaddata_from_DS_backend, load_gendata_from_DS_backend } from '../../slices/siteSlice'
import {
    call_api_cable_assign_qdver_backend,
    call_api_grid_optimize_qdver_backend,
    call_api_steady_state_analysis_qdver_backend,
    call_api_data_conversion_qdver_backend,
    call_api_power_loss_qdver_backend
} from '../../slices/siteSlice'
import { findNonSerializableValue } from '@reduxjs/toolkit'


const GridDesignDemoPage = () => {
    // state variables
    const dispatch = useDispatch()
    const ident = useSelector(state => state.site.ident)
    const ex = useSelector(state => state.site.export)

    // content variables
    const id = ident.id

    // const grid = useSelector(state => state.site.grid)
    // const wind = useSelector(state => state.site.wind)
    // const BO = useSelector(state => state.site.basicOptimization)
    // // const mine_layout = useSelector(state => state.site.mine_layout)

    const gd_mine_demo = useSelector(state => state.site.gd_mine_demo)
    // The following data are from the DS module, not from the grid design module
    const grid = useSelector(state => state.site.grid)
    const wind = useSelector(state => state.site.wind)
    const BO = useSelector(state => state.site.basicOptimization)
    const mine_layout = useSelector(state => state.site.mine_layout)
    const mountedRef = useRef(false)

    //const activateTopNaviItem = item => {
    //    dispatch(updateTopNavi(item))
    //}

    // update data of site in backend
    const updateDataBackend = (site, update) => {
        dispatch(updatesSite({
            'id': id, 'updates': [
                { 'slice': site, ...update }
            ]
        }))
    }

    const downloadDocuments = (path_new, path_system) => {
        dispatch(download({ 'path_new': path_new, 'path_system': path_system }))
    }



    //// drawing pictures
    //const topGraphStates = useSelector(state => state.site.topGraphStates)


    // get data from D&S tool
    const load_loaddata_from_DS = (v) => {
        dispatch(load_loaddata_from_DS_backend(
            { 'all_data': v }
        ))
    }


    const load_gendata_from_DS = (v) => {
        dispatch(load_gendata_from_DS_backend(
            { 'all_data': v }
        ))
    }

    const pop_error = (v) => {
        alert("No data is available from D&S, please use the default values or input the values.")
    }
    const any_mine_element = (mine_layout.crushing_color == true || mine_layout.drilling_color == true || mine_layout.transport_color == true || mine_layout.dewatering_color == true ||
        mine_layout.hoisting_color == true || mine_layout.ventilation_color == true || mine_layout.comminution_color == true)

    const calc_ready = ((mine_layout.total_load_available == 'yes' && grid.load_series_selector !== null) || (mine_layout.add_elements == 'yes' && any_mine_element == true))

    const load_ready = (window.wind_series_selector !== null && grid.pv_series_selector !== null && mine_layout.total_load !== null && mine_layout.total_load.reduce((a, b) => a + b, 0) > 0)

    //const cable_ready = (mine_layout.crushing_color == true && mine_layout.drilling_color == true && mine_layout.transport_color == true && mine_layout.dewatering_color == true &&
    //mine_layout.hoisting_color == true && mine_layout.ventilation_color == true && mine_layout.comminution_color == true
    // BO.series_pv && BO.series_wind && BO.series_el && BO.series_diesel
    //)

    // button color change

    const color_hoisting = mine_layout.hoisting_color ? { border: '4px solid', borderColor: "rgba(255, 0, 0, 0.24)", borderRadius: '1em' } : { border: '4px solid', borderColor: "lightgray", borderRadius: '1em' }
    const color_dewatering = mine_layout.dewatering_color ? { border: '4px solid', borderColor: "rgba(255, 0, 0, 0.24)", borderRadius: '1em' } : { border: '4px solid', borderColor: "lightgray", borderRadius: '1em' }
    const color_rushing = mine_layout.crushing_color ? { border: '4px solid', borderColor: "rgba(255, 0, 0, 0.24)", borderRadius: '1em' } : { border: '4px solid', borderColor: "lightgray", borderRadius: '1em' }
    const color_drilling = mine_layout.drilling_color ? { border: '4px solid', borderColor: "rgba(255, 0, 0, 0.24)", borderRadius: '1em' } : { border: '4px solid', borderColor: "lightgray", borderRadius: '1em' }
    const color_transport = mine_layout.transport_color ? { border: '4px solid', borderColor: "rgba(255, 0, 0, 0.24)", borderRadius: '1em' } : { border: '4px solid', borderColor: "lightgray", borderRadius: '1em' }
    const color_ventilation = mine_layout.ventilation_color ? { border: '4px solid', borderColor: "rgba(255, 0, 0, 0.24)", borderRadius: '1em' } : { border: '4px solid', borderColor: "lightgray", borderRadius: '1em' }
    const color_comminution = mine_layout.comminution_color ? { border: '4px solid', borderColor: "rgba(255, 0, 0, 0.24)", borderRadius: '1em' } : { border: '4px solid', borderColor: "lightgray", borderRadius: '1em' }
    const color_res = (BO.pv_real_install || BO.wind_real_install || BO.wind_real_install == 0 || BO.pv_real_install == 0) ? { border: '4px solid', borderColor: "rgba(255, 0, 0, 0.24)", borderRadius: '1em' } : { border: '4px solid', borderColor: "lightgray", borderRadius: '1em' }
    // call different functions at the backend
    // Function 1
    const call_api_cable_assign_qdver = (v) => {
        dispatch(call_api_cable_assign_qdver_backend(
            { 'all_data': v }
        ))
    }
    const call_api_power_loss_qdver = (v) => {
        dispatch(call_api_power_loss_qdver_backend(
            { 'all_data': v }
        ))
    }

    // Function 2
    const call_api_grid_optimize_qdver = (v) => {
        dispatch(call_api_grid_optimize_qdver_backend(
            { 'all_data': v }
        ))
    }

    // Function 3
    const call_api_steady_state_analysis_qdver = (v) => {
        dispatch(call_api_steady_state_analysis_qdver_backend(
            { 'all_data': v }
        ))
    }

    // Function 4
    const call_api_data_conversion_qdver = (v) => {
        dispatch(call_api_data_conversion_qdver_backend(
            { 'id': id }
        ))
    }

    const cableCheck = () => {
        if (true) {
            call_api_cable_assign_qdver({ func_type: 'str', val: gd_mine_demo })
        } else {
            alert("Please tick all load types provided on the D&S page, including hoisting, drilling, ventilation, transport, dewatering, comminution, crushing. When performing optimization calculations, time-series data of the corresponding loads is required.")
        }

    }
    // useEffect(() => {
    //     load_loaddata_from_DS({ ds_results: mine_layout, gd_mine_var: gd_mine_demo })
    // }, [gd_mine_demo.ho_load_capacity_factor, gd_mine_demo.de_load_capacity_factor, gd_mine_demo.co_load_capacity_factor, gd_mine_demo.cr_load_capacity_factor, gd_mine_demo.ve_load_capacity_factor, gd_mine_demo.dr_load_capacity_factor, gd_mine_demo.tr_load_capacity_factor])

    useEffect(() => {
        if (mountedRef.current) {
            load_loaddata_from_DS({ ds_results: mine_layout, gd_mine_var: gd_mine_demo })
        } else {
            mountedRef.current = true;
        }

    }, [gd_mine_demo.ho_load_capacity_factor, gd_mine_demo.de_load_capacity_factor, gd_mine_demo.co_load_capacity_factor, gd_mine_demo.cr_load_capacity_factor, gd_mine_demo.ve_load_capacity_factor, gd_mine_demo.dr_load_capacity_factor, gd_mine_demo.tr_load_capacity_factor])

    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isGenButtonClicked, setIsGenButtonClicked] = useState(false);

    function handleClick() {
        setIsButtonClicked(true)
    }
    function handleGenClick() {
        setIsGenButtonClicked(true)
    }



    return (
        <div className="fullBox">
            <div className='contentBox'>
                <ABB.Heading level={1} text={"Microgrid design for mines"} />
                <p>This page provide a functionality to design the microgrid for industries located in rural areas, e.g., mines, etc. </p>

                <p>The propose of this method tries to determine the optimal voltage rating, cable types and the required capacitor capacity for a microgrid with a concrete topology. </p>

                <p>The design with the lowest building cost could be given by using the proposed method.</p>

                <ABB.Heading level={2} text='Introduction' />
                <p>The design of the grid is related to the situation of loads, diesel generators and renewables.</p>
                <p>Different extreme operational situations and mine-related equipment are considered during the design process. </p>
                <p>The design results can satisfy the needs of steady-state operation of the mine. </p>


                <ABB.Heading level={2} text='Info about loads' />

                <p> For loads:</p>
                <p> Different operational conditions, including maximum/minimum power consumptions, should be considered in the grid design process.</p>
                <p> Impact factors, including power factors (related to equipment type), capacity factors are used to estimated the energy consumpution under different scenarios.</p>

                <p>Data can be entered in three ways:</p>
                <p>1. Use the default values for grid design.</p>
                <p>2. Users enter data in the input box according to their knowledge. </p>
                <p>3. Click the red button to get the corresponding configuration results from the D&S module.</p>

                <div id='legend-button-block'>
                    {calc_ready && <div class='block' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Button id={isButtonClicked ? 'loadedclicked' : 'loaded'}
                            type="ghost" shape="pill" text="Data from D&S (For all loads)"
                            onClick={v => [load_loaddata_from_DS({ ds_results: mine_layout, gd_mine_var: gd_mine_demo }), handleClick(v)]} />
                    </div>}

                    {!calc_ready && <div class='block' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Button id="unloaded" type="ghost" shape="pill" text="Data from D&S (For all loads)" onClick={pop_error} />
                    </div>}
                    <div id='legend-container'><div id='spa'>no data available</div>
                        <div id='spa2'>data available</div>
                        <div id='spa3'>data loaded</div></div>
                </div>






                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}>Hoisting</p>
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <img id={isButtonClicked ? 'loadedclicked' : 'loaded'} src={fig_hoisting} style={color_hoisting} alt="test" width='50px' height='50px' onClick={v => []} />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Name'}
                            value={gd_mine_demo.ho_load_name}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'ho_load_name', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Type'}
                            value={gd_mine_demo.ho_load_type}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'ho_load_type', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Max power peak (MW)'}
                            value={gd_mine_demo.ho_load_max_active_load_mw}
                            onValueChange={v => v > 60 ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'ho_load_max_active_load_mw', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                        <span id='spanid' > range (0-60)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.ho_load_max_active_load_mw}
                            onChange={v => [updateDataBackend('gd_mine_demo', { key: 'ho_load_max_active_load_mw', value: emptyStringToNull(parseFloat(v)) })]}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0} max={60} steps={0.001} label="Max power peak (MW)" ticks={9} //max range not defined yet
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Min power peak (MW)'}
                            value={gd_mine_demo.ho_load_min_active_load_mw}
                            onValueChange={v => (v < -5 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'ho_load_min_active_load_mw', value: ((v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization(wind, generation, grid)} */
                        /><span id='spanid' > range (-5-0)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.ho_load_min_active_load_mw}
                            onChange={v => updateDataBackend('gd_mine_demo', { key: 'ho_load_min_active_load_mw', value: emptyStringToNull(parseFloat(v)) })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={-5} max={0} step={0.001} label="Min power peak (MW)" ticks={9}
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number' id='test'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Power factor'}
                            value={gd_mine_demo.ho_load_power_factor}
                            onValueChange={v => (v < 0.7 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'ho_load_power_factor', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0.7-1)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.ho_load_power_factor}
                            onChange={v => updateDataBackend('gd_mine_demo', { key: 'ho_load_power_factor', value: v })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0.7} max={1} step={0.001} label="power factor" ticks={10}
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Capacity factor'}
                            value={gd_mine_demo.ho_load_capacity_factor}
                            // onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'ho_load_capacity_factor', value: emptyStringToNull(parseFloat(v)) })}
                            onValueChange={v => (v < 0 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'ho_load_capacity_factor', value: emptyStringToNull(parseFloat(v)) })}
                        // onKeyUp={load_loaddata_from_DS({ ds_results: mine_layout, gd_mine_var: gd_mine_demo })}
                        // onLostFocus = {() => callOptimization( wind, generation, grid)}
                        /><span id='spanid' > range (0.5-1)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.ho_load_capacity_factor}
                            onChange={v => (updateDataBackend('gd_mine_demo', { key: 'ho_load_capacity_factor', value: v }))}
                            // onKeyUp = {load_adata_from_DS({ ds_results: mine_layout, gd_mine_var:gd_mine_demo })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0.5} max={1} step={0.001} label="power factor" ticks={10}
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'X (km)'}
                            value={gd_mine_demo.ho_load_x}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'ho_load_x', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Y (km)'}
                            value={gd_mine_demo.ho_load_y}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'ho_load_y', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Z (km)'}
                            value={gd_mine_demo.ho_load_z}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'ho_load_z', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}>Dewatering</p>
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <img id={isButtonClicked ? 'loadedclicked' : 'loaded'} src={fig_dewatering} style={color_dewatering} alt="test" width='50px' height='50px' onClick={v => []} />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Name'}
                            value={gd_mine_demo.de_load_name}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'de_load_name', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Type'}
                            value={gd_mine_demo.de_load_type}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'de_load_type', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Max power peak (MW)'}
                            value={gd_mine_demo.de_load_max_active_load_mw}
                            onValueChange={v => v > 60 ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'de_load_max_active_load_mw', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0-60)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.de_load_max_active_load_mw}
                            onChange={v => [updateDataBackend('gd_mine_demo', { key: 'de_load_max_active_load_mw', value: emptyStringToNull(parseFloat(v)) })]}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0} max={60} steps={0.001} label="Max power peak (MW)" ticks={9} //max range not defined yet
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Min power peak (MW)'}
                            value={gd_mine_demo.de_load_min_active_load_mw}
                            onValueChange={v => (v < -5 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'de_load_min_active_load_mw', value: v })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (-5-0)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.de_load_min_active_load_mw}
                            onChange={v => updateDataBackend('gd_mine_demo', { key: 'de_load_min_active_load_mw', value: emptyStringToNull(parseFloat(v)) })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={-5} max={0} step={0.001} label="Min power peak (MW)" ticks={9}
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Power factor'}
                            value={gd_mine_demo.de_load_power_factor}
                            onValueChange={v => (v < 0.7 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'de_load_power_factor', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0.7-1)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.de_load_power_factor}
                            onChange={v => updateDataBackend('gd_mine_demo', { key: 'de_load_power_factor', value: v })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0.7} max={1} step={0.001} label="power factor" ticks={10}
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Capacity factor'}
                            value={gd_mine_demo.de_load_capacity_factor}
                            onValueChange={v => (v < 0 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'de_load_capacity_factor', value: emptyStringToNull(parseFloat(v)) })}
                        // onKeyUp={e => { { load_loaddata_from_DS({ ds_results: mine_layout, gd_mine_var: gd_mine_demo }) } }}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0.5-1)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.de_load_capacity_factor}
                            onChange={v => (updateDataBackend('gd_mine_demo', { key: 'de_load_capacity_factor', value: v }))}
                            // onKeyUp = {load_adata_from_DS({ ds_results: mine_layout, gd_mine_var:gd_mine_demo })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0.5} max={1} step={0.001} label="Capacity factor" ticks={10}
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'X (km)'}
                            value={gd_mine_demo.de_load_x}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'de_load_x', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Y (km)'}
                            value={gd_mine_demo.de_load_y}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'de_load_y', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Z (km)'}
                            value={gd_mine_demo.de_load_z}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'de_load_z', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}> Comminution </p>
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <img id={isButtonClicked ? 'loadedclicked' : 'loaded'} src={fig_comminution} style={color_comminution} alt="test" width='50px' height='50px' onClick={v => []} />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Name'}
                            value={gd_mine_demo.co_load_name}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'co_load_name', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Type'}
                            value={gd_mine_demo.co_load_type}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'co_load_type', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Max power peak (MW)'}
                            value={gd_mine_demo.co_load_max_active_load_mw}
                            onValueChange={v => v > 60 ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'co_load_max_active_load_mw', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0-60)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.co_load_max_active_load_mw}
                            onChange={v => [updateDataBackend('gd_mine_demo', { key: 'co_load_max_active_load_mw', value: emptyStringToNull(parseFloat(v)) })]}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0} max={60} steps={0.001} label="Max power peak (MW)" ticks={9} //max range not defined yet
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Min power peak (MW)'}
                            value={gd_mine_demo.co_load_min_active_load_mw}
                            onValueChange={v => (v < -5 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'co_load_min_active_load_mw', value: v })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (-5-0)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.co_load_min_active_load_mw}
                            onChange={v => updateDataBackend('gd_mine_demo', { key: 'co_load_min_active_load_mw', value: emptyStringToNull(parseFloat(v)) })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={-5} max={0} step={0.001} label="Min power peak (MW)" ticks={9}
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Power factor'}
                            value={gd_mine_demo.co_load_power_factor}
                            onValueChange={v => (v < 0.7 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'co_load_power_factor', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0.7-1)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.co_load_power_factor}
                            onChange={v => updateDataBackend('gd_mine_demo', { key: 'co_load_power_factor', value: v })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0.7} max={1} step={0.001} label="power factor" ticks={10}
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Capacity factor'}
                            value={gd_mine_demo.co_load_capacity_factor}
                            onValueChange={v => (v < 0 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'co_load_capacity_factor', value: emptyStringToNull(parseFloat(v)) })}
                        // onKeyUp={e => { { load_loaddata_from_DS({ ds_results: mine_layout, gd_mine_var: gd_mine_demo }) } }}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0.5-1)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.co_load_capacity_factor}
                            onChange={v => (updateDataBackend('gd_mine_demo', { key: 'co_load_capacity_factor', value: v }))}
                            // onKeyUp = {load_adata_from_DS({ ds_results: mine_layout, gd_mine_var:gd_mine_demo })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0.5} max={1} step={0.001} label="Capacity factor" ticks={10}
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'X (km)'}
                            value={gd_mine_demo.co_load_x}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'co_load_x', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Y (km)'}
                            value={gd_mine_demo.co_load_y}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'co_load_y', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Z (km)'}
                            value={gd_mine_demo.co_load_z}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'co_load_z', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}> Crushing </p>
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <img id={isButtonClicked ? 'loadedclicked' : 'loaded'} src={fig_crushing} style={color_rushing} alt="test" width='50px' height='50px' onClick={v => []} />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Name'}
                            value={gd_mine_demo.cr_load_name}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'cr_load_name', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Type'}
                            value={gd_mine_demo.cr_load_type}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'cr_load_type', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Max power peak (MW)'}
                            value={gd_mine_demo.cr_load_max_active_load_mw}
                            onValueChange={v => v > 60 ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'cr_load_max_active_load_mw', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0-60)</span><Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.cr_load_max_active_load_mw}
                            onChange={v => [updateDataBackend('gd_mine_demo', { key: 'cr_load_max_active_load_mw', value: emptyStringToNull(parseFloat(v)) })]}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0} max={60} steps={0.001} label="Max power peak (MW)" ticks={9} //max range not defined yet
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Min power peak (MW)'}
                            value={gd_mine_demo.cr_load_min_active_load_mw}
                            onValueChange={v => (v < -5 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'cr_load_min_active_load_mw', value: v })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (-5-0)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.cr_load_min_active_load_mw}
                            onChange={v => updateDataBackend('gd_mine_demo', { key: 'cr_load_min_active_load_mw', value: emptyStringToNull(parseFloat(v)) })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={-5} max={0} step={0.001} label="Min power peak (MW)" ticks={9}
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Power factor'}
                            value={gd_mine_demo.cr_load_power_factor}
                            onValueChange={v => (v < 0.7 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'cr_load_power_factor', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0.7-1)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.cr_load_power_factor}
                            onChange={v => updateDataBackend('gd_mine_demo', { key: 'cr_load_power_factor', value: v })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0.7} max={1} step={0.001} label="power factor" ticks={10}
                        />
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Capacity factor'}
                            value={gd_mine_demo.cr_load_capacity_factor}
                            onValueChange={v => (v < 0 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'cr_load_capacity_factor', value: emptyStringToNull(parseFloat(v)) })}
                        // onKeyUp={e => { { load_loaddata_from_DS({ ds_results: mine_layout, gd_mine_var: gd_mine_demo }) } }}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0.5-1)</span><Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.cr_load_capacity_factor}
                            onChange={v => (updateDataBackend('gd_mine_demo', { key: 'cr_load_capacity_factor', value: v }))}
                            // onKeyUp = {load_adata_from_DS({ ds_results: mine_layout, gd_mine_var:gd_mine_demo })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0.5} max={1} step={0.001} label="Capacity factor" ticks={10}
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'X (km)'}
                            value={gd_mine_demo.cr_load_x}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'cr_load_x', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Y (km)'}
                            value={gd_mine_demo.cr_load_y}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'cr_load_y', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Z (km)'}
                            value={gd_mine_demo.cr_load_z}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'cr_load_z', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}> Ventilation </p>
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <img id={isButtonClicked ? 'loadedclicked' : 'loaded'} src={fig_ventilation} style={color_ventilation} alt="test" width='50px' height='50px' onClick={v => []} />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Name'}
                            value={gd_mine_demo.ve_load_name}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 've_load_name', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Type'}
                            value={gd_mine_demo.ve_load_type}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 've_load_type', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Max power peak (MW)'}
                            value={gd_mine_demo.ve_load_max_active_load_mw}
                            onValueChange={v => v > 60 ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 've_load_max_active_load_mw', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0-60)</span><Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.ve_load_max_active_load_mw}
                            onChange={v => [updateDataBackend('gd_mine_demo', { key: 've_load_max_active_load_mw', value: emptyStringToNull(parseFloat(v)) })]}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0} max={60} steps={0.001} label="Max power peak (MW)" ticks={9} //max range not defined yet
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Min power peak (MW)'}
                            value={gd_mine_demo.ve_load_min_active_load_mw}
                            onValueChange={v => (v < -5 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 've_load_min_active_load_mw', value: v })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (-5-0)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.ve_load_min_active_load_mw}
                            onChange={v => updateDataBackend('gd_mine_demo', { key: 've_load_min_active_load_mw', value: emptyStringToNull(parseFloat(v)) })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={-5} max={0} step={0.001} label="Min power peak (MW)" ticks={9}
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Power factor'}
                            value={gd_mine_demo.ve_load_power_factor}
                            onValueChange={v => (v < 0.7 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 've_load_power_factor', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0.7-1)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.ve_load_power_factor}
                            onChange={v => updateDataBackend('gd_mine_demo', { key: 've_load_power_factor', value: v })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0.7} max={1} step={0.001} label="power factor" ticks={10}
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Capacity factor'}
                            value={gd_mine_demo.ve_load_capacity_factor}
                            onValueChange={v => (v < 0 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 've_load_capacity_factor', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0.5-1)</span><Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.ve_load_capacity_factor}
                            onChange={v => (updateDataBackend('gd_mine_demo', { key: 've_load_capacity_factor', value: v }))}
                            // onKeyUp = {load_adata_from_DS({ ds_results: mine_layout, gd_mine_var:gd_mine_demo })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0.5} max={1} step={0.001} label="Capacity factor" ticks={10}
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'X (km)'}
                            value={gd_mine_demo.ve_load_x}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 've_load_x', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Y (km)'}
                            value={gd_mine_demo.ve_load_y}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 've_load_y', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Z (km)'}
                            value={gd_mine_demo.ve_load_z}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 've_load_z', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}> Drilling </p>
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <img id={isButtonClicked ? 'loadedclicked' : 'loaded'} src={fig_drill} style={color_drilling} alt="test" width='50px' height='50px' onClick={v => []} />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Name'}
                            value={gd_mine_demo.dr_load_name}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'dr_load_name', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Type'}
                            value={gd_mine_demo.dr_load_type}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'dr_load_type', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Max power peak (MW)'}
                            value={gd_mine_demo.dr_load_max_active_load_mw}
                            onValueChange={v => v > 60 ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'dr_load_max_active_load_mw', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0-60)</span><Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.dr_load_max_active_load_mw}
                            onChange={v => [updateDataBackend('gd_mine_demo', { key: 'dr_load_max_active_load_mw', value: emptyStringToNull(parseFloat(v)) })]}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0} max={60} steps={0.001} label="Max power peak (MW)" ticks={9} //max range not defined yet
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Min power peak (MW)'}
                            value={gd_mine_demo.dr_load_min_active_load_mw}
                            onValueChange={v => (v < -5 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'dr_load_min_active_load_mw', value: v })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (-5-0)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.dr_load_min_active_load_mw}
                            onChange={v => updateDataBackend('gd_mine_demo', { key: 'dr_load_min_active_load_mw', value: emptyStringToNull(parseFloat(v)) })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={-5} max={0} step={0.001} label="Min power peak (MW)" ticks={9}
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Power factor'}
                            value={gd_mine_demo.dr_load_power_factor}
                            onValueChange={v => (v < 0.7 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'dr_load_power_factor', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0.7-1)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.dr_load_power_factor}
                            onChange={v => updateDataBackend('gd_mine_demo', { key: 'dr_load_power_factor', value: v })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0.7} max={1} step={0.001} label="power factor" ticks={10}
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Capacity factor'}
                            value={gd_mine_demo.dr_load_capacity_factor}
                            onValueChange={v => (v < 0 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'dr_load_capacity_factor', value: emptyStringToNull(parseFloat(v)) })}

                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0.5-1)</span><Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.dr_load_capacity_factor}
                            onChange={v => (updateDataBackend('gd_mine_demo', { key: 'dr_load_capacity_factor', value: v }))}
                            // onKeyUp = {load_adata_from_DS({ ds_results: mine_layout, gd_mine_var:gd_mine_demo })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0.5} max={1} step={0.001} label="Capacity factor" ticks={10}
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'X (km)'}
                            value={gd_mine_demo.dr_load_x}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'dr_load_x', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Y (km)'}
                            value={gd_mine_demo.dr_load_y}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'dr_load_y', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Z (km)'}
                            value={gd_mine_demo.dr_load_z}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'dr_load_z', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}> Transport </p>
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <img id={isButtonClicked ? 'loadedclicked' : 'loaded'} src={fig_transport} style={color_transport} alt="test" width='50px' height='50px' onClick={v => []} />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Name'}
                            value={gd_mine_demo.tr_load_name}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'tr_load_name', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Type'}
                            value={gd_mine_demo.tr_load_type}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'tr_load_type', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Max power peak (MW)'}
                            value={gd_mine_demo.tr_load_max_active_load_mw}
                            onValueChange={v => v > 60 ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'tr_load_max_active_load_mw', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0-60)</span><Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.tr_load_max_active_load_mw}
                            onChange={v => [updateDataBackend('gd_mine_demo', { key: 'tr_load_max_active_load_mw', value: emptyStringToNull(parseFloat(v)) })]}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0} max={60} steps={0.001} label="Max power peak (MW)" ticks={9} //max range not defined yet
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Min power peak (MW)'}
                            value={gd_mine_demo.tr_load_min_active_load_mw}
                            onValueChange={v => (v < -5 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'tr_load_min_active_load_mw', value: v })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (-5-0)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.tr_load_min_active_load_mw}
                            onChange={v => updateDataBackend('gd_mine_demo', { key: 'tr_load_min_active_load_mw', value: emptyStringToNull(parseFloat(v)) })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={-5} max={0} step={0.001} label="Min power peak (MW)" ticks={9}
                        />

                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Power factor'}
                            value={gd_mine_demo.tr_load_power_factor}
                            onValueChange={v => (v < 0.7 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'tr_load_power_factor', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0.7-1)</span>
                        <Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.tr_load_power_factor}
                            onChange={v => updateDataBackend('gd_mine_demo', { key: 'tr_load_power_factor', value: v })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0.7} max={1} step={0.001} label="power factor" ticks={10}
                        />
                    </div>
                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Capacity factor'}
                            value={gd_mine_demo.tr_load_capacity_factor}
                            onValueChange={v => (v < 0 || v > 1) ? alert('Enter the value in range') : updateDataBackend('gd_mine_demo', { key: 'tr_load_capacity_factor', value: emptyStringToNull(parseFloat(v)) })}

                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        /><span id='spanid' > range (0.5-1)</span><Slider style={{ height: "15px", 'width': '170px', backgroundColor: "transparent", 'marginTop': '3px', 'marginLeft': '2px', 'marginRight': '64px' }}
                            value={gd_mine_demo.tr_load_capacity_factor}
                            onChange={v => (updateDataBackend('gd_mine_demo', { key: 'tr_load_capacity_factor', value: v }))}
                            // onKeyUp = {load_adata_from_DS({ ds_results: mine_layout, gd_mine_var:gd_mine_demo })}
                            // onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                            min={0.5} max={1} step={0.001} label="Capacity factor" ticks={10}
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'X (km)'}
                            value={gd_mine_demo.tr_load_x}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'tr_load_x', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Y (km)'}
                            value={gd_mine_demo.tr_load_y}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'tr_load_y', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Z (km)'}
                            value={gd_mine_demo.tr_load_z}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'tr_load_z', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>
                </div>

                <ABB.Heading level={2} text='Info about generators' />
                <p> For generators:</p>
                <p> The capacity of different generator resources are considered in the cable design.</p>
                <p> Impact factors, including power factors (related to equipment type), capacity factors are used to estimated the energy consumpution under different scenarios.</p>
                {/* load_ready const and && condition update (dekikan)*/}
                {(BO.pv_real_install || BO.wind_real_install || BO.wind_real_install == 0 || BO.pv_real_install == 0) && <div style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                    <ABB.Button id={isGenButtonClicked ? 'gen--loadedclicked' : 'gen--loaded'}
                        type="ghost" shape="pill" text="Data from D&S (For all generators)" onClick={
                            v => [load_gendata_from_DS({ BO: BO, grid: grid, gd_mine_var: gd_mine_demo, ds_results: mine_layout }), handleGenClick(v)]
                        } />
                </div>}

                {!(BO.pv_real_install || BO.wind_real_install || BO.wind_real_install == 0 || BO.pv_real_install == 0) && <div style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                    <ABB.Button id='gen--unloaded' type="ghost" shape="pill" text="Data from D&S (For all generators)" onClick={pop_error} />
                </div>}

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}> External Grid </p>
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <img id={isGenButtonClicked ? 'gen--loadedclicked' : 'gen--loaded'} src={fig_grid} style={color_res} alt="test" width='50px' height='50px' onClick={v => []} />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Name'}
                            value={gd_mine_demo.ext_grid_name}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'ext_grid_name', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Type'}
                            value={gd_mine_demo.ext_grid_type}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'ext_grid_type', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Capacity (MVA)'}
                            value={gd_mine_demo.ext_grid_capacity_mva}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'ext_grid_capacity_mva', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'X (km)'}
                            value={gd_mine_demo.ext_grid_x}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'ext_grid_x', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Y (km)'}
                            value={gd_mine_demo.ext_grid_y}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'ext_grid_y', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Z (km)'}
                            value={gd_mine_demo.ext_grid_z}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'ext_grid_z', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}> Diesel generator</p>
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <img id={isGenButtonClicked ? 'gen--loadedclicked' : 'gen--loaded'} style={color_res} src={fig_dg} alt="test" width='50px' height='50px' onClick={v => []} />
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Name'}
                            value={gd_mine_demo.generator_name}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'generator_name', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Type'}
                            value={gd_mine_demo.generator_type}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'generator_type', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Capacity (MVA)'}
                            value={gd_mine_demo.generator_capacity_mva}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'generator_capacity_mva', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />

                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'X (km)'}
                            value={gd_mine_demo.generator_x}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'generator_x', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Y (km)'}
                            value={gd_mine_demo.generator_y}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'generator_y', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Z (km)'}
                            value={gd_mine_demo.generator_z}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'generator_z', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}> Wind farm </p>
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <img id={isGenButtonClicked ? 'gen--loadedclicked' : 'gen--loaded'} style={color_res} src={fig_wind} alt="test" width='50px' height='50px' onClick={v => []} />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Name'}
                            value={gd_mine_demo.wind_name}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'wind_name', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Type'}
                            value={gd_mine_demo.wind_type}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'wind_type', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Max Output (MW)'}
                            value={gd_mine_demo.wind_max_output_mw}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'wind_max_output_mw', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Min Output (MW)'}
                            value={gd_mine_demo.wind_min_output_mw}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'wind_min_output_mw', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'X (km)'}
                            value={gd_mine_demo.wind_x}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'wind_x', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Y (km)'}
                            value={gd_mine_demo.wind_y}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'wind_y', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Z (km)'}
                            value={gd_mine_demo.wind_z}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'wind_z', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}> Solar power </p>
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <img id={isGenButtonClicked ? 'gen--loadedclicked' : 'gen--loaded'} style={color_res} src={fig_solar} alt="test" width='50px' height='50px' onClick={v => []} />
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Name'}
                            value={gd_mine_demo.solar_name}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'solar_name', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Type'}
                            value={gd_mine_demo.solar_type}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'solar_type', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Max Output (MW)'}
                            value={gd_mine_demo.solar_max_output_mw}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'solar_max_output_mw', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Min Output (MW)'}
                            value={gd_mine_demo.solar_min_output_mw}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'solar_min_output_mw', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'X (km)'}
                            value={gd_mine_demo.solar_x}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'solar_x', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Y (km)'}
                            value={gd_mine_demo.solar_y}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'solar_y', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Z (km)'}
                            value={gd_mine_demo.solar_z}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'solar_z', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}> Battery storage </p>
                    </div>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <img src={fig_storage} alt="test" width='50px' height='50px' onClick={v => []} />
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Name'}
                            value={gd_mine_demo.battery_name}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'battery_name', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='text'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Type'}
                            value={gd_mine_demo.battery_type}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'battery_type', value: emptyStringToNull(v) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Max Output (MW)'}
                            value={gd_mine_demo.battery_max_output_mw}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'battery_max_output_mw', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Min Output (MW)'}
                            value={gd_mine_demo.battery_min_output_mw}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'battery_min_output_mw', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'X (km)'}
                            value={gd_mine_demo.battery_x}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'battery_x', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Y (km)'}
                            value={gd_mine_demo.battery_y}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'battery_y', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Z (km)'}
                            value={gd_mine_demo.battery_z}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'battery_z', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                </div>

                <ABB.Heading level={2} text='Info about grid layout' />

                <p> In this section, the users could drag the draggable elements on the canvas. </p>
                <p> The locations and connections of different devices are determined by users. </p>
                <p> The length of lines and the topology are determined automatically. </p>
                <p> This function will be developed in the furture.</p>

                <img src={grid_design_map_demo} alt="grid_design_map_demo" width='1351px' height='754px' onClick={v => []} />


                <p> Find the available cable set for each line under different scenarios. </p>



                <div className='rows'>
                    <div style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Button type="primary-red" shape="pill" text="Cable assignment" onClick={v => cableCheck(v)} />
                    </div>

                    <div className='row' style={{ 'width': '500px', 'margin-top': '14px', 'margin-left': '100px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Status: {'Case:' + id + '  ' + gd_mine_demo.indicator_cable_assign_finish}  </div>
                    </div>
                </div>



                <div className='rows'>
                    <div style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Button type="primary-red" shape="pill" text="Power loss estimation" onClick={
                            v => call_api_power_loss_qdver({ BO: BO, grid: grid, ds_results: mine_layout, gd_mine_var: gd_mine_demo })
                        } />
                    </div>

                    <div className='row' style={{ 'width': '500px', 'margin-top': '14px', 'margin-left': '100px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Status: {'Case:' + id + '  ' + gd_mine_demo.indicator_power_loss_calculation_finish}  </div>
                    </div>
                </div>



                <ABB.Heading level={2} text='Info about material prices' />

                <p>Slide bar might be needed in the future for this part. </p>


                <div className='rows'>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '20px', 'margin-right': '20px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Copper price (Euro/kg)'}
                            value={gd_mine_demo.Cu_euro_kg}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'Cu_euro_kg', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '20px', 'margin-right': '20px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'Aluminium price (Euro/kg)'}
                            value={gd_mine_demo.Al_euro_kg}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'Al_euro_kg', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                    <div className='row' style={{ 'width': '170px', 'margin-top': '10px', 'margin-left': '20px', 'margin-right': '20px' }}>
                        <ABB.Input required={true} dataType='number'
                            /*style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}*/
                            label={'XLPE price (Euro/kg)'}
                            value={gd_mine_demo.XLPE_euro_kg}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'XLPE_euro_kg', value: emptyStringToNull(parseFloat(v)) })}
                        /* onKeyUp={e => {
                            if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                        }}
                        onLostFocus = {() => callOptimization( wind, generation, grid)} */
                        />
                    </div>

                </div>


                <ABB.Heading level={2} text='Execute grid design' />
                <p>After entering all the required data, click the red button to execute the microgrid design function.</p>

                <div style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                    <ABB.Button type="primary-red" shape="pill" text="Cost-based optimization"
                        onClick={() => {
                            const result = call_api_grid_optimize_qdver({ func_type: 'str', val: gd_mine_demo });
                            if (gd_mine_demo.indicator_optimal_solution_exist === false) {
                                alert('No feasible solution for this case. More cable info is required.');
                            }
                        }} />
                </div>

                <p>The results include four part: The optimal voltage rating, the cable design results of lines connecting to loads and generators, the steady-state anaylsis results based on the design results.</p>

                {/* Results exhibition test */}
                <ABB.Heading level={3} text='Result 1: The optimal voltage rating' />

                <div className='gd_landing_bottom' style={{ 'background': 'white' }}>
                    <div className="content_res"> Optimal Voltage Rating: {gd_mine_demo.optimal_voltage_rating} kV  </div>
                </div>

                <ABB.Heading level={3} text='Result 2: Cable design results - For loads' />

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '2px', 'margin-bottom': '2px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}>Hoisting</p>
                    </div>

                    <div className='row' style={{ 'width': '500px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Type: {gd_mine_demo.ho_load_cable_type}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Length: {gd_mine_demo.ho_load_line_length} km </div>
                    </div>

                    <div className='row' style={{ 'width': '200px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Voltage rating: {gd_mine_demo.optimal_voltage_rating} kV   </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Cost: {gd_mine_demo.ho_load_cable_cost} Euro  </div>
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >

                        <div className="content_gd_results">  Material: {gd_mine_demo.ho_load_cable_material}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Opex per year: {gd_mine_demo.ho_load_cable_opex_per_year} Euro </div>
                    </div>

                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '2px', 'margin-bottom': '2px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}>Dewatering</p>
                    </div>

                    <div className='row' style={{ 'width': '500px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Type: {gd_mine_demo.de_load_cable_type}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Length: {gd_mine_demo.de_load_line_length} km </div>
                    </div>

                    <div className='row' style={{ 'width': '200px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Voltage rating: {gd_mine_demo.optimal_voltage_rating} kV   </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Cost: {gd_mine_demo.de_load_cable_cost} Euro   </div>
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Material: {gd_mine_demo.de_load_cable_material}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Opex per year: {gd_mine_demo.de_load_cable_opex_per_year} Euro </div>
                    </div>

                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '2px', 'margin-bottom': '2px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}>Comminution</p>
                    </div>

                    <div className='row' style={{ 'width': '500px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Type: {gd_mine_demo.co_load_cable_type}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Length: {gd_mine_demo.co_load_line_length} km </div>
                    </div>

                    <div className='row' style={{ 'width': '200px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Voltage rating: {gd_mine_demo.optimal_voltage_rating} kV   </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Cost: {gd_mine_demo.co_load_cable_cost} Euro   </div>
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Material: {gd_mine_demo.co_load_cable_material}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Opex per year: {gd_mine_demo.co_load_cable_opex_per_year} Euro </div>
                    </div>

                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '2px', 'margin-bottom': '2px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}>Crushing</p>
                    </div>

                    <div className='row' style={{ 'width': '500px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Type: {gd_mine_demo.cr_load_cable_type}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Length: {gd_mine_demo.cr_load_line_length} km </div>
                    </div>

                    <div className='row' style={{ 'width': '200px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Voltage rating: {gd_mine_demo.optimal_voltage_rating} kV   </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Cost: {gd_mine_demo.cr_load_cable_cost} Euro    </div>
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Material: {gd_mine_demo.cr_load_cable_material}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Opex per year: {gd_mine_demo.cr_load_cable_opex_per_year} Euro </div>
                    </div>

                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '2px', 'margin-bottom': '2px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}>Ventilation</p>
                    </div>

                    <div className='row' style={{ 'width': '500px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Type: {gd_mine_demo.ve_load_cable_type}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Length: {gd_mine_demo.ve_load_line_length} km </div>
                    </div>

                    <div className='row' style={{ 'width': '200px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Voltage rating: {gd_mine_demo.optimal_voltage_rating} kV   </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Cost: {gd_mine_demo.ve_load_cable_cost} Euro  </div>
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Material: {gd_mine_demo.ve_load_cable_material}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Opex per year: {gd_mine_demo.ve_load_cable_opex_per_year} Euro </div>
                    </div>


                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '2px', 'margin-bottom': '2px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}>Drilling</p>
                    </div>

                    <div className='row' style={{ 'width': '500px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Type: {gd_mine_demo.dr_load_cable_type}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Length: {gd_mine_demo.dr_load_line_length} km </div>
                    </div>

                    <div className='row' style={{ 'width': '200px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Voltage rating: {gd_mine_demo.optimal_voltage_rating} kV   </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Cost: {gd_mine_demo.dr_load_cable_cost} Euro   </div>
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Material: {gd_mine_demo.dr_load_cable_material}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Opex per year: {gd_mine_demo.dr_load_cable_opex_per_year} Euro </div>
                    </div>

                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '2px', 'margin-bottom': '2px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}>Transport</p>
                    </div>

                    <div className='row' style={{ 'width': '500px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Type: {gd_mine_demo.tr_load_cable_type}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Length: {gd_mine_demo.tr_load_line_length} km </div>
                    </div>

                    <div className='row' style={{ 'width': '200px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Voltage rating: {gd_mine_demo.optimal_voltage_rating} kV   </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Cost: {gd_mine_demo.tr_load_cable_cost} Euro   </div>
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Material: {gd_mine_demo.tr_load_cable_material}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Opex per year: {gd_mine_demo.tr_load_cable_opex_per_year} Euro </div>
                    </div>

                </div>

                <ABB.Heading level={3} text='Result 3: Cable design results - For generators' />

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '2px', 'margin-bottom': '2px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}>Diesel generator</p>
                    </div>

                    <div className='row' style={{ 'width': '500px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Type: {gd_mine_demo.generator_cable_type}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Length: {gd_mine_demo.generator_line_length} km </div>
                    </div>

                    <div className='row' style={{ 'width': '200px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Voltage rating: {gd_mine_demo.optimal_voltage_rating} kV   </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Cost: {gd_mine_demo.generator_cable_cost} Euro   </div>
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Material: {gd_mine_demo.generator_cable_material}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Opex per year: {gd_mine_demo.generator_cable_opex_per_year} Euro </div>
                    </div>

                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '2px', 'margin-bottom': '2px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}>Wind farm</p>
                    </div>

                    <div className='row' style={{ 'width': '500px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Type: {gd_mine_demo.wind_cable_type}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Length: {gd_mine_demo.wind_line_length} km </div>
                    </div>

                    <div className='row' style={{ 'width': '200px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Voltage rating: {gd_mine_demo.optimal_voltage_rating} kV   </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Cost: {gd_mine_demo.wind_cable_cost} Euro </div>
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Material: {gd_mine_demo.wind_cable_material}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Opex per year: {gd_mine_demo.wind_cable_opex_per_year} Euro </div>
                    </div>

                </div>

                <div className='rows'>
                    <div className='row' style={{ 'width': '100px', 'margin-top': '2px', 'margin-bottom': '2px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}>Solar panel</p>
                    </div>

                    <div className='row' style={{ 'width': '500px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Type: {gd_mine_demo.solar_cable_type}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Length: {gd_mine_demo.solar_line_length} km </div>
                    </div>

                    <div className='row' style={{ 'width': '200px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Voltage rating: {gd_mine_demo.optimal_voltage_rating} kV   </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Cable Cost:  {gd_mine_demo.solar_cable_cost} Euro   </div>
                    </div>

                    <div className='row' style={{ 'width': '100px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Material: {gd_mine_demo.solar_cable_material}  </div>
                    </div>

                    <div className='row' style={{ 'width': '300px', 'margin-top': '0px', 'margin-bottom': '-8px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Opex per year: {gd_mine_demo.solar_cable_opex_per_year} Euro </div>
                    </div>

                </div>

                <div className='gd_landing_bottom' style={{ 'background': 'white' }}>
                    <div className="content_res"> Total cost of cables: {gd_mine_demo.total_cable_cost_euro} Euro  </div>
                </div>

                <div className='gd_landing_bottom' style={{ 'background': 'white' }}>
                    <div className="content_res"> Total cost of cables and opex: {gd_mine_demo.total_opex_capex_whole_system} Euro  </div>
                </div>

                <ABB.Heading level={3} text='Result 4: Power quality check' />

                <div style={{ 'width': '100px', 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}>
                    <ABB.Button type="primary-red" shape="pill" text="Steady state analysis" onClick={v => call_api_steady_state_analysis_qdver({ func_type: 'str', val: gd_mine_demo })} />
                </div>

                <p>The microgrid design scheme should ensure that the system does not violate the voltage and current constraints for all cases of steady-state operation.</p>
                <p>Here, the voltage magnitudes of each bus and the load percentage on each line are given under various extreme operational conditions.</p>

                <GroupedBarVolt />

                <GroupedBarCurrent />


                <ABB.Heading level={2} text='Further analysis (PowerFactory-based)' />

                The grid design results could be converted to DGS format, which could be read by the PowerFactory for further analysis.

                <div className='rows'>
                    <div className='row' style={{ 'width': '500px', 'margin-top': '2px', 'margin-bottom': '2px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}>The grid design results in DGS format can be found in: </p>
                    </div>

                    <div className='row' style={{ 'width': '500px', 'margin-top': '10px', 'margin-bottom': '2px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Folder: {'assessments/' + id}  </div>
                    </div>
                </div>

                {/*

                <div className='rows'>
                    <div className='row' style={{ 'width': '500px', 'margin-top': '2px', 'margin-bottom': '2px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}>The grid design results in pandapower format can be found in: </p>
                    </div>

                    <div className='row' style={{ 'width': '500px', 'margin-top': '2px', 'margin-bottom': '-8px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Folder: {gd_mine_demo.grid_design_results_DGS_file_path}  </div>
                    </div>

                </div>

                */}


                <div className='rows'>
                    <div style={{ 'width': '500px', 'margin-top': '2px', 'margin-bottom': '2px', 'margin-left': '10px', 'margin-right': '10px' }}>
                        <ABB.Button type="primary-red" shape="pill" text="Data conversion" onClick={v => call_api_data_conversion_qdver({ func_type: 'str', val: gd_mine_demo })} />
                    </div>

                    <div className='row' style={{ 'width': '500px', 'margin-top': '10px', 'margin-bottom': '2px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <div className="content_gd_results">  Status: {gd_mine_demo.indicator_data_conversion_finish}  </div>
                    </div>
                </div>


                <div className='rows'>

                    <div className='row' style={{ 'width': '500px', 'margin-top': '10px', 'margin-bottom': '2px', 'margin-left': '10px', 'margin-right': '10px' }} >
                        <p style={{ fontSize: 15 }}>User-defined name of DGS file: </p>
                    </div>

                    <div className='row' style={{ 'width': '500px', 'margin-top': '10px', 'margin-bottom': '2px', 'margin-left': '20px', 'margin-right': '10px' }} >
                        <ABB.Input required={true} dataType='text'
                            value={gd_mine_demo.user_define_dgs_file_name}
                            onValueChange={v => updateDataBackend('gd_mine_demo', { key: 'user_define_dgs_file_name', value: emptyStringToNull(v) })}
                        />
                    </div>
                </div>

                <ABB.Button shape="pill" type="primary-red" style={{ 'margin-top': '10px', 'margin-left': '10px', 'margin-right': '10px' }}
                    text="Download DGS Files"
                    onClick={() => [downloadDocuments(id + '.json', 'data/' + id + '.json'),
                    downloadDocuments(gd_mine_demo.user_define_dgs_file_name + '_DGS_max_load_max_res.xlsx', 'assessments/' + id + '/DGS_max_load_max_res.xlsx'),
                    downloadDocuments(gd_mine_demo.user_define_dgs_file_name + '_DGS_max_load_min_res.xlsx', 'assessments/' + id + '/DGS_max_load_min_res.xlsx'),
                    downloadDocuments(gd_mine_demo.user_define_dgs_file_name + '_DGS_min_load_max_res.xlsx', 'assessments/' + id + '/DGS_min_load_max_res.xlsx'),
                    downloadDocuments(gd_mine_demo.user_define_dgs_file_name + '_DGS_min_load_min_res.xlsx', 'assessments/' + id + '/DGS_min_load_min_res.xlsx'),
                    downloadDocuments(gd_mine_demo.user_define_dgs_file_name + '_DGS_zero_load_max_res.xlsx', 'assessments/' + id + '/DGS_zero_load_max_res.xlsx'),
                    downloadDocuments(gd_mine_demo.user_define_dgs_file_name + '_DGS_zero_load_min_res.xlsx', 'assessments/' + id + '/DGS_zero_load_min_res.xlsx')
                    ]} />






            </div>
        </div>

    )

}



export default GridDesignDemoPage