import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updatesSite, load_constructor } from '../../slices/siteSlice'
import { emptyStringToNull } from './../resources/utilities'
import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import './../../App.css'
import { timeSeriesDelete, deleteLocalFiles } from '../../slices/timeseriesFilesSlice'

const fs = require('fs')


const General_data = () => {
    const dispatch = useDispatch()
    const ident = useSelector(state => state.site.ident)
    const id = ident.id
    const mine_layout = useSelector(state => state.site.mine_layout)
    const grid = useSelector(state => state.site.grid)
    const res = useSelector(state => state.site.res)
    const basicOptimzation = useSelector(state => state.site.basicOptimzation)
    const dewater_factor = mine_layout.dewatering_mass_per_ton
    const mine_load_ventilation = mine_layout.ventilation_load_assumed
    const ventilation_volume_factor = mine_layout.ventilation_volume_factor
    let errorDivRef = useRef(null);
    const isFirstRender = useRef(true);
    const [frontValue, setFrontValue] = useState(mine_layout.hours)
    const timeseriesFiles = useSelector(state => state.timeseriesFiles.timeseries)
    const resolutionRef = useRef(false)






    // update data of site in backend
    const updateDataBackend = (site, update) => {
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': site, ...update }] }))
    }

    useEffect(() => {
        updateDataBackend('mine_layout', { key: 'dewatering_mass', value: mine_layout.tons_produced_all })
        updateDataBackend('mine_layout', { key: 'ventilation_tons', value: mine_layout.tons_produced_all })
        updateDataBackend('mine_layout', { key: 'hoisting_daily_mass', value: mine_layout.tons_produced_all })
        updateDataBackend('mine_layout', { key: 'transport_tons', value: mine_layout.tons_produced_all })
        updateDataBackend('mine_layout', { key: 'comminution_tons', value: mine_layout.tons_produced_all })
        updateDataBackend('mine_layout', { key: 'crushing_tons', value: mine_layout.tons_produced_all })
        updateDataBackend('mine_layout', { key: 'drilling_tons', value: mine_layout.tons_produced_all })
        updateDataBackend('mine_layout', { key: 'ventilation_depth', value: mine_layout.mine_depth })
        updateDataBackend('mine_layout', { key: 'hoisting_depth', value: mine_layout.mine_depth })
        updateDataBackend('mine_layout', { key: 'dewatering_height', value: mine_layout.mine_depth })
    }, [mine_layout.tons_produced_all])


    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else {

            errorDivRef.current.click();
        }
    }, [frontValue])

    useEffect(() => {
        setFrontValue(mine_layout.hours)
    }, [frontValue])

    useEffect(() => {
        if (resolutionRef.current) {
            const values = mine_layout.hours=='days' ? 365 : 8760
            dispatch(timeSeriesDelete('/' + id))
            // fs.unlink('base_hourly.csv')
            dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': 'grid', key: 'load_series_selector', value: null }] }))
            dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': 'grid', key: 'pv_series_selector', value: null }] }))
            dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': 'wind', key: 'wind_series_selector', value: null }] }))
            dispatch(deleteLocalFiles('/' + id))
            dispatch(updatesSite({'id': id, 'updates' : [{'slice' : 'mine_layout', key: 'add_elements', value: null}]}))
            dispatch(updatesSite({'id': id, 'updates' : [{'slice' : 'mine_layout', key: 'res_color', value: null}]}))
            dispatch(updatesSite({'id': id, 'updates' : [{'slice' : 'mine_layout', key: 'hoisting_color', value: null}]}))
            dispatch(updatesSite({'id': id, 'updates' : [{'slice' : 'mine_layout', key: 'drilling_color', value: null}]}))
            dispatch(updatesSite({'id': id, 'updates' : [{'slice' : 'mine_layout', key: 'ventilation_color', value: null}]}))
            dispatch(updatesSite({'id': id, 'updates' : [{'slice' : 'mine_layout', key: 'transport_color', value: null}]}))
            dispatch(updatesSite({'id': id, 'updates' : [{'slice' : 'mine_layout', key: 'dewatering_color', value: null}]}))
            dispatch(updatesSite({'id': id, 'updates' : [{'slice' : 'mine_layout', key: 'comminution_color', value: null}]}))
            dispatch(updatesSite({'id': id, 'updates' : [{'slice' : 'mine_layout', key: 'crushing_color', value: null}]}))
            dispatch(updatesSite({
                'id': id, 'updates': [
                    { 'slice': 'mine_layout', key: 'hoisting_load', value: null },//Array(values).fill(0) },
                    { 'slice': 'mine_layout', key: 'hoisting_color', value: false },
                    { 'slice': 'mine_layout', key: 'drilling_load', value: null },
                    { 'slice': 'mine_layout', key: 'drilling_color', value: false },
                    { 'slice': 'mine_layout', key: 'ventilation_load', value: null },
                    { 'slice': 'mine_layout', key: 'ventilation_color', value: false },
                    { 'slice': 'mine_layout', key: 'transport_load', value: null },
                    { 'slice': 'mine_layout', key: 'transport_color', value: false },
                    { 'slice': 'mine_layout', key: 'dewatering_load', value: null },
                    { 'slice': 'mine_layout', key: 'dewatering_color', value: false },
                    { 'slice': 'mine_layout', key: 'comminution_load', value: null },
                    { 'slice': 'mine_layout', key: 'comminution_color', value: false },
                    { 'slice': 'mine_layout', key: 'crushing_load', value: null },
                    { 'slice': 'mine_layout', key: 'crushing_color', value: false },
                    { 'slice': 'mine_layout', key: 'total_load', value: null },
                    { 'slice': 'basicOptimization', key: 'break_even', value: null },
                    { 'slice': 'basicOptimization', key: 'cost_bau_detail', value: null },
                    { 'slice': 'basicOptimization', key: 'cost_res_detail', value: null },
                    { 'slice': 'basicOptimization', key: 'lcoe', value: null },
                    { 'slice': 'basicOptimization', key: 'pv_real_install', value: null },
                    { 'slice': 'basicOptimization', key: 'wind_real_install', value: null },
                    { 'slice': 'basicOptimization', key: 'series_el', value: null },
                    { 'slice': 'basicOptimization', key: 'series_diesel', value: null },
                    { 'slice': 'basicOptimization', key: 'series_load', value: null },
                    { 'slice': 'basicOptimization', key: 'series_pv', value: null },
                    { 'slice': 'basicOptimization', key: 'series_wind', value: null },
                    { 'slice': 'basicOptimization', key: 'timeseries', value: null },
                ]
            }))
        } else {
            resolutionRef.current = true;
        }
    }, [mine_layout.hours])

    // useEffect(() => {
    //     if (mountedRef.current) {
    //         errorDivRef.current.click()
    //     } else {
    //         mountedRef.current = true;
    //     }
    // }, [frontValue])

    // useEffect(() => {

    // }, [mine_layout.u_choice])
    // console.log(mine_layout.hours);

    return (
        <div className="fullHeight content" >
            <div className="contentBox" id='export_general_data'  >
              
                <ABB.Heading level={4} text='Enter the location of the mine' style={{ 'marginLeft': '16px' }} />
                <ABB.WithDialog>
                    <div ref={errorDivRef}></div>
                    {<ABB.Dialog showCloseButton={true} closeOnEscape={true} closeOnLostFocus={true} dimBackground={true}
                        standardButtonsOnBottom={mine_layout.hours == 'days' ? [
                            { text: 'No', value: 'days', type: 'discreet-blue', handler: (dlg) => [updateDataBackend('mine_layout', { key: 'hours', value: 'days' }), dlg.close()] },
                            { text: 'Yes', value: 'hours', type: 'primary-red', handler: (dlg) => [updateDataBackend('mine_layout', { key: 'hours', value: 'hours' }), dlg.close()] },
                        ] : [
                            { text: 'No', value: 'hours', type: 'discreet-blue', handler: (dlg) => [updateDataBackend('mine_layout', { key: 'hours', value: 'hours' }), dlg.close()] },
                            { text: 'Yes', value: 'days', type: 'primary-red', handler: (dlg) => [updateDataBackend('mine_layout', { key: 'hours', value: 'days' }), dlg.close()] },
                        ]}
                        title="Confirm" style={{ top: "10%", left: "50%", margin: "0 0 0 0px", width: "110%", height: "150%", overflow: "hidden" }} >
                        <main>
                            <div>
                                {mine_layout.hours == 'days' && <p>Are you sure you want to change the resolution to hours? This will delete all the files</p>}
                                {mine_layout.hours == 'hours' && <p>Are you sure you want to change the resolution to days? This will delete all the files</p>}
                            </div>
                        </main>
                    </ABB.Dialog>}
                </ABB.WithDialog>

                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                    label={'Latitude'}
                    value={res.res_api_latitude}
                    onValueChange={v => [updateDataBackend('mine_layout', { key: 'latitude', value: emptyStringToNull(parseFloat(v)) }),
                                         updateDataBackend('res', { key: 'res_api_latitude', value: emptyStringToNull(parseFloat(v)) })]}
                />


                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                    label={'Longitude'}                  
                    value={res.res_api_longitude}
                    onValueChange={v => [updateDataBackend('mine_layout', { key: 'longitude', value: emptyStringToNull(parseFloat(v)) }),
                                         updateDataBackend('res', { key: 'res_api_longitude', value: emptyStringToNull(parseFloat(v)) }) ]}
                />

                <ABB.Heading level={4} text='Enter the rough size of the mine' style={{ 'marginLeft': '16px' }} />

                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                    label={'Depth [m]'}
                    value={mine_layout.mine_depth}
                    onValueChange={v => updateDataBackend('mine_layout', { key: 'mine_depth', value: emptyStringToNull(parseFloat(v)) })} //ventilation_depth
                />
                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                    label={'Width [m]'}
                    value={mine_layout.mine_width}
                    onValueChange={v => updateDataBackend('mine_layout', { key: 'mine_width', value: emptyStringToNull(parseFloat(v)) })}
                />

                <ABB.Heading level={4} text='Enter the daily throughput of the mine' style={{ 'marginLeft': '16px' }} />

                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                    label={'Produced mass [t/day]'}
                    value={mine_layout.tons_produced_all}
                    onValueChange={v => [updateDataBackend('mine_layout', { key: 'tons_produced_all', value: emptyStringToNull(parseFloat(v)) })]}

                />


                <ABB.Heading level={4} text='Enter the time specifications' style={{ 'marginLeft': '16px' }} />
                <ABB.Dropdown id='drop' label={"Choose the resolution:"} required={true} searchable={true}
                    //value= {mine_layout.total_load_available}
                    value={(frontValue !== null) ? [{ label: mine_layout.hours }] : ['no data selected']}
                    // onChange={v => [updateDataBackend('mine_layout', { key: 'hours', value: v[0].value })]}
                    onChange={v => [setFrontValue()]}
                >
                    <ABB.DropdownOption label={'hours'} value={'hours'} />
                    <ABB.DropdownOption label={'days'} value={'days'} />
                </ABB.Dropdown>



                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                    label={'Optimization horizon [years]'}
                    value={grid.lifetime}
                    onValueChange={v => updateDataBackend('grid', { key: 'lifetime', value: emptyStringToNull(parseFloat(v)) })}
                />

            </div>
        </div >
    )
}

export default General_data