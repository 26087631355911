import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateIdent, register } from '../../slices/identSlice'
import { withRouter } from 'react-router-dom'
import { sectors } from '../resources/sectors.js'
import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css'


const Register = (props) => {

    const dispatch = useDispatch()
    const ident = useSelector(state => state.ident)
    const countries = useSelector(state => state.countries)
    const currencies = useSelector(state => state.currencies)

    const [warnings, setWarnings] = useState([])

    useEffect( () => {
        if(ident.id) {
            props.history.push('/'+ident.id)
        }
    }, [ident.id, props.history])

    const setCountry = (v) => {
        dispatch(updateIdent({key: 'country', value: v[0].value}))
        let currency = currenciesEnriched['USD']
        countries.countries.filter( c => c.name === v[0].value )[0].currencies.forEach( (c) => {
            if (c.code in currenciesEnriched) {
                currency = currenciesEnriched[c.code]
            }
        })
        dispatch(updateIdent({key: 'currency', value: currency}))
    }

    const handleSubmit = ident => {
        const _warnings = []
        if( ident.siteName.length < 1 ) {
            _warnings.push("Please specify the site name.")
        }
        if( ident.contactName.length < 1) {
            _warnings.push("Please specify your contact name.")
        }
        if( !/.+@.+\.[A-Za-z]+$/.test(ident.email) ) {
            _warnings.push("Please specify a valid email address.")
        }
        if( ident.country.length < 1) {
            _warnings.push("Please specify the country of the site location.")
        }
        if( ident.zip.length < 1) {
            _warnings.push("Please specify the zip code of the site location.")
        }
        if( ident.sector.length < 1) {
            _warnings.push("Please specify the sector the site operates in.")
        }
        if( ident.subsector.length < 1) {
            _warnings.push("Please specify the subsector the site operates in.")
        }
        setWarnings(_warnings)
        if( _warnings.length < 1 ) {
            dispatch(register(ident))
        }
    }

    const handleCloseError = dialog => {
        dispatch(updateIdent({key: 'status', value: 'uninitialized'}))
        dialog.close()
    }

    let dialogButtons = []
    if (ident.status !== 'loading' && ident.status !== 'error') {
        dialogButtons = [
            { text: 'Cancel', type: 'discreet-blue', handler: (dialog) => dialog.close() },
            { text: 'Register', type: 'primary-blue', handler: (dialog) => handleSubmit(ident) },
        ]
    } else if (ident.status === 'error') {
        dialogButtons = [
            { text: 'Cancel', type: 'discreet-blue', handler: (dialog) => handleCloseError(dialog) }
        ]
    }

    const warning = warnings.map( w => {
        return (<li>{w}</li>)
    })

    const sectors_filtered_by_sector = sectors.filter( s => s.name === ident.sector )
    const subsectors = sectors_filtered_by_sector.length > 0 ? sectors_filtered_by_sector[0].subsectors : []

    const currenciesEnriched = {}

    countries.countries.forEach( country => {
        country.currencies.forEach( currency => {
            if (!(currency.code in currenciesEnriched) && currency.code in currencies.currencies.rates) {
                currenciesEnriched[currency.code] = {
                    code: currency.code,
                    name: currency.name.toLowerCase() !== 'united state dollar' ? currency.name : 'United States Dollar',
                    symbol: currency.symbol !== null ? currency.symbol : currency.code,
                    from_usd: currencies.currencies.rates[currency.code],
                    to_usd: 1.0/currencies.currencies.rates[currency.code]
                }
            }
        })
    })

    return (
        <ABB.WithDialog>
            <ABB.Button type="primary-red" shape="pill" text="Register for Site Evaluation" />
            <ABB.Dialog
                showCloseButton={false}
                closeOnEscape={false}
                closeOnLostFocus={false}
                hideBackground={true}
                title="Register for Site Evaluation"
                standardButtonsOnBottom={dialogButtons}
                style={{width: "800px"}}
            >
                <main>
                   { (ident.status !=='error') && 
                        <div>
                            <ABB.Input dataType="text" label="Site Name" placeholder="Name of your site"
                                value={ident.siteName} required={true}
                                onValueChange={v => dispatch(updateIdent({key: 'siteName', value: v}))} />
                            <ABB.Input dataType="text" label="Contact Name" placeholder="Your name"
                                value={ident.contactName} required={true}
                                onValueChange={v => dispatch(updateIdent({key: 'contactName', value: v}))} />
                            <ABB.Input dataType="email" type="normal" icon="abb/envelope" label="E-Mail"
                                value={ident.email} required={true}
                                onValueChange={v => dispatch(updateIdent({key: 'email', value: v}))} />
                            <div style={{display: 'flex'}} >
                                <ABB.Dropdown style={{ width: "100%", marginRight: '16px' }} label="Country" required={true} searchable={true}
                                    value={ident.country !== null ? [{value: ident.country}] : []}
                                    onChange={v => setCountry(v)}
                                >
                                    {countries.countries.map((c) => (
                                        <ABB.DropdownOption key={c.alpha3code} label={c.name} value={c.name} />
                                    ))}
                                </ABB.Dropdown>
                                <ABB.Dropdown style={{ width: "100%" }} label="Currency" required={true} searchable={true}
                                    value={ident.currency !== null ? [{value: ident.currency.code}] : []}
                                    onChange={v => dispatch(updateIdent({key: 'currency', value: currenciesEnriched[v[0].value]}))}
                                >
                                    {Object.entries(currenciesEnriched).map( ([code, currency]) => (
                                        <ABB.DropdownOption key={code} label={currency.name} value={code} />
                                    ))}
                                </ABB.Dropdown>
                            </div>
                            <ABB.Input dataType="text" label="Street" placeholder="Street of site location"
                                value={ident.street} required={false}
                                onValueChange={v => dispatch(updateIdent({key: 'street', value: v}))} />
                            <div style={{display: 'flex'}} >
                                <ABB.Input style={{ width: "120px", marginRight: '16px' }} dataType="text" label="Zip code"
                                    value={ident.zip} required={true}
                                    onValueChange={v => dispatch(updateIdent({key: 'zip', value: v}))} />
                                <ABB.Input style={{ width: "100%"  }} dataType="text" label="City" placeholder="City of site location"
                                    value={ident.city} required={true}
                                    onValueChange={v => dispatch(updateIdent({key: 'city', value: v}))} />
                            </div>
                            <div style={{display: 'flex'}} >
                                <ABB.Dropdown style={{ width: "100%", marginRight: '16px' }} label="Sector" required={true}
                                    value={ident.sector !== null ? [{value: ident.sector}] : []}
                                    onChange={v => dispatch(updateIdent({key: 'sector', value: v[0].value}))}
                                >
                                    {sectors.map((s) => (
                                        <ABB.DropdownOption key={s.name} label={s.name} value={s.name} />
                                    ))}
                                </ABB.Dropdown>
                                <ABB.Dropdown style={{ width: "100%"  }} label="Subsector" required={true}
                                    value={ident.subsector !== null ? [{value: ident.subsector}] : []}
                                    onChange={v => dispatch(updateIdent({key: 'subsector', value: v[0].value}))}
                                >
                                    { subsectors.map((s) => (
                                        <ABB.DropdownOption key={s} label={s} value={s} />
                                    ))}
                                </ABB.Dropdown>
                            </div>
                            <p>ABB collects site data and uses it to evaluate energy management services and to improve ABB's products. By registering you accept these conditions.</p>
                            <ul>{warning}</ul>
                            {ident.status==='loading' && <ABB.LoadingIndicator color='grey' text='Registering' />}
                        </div>
                    }
                    { (ident.status === 'error') &&
                        <div>
                            <p>An error occured. Please try again later.</p>
                        </div>
                    }
                </main>    
            </ABB.Dialog>
        </ABB.WithDialog>
    )
}


export default withRouter(Register)