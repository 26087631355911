import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { register } from '../../slices/identSlice'
import { withRouter } from 'react-router-dom'
import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css'
import Login from './login'

const Welcome = (props) => {

    const dispatch = useDispatch()
    const ident = useSelector(state => state.ident)   
    const id = ident.id
    const [warnings, setWarnings] = useState([])

    useEffect(() => {
        if (ident.id) {
            props.history.push('/' + ident.id)
        }
    }, [ident.id, props.history])

   
    const handleSubmit = ident => {
        const _warnings = []
        
        if( ident.userName.length < 1 ) {
            _warnings.push("Error")            
        }

        //insert error message for role Here

        if( ident.email.length < 1 ) {
            _warnings.push("Error")            
        }
        
        if( ident.customerCompany.length < 1 ) {
            _warnings.push("Error")            
        }

        
        //if( ident.customerName.length < 1 ) {
        //    _warnings.push("Error")            
        //}

        //if( ident.customerEmail.length < 1 ) {
        //    _warnings.push("Error")            
        //}

        if( ident.siteName.length < 1 ) {
            _warnings.push("Error")            
        }

        if( ident.siteCountry.length < 1 ) {
            _warnings.push("Error")            
        }


        console.log(_warnings)
        setWarnings(_warnings)
        if (_warnings.length < 1) {
            dispatch(register(ident))
        } else
        alert('Fill all the remaining fields please')    
    }
    
    //const warning = warnings.map(w => {
    //    return (<li>{w}</li>)
    //})
    
    return (
        <div class="button-container">
        
            <ABB.WithDialog>
                <ABB.Button type="primary-red" shape="pill" text="Register" style={{ 'margin-top': '1px', 'margin-left': '1px' }} />
                <ABB.Dialog showCloseButton={true} closeOnEscape={false} closeOnLostFocus={false} hideBackground={false}
                    standardButtonsOnBottom={[{ text: 'Submit', shape: 'pill', type: 'primary-red', handler: (dialog) => handleSubmit(ident) },]}
                /* title="Input Site Data" */ style={{ top: "10%", left: "50%", margin: "0 0 0 0px", width: "110%", height: "150%", overflow: "hidden" }} >
                    <Login/>
                    <main> <div> </div> </main>
                </ABB.Dialog>
            </ABB.WithDialog>
        </div>
        

    )
}
export default withRouter(Welcome)