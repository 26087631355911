import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { api_url } from '../config.js'
import register from '../components/joint_frontend/register.js'

const initialState = {
    status: 'uninitialized',
    topNaviStates: {
        'overview': false,
        'mine': false,
        'res': false,
        'results': false,
        'timeseries': false,
        'loads': false,
        'generation': false,
        'storages': false,
        'grid': false,
        'defaults': false,
        'assessment': false,
        'play': false,
        'drilling': false,
        'transport': false,
        'crushing': false,
        'addition_button': false,
        'dewatering': false,
        'hoisting': false,
        'ventilation': false,
        'comminution': false,
        'general_data': false,
        'mainDisplay': false,
        'mainDisplay2': true,  // this one
        'mainGridDesignDemo': false,
    },
    topGraphStates: {
        'graphA': true,
        'graphB': false,
        'graphC': false,
        'graphD': false,
    },
    simStates: {
        'simulation1_state': 'empty',
        'simulation2_state': 'empty',
        'simulation3_state': 'empty',
    },

    vehicleToGridMode: false,
    exportPNG:{
        'button_name' : "Create Report"
    }  
}

const fetchSite = createAsyncThunk(
    'fetchSite',
    async id => {
        const response = await axios.get(api_url + '/site' + id)
        return response.data
    }
)

const updatesSite = createAsyncThunk(
    'updatesSite',
    async updates => {
        const response = await axios.post(api_url + '/site/updates', updates)
        return response
    }
)

const exportPNG = createAsyncThunk(
    'exportPNG',
    async updates => {
        const response = await axios.post(api_url + '/site/exportPNG', updates)
        return response
    }
)

const exportReport = createAsyncThunk(
    'exportReport',
    async updates => {
        const response = await axios.post(api_url + '/site/exportReport', updates)
        return response
    }
)

const download = createAsyncThunk(
    'download',
    async (input) => {
        const { path_new, path_system } = input;
        console.log(api_url)
        console.log(path_system)
        console.log(path_new)
        /*
        if (api_url == "http://emine-backend.kn.crc.de.abb.com"){
        path_system = '/'
        }
        */
        console.log('This path: ' + api_url + '/site/download/' + path_system)
        const response = await axios.get(api_url + '/site/download/' + path_system, {
            responseType: 'blob',
        })
        console.log(response)
        const filename = path_new
        return { response, filename }
    }

)

const minExample = createAsyncThunk(
    'minExample',
    async updates => {
        const response = await axios.post(api_url + '/site/minimumExampleTest', updates)
        return response
    }
)

const basicOptimization = createAsyncThunk(
    'basicOptimization',
    async updates => {
        const response = await axios.post(api_url + '/site/basicOptimization', updates)
        return response
    }
)

const downloadWeatherData = createAsyncThunk(
    'downloadWeatherData',
    async updates => {
        const response = await axios.post(api_url + '/site/downloadWeatherData', updates)
        return response
    }
)
const load_constructor = createAsyncThunk(
    'load_constructor',
    async updates => {
        const response = await axios.post(api_url + '/site/load_constructor', updates)
        return response
    }
)

const super_load_constructor = createAsyncThunk(
    'super_load_constructor',
    async updates => {
        const response = await axios.post(api_url + '/site/super_load_constructor', updates)
        return response
    }
)

const load_summation = createAsyncThunk(
    'load_summation',
    async updates => {
        const response = await axios.post(api_url + '/site/load_summation', updates)
        return response
    }
)

const load_summation_hours = createAsyncThunk(
    'load_summation_hours',
    async updates => {
        const response = await axios.post(api_url + '/site/load_summation_hours', updates)
        return response
    }
)

const dewatering = createAsyncThunk(
    'dewatering',
    async updates => {
        const response = await axios.post(api_url + '/site/dewatering', updates)
        return response
    }
)

const super_dewatering = createAsyncThunk(
    'super_dewatering',
    async updates => {
        const response = await axios.post(api_url + '/site/super_dewatering', updates)
        return response
    }
)


const super_hoisting = createAsyncThunk(
    'super_hoisting',
    async updates => {
        const response = await axios.post(api_url + '/site/super_hoisting', updates)
        return response
    }
)

const hoisting = createAsyncThunk(
    'hoisting',
    async updates => {
        const response = await axios.post(api_url + '/site/hoisting', updates)
        return response
    }
)

const super_ventilation = createAsyncThunk(
    'super_ventilation',
    async updates => {
        const response = await axios.post(api_url + '/site/super_ventilation', updates)
        return response
    }
)

const ventilation = createAsyncThunk(
    'ventilation',
    async updates => {
        const response = await axios.post(api_url + '/site/ventilation', updates)
        return response
    }
)

const super_comminution = createAsyncThunk(
    'super_comminution',
    async updates => {
        const response = await axios.post(api_url + '/site/super_comminution', updates)
        return response
    }
)

const comminution = createAsyncThunk(
    'comminution',
    async updates => {
        const response = await axios.post(api_url + '/site/comminution', updates)
        return response
    }
)



const fetchVehicleToGridMode = createAsyncThunk(
    'vehicleToGridMode',
    async () => {
        const response = await axios.get(api_url + '/vehicle_to_grid_mode')
        return response.data
    }
)


// For Boda - SSA module - create the react hook to get the data

const load_loaddata_from_DS_backend = createAsyncThunk(
    'load_loaddata_from_DS_backend',
    async updates => {
        const response = await axios.post(api_url + '/site/load_loaddata_from_DS_backend', updates)
        return response
    }
)

const load_gendata_from_DS_backend = createAsyncThunk(
    'load_gendata_from_DS_backend',
    async updates => {
        const response = await axios.post(api_url + '/site/load_gendata_from_DS_backend', updates)
        return response
    }
)

// For different four functions
const call_api_cable_assign_qdver_backend = createAsyncThunk(
    'call_api_cable_assign_qdver_backend',
    async updates => {
        const response = await axios.post(api_url + '/site/call_api_cable_assign_qdver_backend', updates)
        return response
    }
)

const call_api_power_loss_qdver_backend = createAsyncThunk(
    'call_api_power_loss_qdver_backend',
    async updates => {
        const response = await axios.post(api_url + '/site/call_api_power_loss_qdver_backend', updates)
        return response
    }
)


const call_api_grid_optimize_qdver_backend = createAsyncThunk(
    'call_api_grid_optimize_qdver_backend',
    async updates => {
        const response = await axios.post(api_url + '/site/call_api_grid_optimize_qdver_backend', updates)
        return response
    }
)

const call_api_steady_state_analysis_qdver_backend = createAsyncThunk(
    'call_api_steady_state_analysis_qdver_backend',
    async updates => {
        const response = await axios.post(api_url + '/site/call_api_steady_state_analysis_qdver_backend', updates)
        return response
    }
)

const call_api_data_conversion_qdver_backend = createAsyncThunk(
    'call_api_data_conversion_qdver_backend',
    async updates => {
        const response = await axios.post(api_url + '/site/call_api_data_conversion_qdver_backend', updates)
        return response
    }
)




// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {
        updateTopNavi(state, action) {
            //const newTopNaviState = (s => (Object.entries(s).reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[0] === action.payload }), {})))
            const newTopNaviStateArray = Object.entries(state.topNaviStates).map(t => ([t[0], t[0] === action.payload]))
            const newTopNaviState = Object.fromEntries(newTopNaviStateArray)
            state.topNaviStates = newTopNaviState
        },
        updateTopGraph(state, action) {
            const newTopGraphStateArray = Object.entries(state.topGraphStates).map(t => ([t[0], t[0] === action.payload]))
            const newTopGraphState = Object.fromEntries(newTopGraphStateArray)
            state.topGraphStates = newTopGraphState
        },
        updateSimNavi(state, info) {
            const newSimStateArray = Object.entries(state.simStates).map(t => ([t[0], (t[0] === info.payload.name) ? info.payload.val : t[1]]))
            const newSimState = Object.fromEntries(newSimStateArray)
            state.simStates = newSimState
        },
    },
    extraReducers: {
        [fetchSite.pending]: (state, action) => { state.status = 'loading' },
        [fetchSite.fulfilled]: (state, action) => {
            Object.entries(action.payload).forEach(e =>
                state[e[0]] = e[1]
            )
            state.status = 'loaded'
        },
        [fetchSite.rejected]: (state, action) => { state.status = 'error' },
        [fetchVehicleToGridMode.fulfilled]: (state, action) => {
            state.vehicleToGridMode = action.payload.vehicle_to_grid_mode
        },
        [fetchVehicleToGridMode.rejected]: (state, action) => { state.status = 'error' },
        [updatesSite.pending]: (state, action) => {
            const updates = action.meta.arg.updates
            updates.forEach(update => {
                if (update.component) {
                    state[update.slice][update.component][update.key] = update.value
                } else {
                    state[update.slice][update.key] = update.value
                }
            })
        },
        [updatesSite.rejected]: (state, action) => { state.status = 'error' },

        //export report
        [exportReport.pending]: (state, action) => {
            //state['export']['report_col'] = 'red'
            state['export']['button_name'] = 'Loading...'
        },
        [exportReport.fulfilled]: (state, action) => {
            //alert(action.payload.data)
            state['export']['report_col'] = 'white'
            state['export']['download_col'] = 'red'
            state['export']['button_name'] = 'Create Report'
        },

        [download.fulfilled]: (state, action) => {
            //create URL and HTML element
            const href = URL.createObjectURL(action.payload.response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', action.payload.filename); //set filename
            document.body.appendChild(link);
            link.click();
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        },
        [download.rejected]: (state, action) => { state.status = "error" },

        [super_load_constructor.pending]: (state, action) => {
            const updates = action.meta.arg.updates
            updates.forEach(update => {
                if (update.component) {
                    state[update.slice][update.component][update.key] = update.value
                } else {
                    state[update.slice][update.key] = update.value
                }
            })
        },
        [super_load_constructor.rejected]: (state, action) => { state.status = 'error' },

        [super_dewatering.pending]: (state, action) => {
            const updates = action.meta.arg.updates
            updates.forEach(update => {
                if (update.component) {
                    state[update.slice][update.component][update.key] = update.value
                } else {
                    state[update.slice][update.key] = update.value
                }
            })
        },
        [super_dewatering.rejected]: (state, action) => { state.status = 'error' },


        [super_hoisting.pending]: (state, action) => {
            const updates = action.meta.arg.updates
            updates.forEach(update => {
                if (update.component) {
                    state[update.slice][update.component][update.key] = update.value
                } else {
                    state[update.slice][update.key] = update.value
                }
            })
        },
        [super_hoisting.rejected]: (state, action) => { state.status = 'error' },

        [super_ventilation.pending]: (state, action) => {
            const updates = action.meta.arg.updates
            updates.forEach(update => {
                if (update.component) {
                    state[update.slice][update.component][update.key] = update.value
                } else {
                    state[update.slice][update.key] = update.value
                }
            })
        },
        [super_ventilation.rejected]: (state, action) => { state.status = 'error' },

        [super_comminution.pending]: (state, action) => {
            const updates = action.meta.arg.updates
            updates.forEach(update => {
                if (update.component) {
                    state[update.slice][update.component][update.key] = update.value
                } else {
                    state[update.slice][update.key] = update.value
                }
            })
        },
        [super_comminution.rejected]: (state, action) => { state.status = 'error' },

        [minExample.fulfilled]: (state, action) => {
            state['wind']['wind_direction'] = action.payload.data.wind_direction
            state['wind']['wind_speed'] = action.payload.data.wind_speed
        },

        //weather API usage
        [downloadWeatherData.fulfilled]: (state, action) => {
            state['res']['res_api_state'] = 2
            state['res']['res_api_filenames'] = action.payload.data.filename
            state['res']['res_api_message'] = action.payload.data.error
        },
        [downloadWeatherData.pending]: (state, action) => {
            state['res']['res_api_state'] = 1
        },

        [basicOptimization.fulfilled]: (state, action) => {
            state['basicOptimization']['timeseries'] = action.payload.data.timeseries
            state['basicOptimization']['pv_real_install'] = action.payload.data.pv_real_install
            state['basicOptimization']['wind_real_install'] = action.payload.data.wind_real_install
            state['basicOptimization']['lifetime'] = action.payload.data.lifetime
            state['basicOptimization']['break_even'] = action.payload.data.break_even
            state['basicOptimization']['lcoe'] = action.payload.data.lcoe
            state['basicOptimization']['costs_res_detail'] = action.payload.data.costs_res_detail
            state['basicOptimization']['costs_bau_detail'] = action.payload.data.costs_bau_detail
            state['basicOptimization']['series_load'] = action.payload.data.series_load
            state['basicOptimization']['series_wind'] = action.payload.data.series_wind
            state['basicOptimization']['series_pv'] = action.payload.data.series_pv
            state['basicOptimization']['series_el'] = action.payload.data.series_el
            state['basicOptimization']['series_diesel'] = action.payload.data.series_diesel
        },
        /* [load_constructor.pending]: (state, action) => { state.status = 'loading' }, */
        [load_constructor.fulfilled]: (state, action) => {
            state['mine_layout'][action.payload.data.string] = action.payload.data.timeseries
        },
        [load_summation.fulfilled]: (state, action) => {
            state['mine_layout']['total_load'] = action.payload.data.timeseries
        },
        [load_summation_hours.fulfilled]: (state, action) => {
            state['mine_layout']['total_load'] = action.payload.data.timeseries
        },
        [dewatering.fulfilled]: (state, action) => {
            state['mine_layout']['dewatering_load'] = action.payload.data.timeseries
        },
        [hoisting.fulfilled]: (state, action) => {
            state['mine_layout']['hoisting_load'] = action.payload.data.timeseries
        },
        [ventilation.fulfilled]: (state, action) => {
            state['mine_layout']['ventilation_load'] = action.payload.data.timeseries
        },
        [comminution.fulfilled]: (state, action) => {
            state['mine_layout']['comminution_load'] = action.payload.data.timeseries
        },
        [super_load_constructor.fulfilled]: (state, action) => {
            state['mine_layout'][action.payload.data.string] = action.payload.data.timeseries
        },
        [super_dewatering.fulfilled]: (state, action) => {
            state['mine_layout']['dewatering_load'] = action.payload.data.timeseries
        },
        [super_hoisting.fulfilled]: (state, action) => {
            state['mine_layout']['hoisting_load'] = action.payload.data.timeseries
        },
        [super_ventilation.fulfilled]: (state, action) => {
            state['mine_layout']['ventilation_load'] = action.payload.data.timeseries
        },
        [super_comminution.fulfilled]: (state, action) => {
            state['mine_layout']['comminution_load'] = action.payload.data.timeseries
        },
        // grid design demo

        [load_loaddata_from_DS_backend.fulfilled]: (state, action) => {
            // update the value of json files
            // state['gd_mine_demo']['pp_file_name'] = action.payload.data.full_ppfile_name


            state['gd_mine_demo']['ho_load_max_active_load_mw'] = action.payload.data.ho_load_max_active_load_mw
            state['gd_mine_demo']['ho_load_min_active_load_mw'] = action.payload.data.ho_load_min_active_load_mw

            state['gd_mine_demo']['de_load_max_active_load_mw'] = action.payload.data.de_load_max_active_load_mw
            state['gd_mine_demo']['de_load_min_active_load_mw'] = action.payload.data.de_load_min_active_load_mw

            state['gd_mine_demo']['co_load_max_active_load_mw'] = action.payload.data.co_load_max_active_load_mw
            state['gd_mine_demo']['co_load_min_active_load_mw'] = action.payload.data.co_load_min_active_load_mw

            state['gd_mine_demo']['cr_load_max_active_load_mw'] = action.payload.data.cr_load_max_active_load_mw
            state['gd_mine_demo']['cr_load_min_active_load_mw'] = action.payload.data.cr_load_min_active_load_mw

            state['gd_mine_demo']['ve_load_max_active_load_mw'] = action.payload.data.ve_load_max_active_load_mw
            state['gd_mine_demo']['ve_load_min_active_load_mw'] = action.payload.data.ve_load_min_active_load_mw

            state['gd_mine_demo']['dr_load_max_active_load_mw'] = action.payload.data.dr_load_max_active_load_mw
            state['gd_mine_demo']['dr_load_min_active_load_mw'] = action.payload.data.dr_load_min_active_load_mw

            state['gd_mine_demo']['tr_load_max_active_load_mw'] = action.payload.data.tr_load_max_active_load_mw
            state['gd_mine_demo']['tr_load_min_active_load_mw'] = action.payload.data.tr_load_min_active_load_mw


        },

        [load_gendata_from_DS_backend.fulfilled]: (state, action) => {
            // update the value of json files
            // state['gd_mine_demo']['pp_file_name'] = action.payload.data.full_ppfile_name

            state['gd_mine_demo']['ext_grid_capacity_mva'] = action.payload.data.ext_grid_capacity_mva

            state['gd_mine_demo']['wind_capacity_mw'] = action.payload.data.wind_capacity_mw
            state['gd_mine_demo']['wind_max_output_mw'] = action.payload.data.wind_max_output_mw

            state['gd_mine_demo']['solar_capacity_mw'] = action.payload.data.solar_capacity_mw
            state['gd_mine_demo']['solar_max_output_mw'] = action.payload.data.solar_max_output_mw

            state['gd_mine_demo']['generator_capacity_mva'] = action.payload.data.generator_capacity_mva

        },


        [call_api_cable_assign_qdver_backend.fulfilled]: (state, action) => {
            // update the value of json files
            // state['gd_mine_demo']['pp_file_name'] = action.payload.data.full_ppfile_name
            state['gd_mine_demo']['indicator_cable_assign_finish'] = action.payload.data.indicator_cable_assign_finish
        },


        [call_api_power_loss_qdver_backend.fulfilled]: (state, action) => {
            // update the value of json files
            // state['gd_mine_demo']['pp_file_name'] = action.payload.data.full_ppfile_name
            state['gd_mine_demo']['indicator_power_loss_calculation_finish'] = action.payload.data.indicator_power_loss_calculation_finish
        },

        [call_api_grid_optimize_qdver_backend.fulfilled]: (state, action) => {
            // update the value of json files
            // state['gd_mine_demo']['pp_file_name'] = action.payload.data.full_ppfile_name

            // An indicator to show if the results exist or not
            state["gd_mine_demo"]["indicator_optimal_solution_exist"] = action.payload.data.indicator_optimal_solution_exist

            // basic information

            state['gd_mine_demo']['optimal_voltage_rating'] = action.payload.data.optimal_voltage_rating
            state['gd_mine_demo']['cable_design_results'] = action.payload.data.cable_design_results
            state['gd_mine_demo']['total_cable_cost_euro'] = action.payload.data.total_cable_cost_euro

            // cable type for each cable
            state['gd_mine_demo']['generator_cable_type'] = action.payload.data.generator_cable_type
            state['gd_mine_demo']['solar_cable_type'] = action.payload.data.solar_cable_type
            state['gd_mine_demo']['wind_cable_type'] = action.payload.data.wind_cable_type
            state['gd_mine_demo']['battery_cable_type'] = action.payload.data.battery_cable_type

            state['gd_mine_demo']['ho_load_cable_type'] = action.payload.data.ho_load_cable_type
            state['gd_mine_demo']['de_load_cable_type'] = action.payload.data.de_load_cable_type
            state['gd_mine_demo']['co_load_cable_type'] = action.payload.data.co_load_cable_type
            state['gd_mine_demo']['cr_load_cable_type'] = action.payload.data.cr_load_cable_type
            state['gd_mine_demo']['ve_load_cable_type'] = action.payload.data.ve_load_cable_type
            state['gd_mine_demo']['dr_load_cable_type'] = action.payload.data.dr_load_cable_type
            state['gd_mine_demo']['tr_load_cable_type'] = action.payload.data.tr_load_cable_type
            state['gd_mine_demo']['sh_load_cable_type'] = action.payload.data.sh_load_cable_type

            // cable length for each line
            state['gd_mine_demo']['generator_line_length'] = action.payload.data.generator_line_length
            state['gd_mine_demo']['solar_line_length'] = action.payload.data.solar_line_length
            state['gd_mine_demo']['wind_line_length'] = action.payload.data.wind_line_length
            state['gd_mine_demo']['battery_line_length'] = action.payload.data.battery_line_length

            state['gd_mine_demo']['ho_load_line_length'] = action.payload.data.ho_load_line_length
            state['gd_mine_demo']['de_load_line_length'] = action.payload.data.de_load_line_length
            state['gd_mine_demo']['co_load_line_length'] = action.payload.data.co_load_line_length
            state['gd_mine_demo']['cr_load_line_length'] = action.payload.data.cr_load_line_length
            state['gd_mine_demo']['ve_load_line_length'] = action.payload.data.ve_load_line_length
            state['gd_mine_demo']['dr_load_line_length'] = action.payload.data.dr_load_line_length
            state['gd_mine_demo']['tr_load_line_length'] = action.payload.data.tr_load_line_length
            state['gd_mine_demo']['sh_load_line_length'] = action.payload.data.sh_load_line_length

            // cable cost for each line

            state['gd_mine_demo']['generator_cable_cost'] = action.payload.data.generator_cable_cost
            state['gd_mine_demo']['solar_cable_cost'] = action.payload.data.solar_cable_cost
            state['gd_mine_demo']['wind_cable_cost'] = action.payload.data.wind_cable_cost
            state['gd_mine_demo']['battery_cable_cost'] = action.payload.data.battery_cable_cost

            state['gd_mine_demo']['ho_load_cable_cost'] = action.payload.data.ho_load_cable_cost
            state['gd_mine_demo']['de_load_cable_cost'] = action.payload.data.de_load_cable_cost
            state['gd_mine_demo']['co_load_cable_cost'] = action.payload.data.co_load_cable_cost
            state['gd_mine_demo']['cr_load_cable_cost'] = action.payload.data.cr_load_cable_cost
            state['gd_mine_demo']['ve_load_cable_cost'] = action.payload.data.ve_load_cable_cost
            state['gd_mine_demo']['dr_load_cable_cost'] = action.payload.data.dr_load_cable_cost
            state['gd_mine_demo']['tr_load_cable_cost'] = action.payload.data.tr_load_cable_cost
            state['gd_mine_demo']['sh_load_cable_cost'] = action.payload.data.sh_load_cable_cost

            // Materials of the grid
            state['gd_mine_demo']['generator_cable_material'] = action.payload.data.generator_cable_material
            state['gd_mine_demo']['solar_cable_material'] = action.payload.data.solar_cable_material
            state['gd_mine_demo']['wind_cable_material'] = action.payload.data.wind_cable_material
            state['gd_mine_demo']['battery_cable_material'] = action.payload.data.battery_cable_material

            state['gd_mine_demo']['ho_load_cable_material'] = action.payload.data.ho_load_cable_material
            state['gd_mine_demo']['de_load_cable_material'] = action.payload.data.de_load_cable_material
            state['gd_mine_demo']['co_load_cable_material'] = action.payload.data.co_load_cable_material
            state['gd_mine_demo']['cr_load_cable_material'] = action.payload.data.cr_load_cable_material
            state['gd_mine_demo']['ve_load_cable_material'] = action.payload.data.ve_load_cable_material
            state['gd_mine_demo']['dr_load_cable_material'] = action.payload.data.dr_load_cable_material
            state['gd_mine_demo']['tr_load_cable_material'] = action.payload.data.tr_load_cable_material
            state['gd_mine_demo']['sh_load_cable_material'] = action.payload.data.sh_load_cable_material

            // Materials of the grid
            state['gd_mine_demo']['generator_cable_opex_per_year'] = action.payload.data.generator_cable_opex_per_year
            state['gd_mine_demo']['solar_cable_opex_per_year'] = action.payload.data.solar_cable_opex_per_year
            state['gd_mine_demo']['wind_cable_opex_per_year'] = action.payload.data.wind_cable_opex_per_year
            state['gd_mine_demo']['battery_cable_opex_per_year'] = action.payload.data.battery_cable_opex_per_year

            state['gd_mine_demo']['ho_load_cable_opex_per_year'] = action.payload.data.ho_load_cable_opex_per_year
            state['gd_mine_demo']['de_load_cable_opex_per_year'] = action.payload.data.de_load_cable_opex_per_year
            state['gd_mine_demo']['co_load_cable_opex_per_year'] = action.payload.data.co_load_cable_opex_per_year
            state['gd_mine_demo']['cr_load_cable_opex_per_year'] = action.payload.data.cr_load_cable_opex_per_year
            state['gd_mine_demo']['ve_load_cable_opex_per_year'] = action.payload.data.ve_load_cable_opex_per_year
            state['gd_mine_demo']['dr_load_cable_opex_per_year'] = action.payload.data.dr_load_cable_opex_per_year
            state['gd_mine_demo']['tr_load_cable_opex_per_year'] = action.payload.data.tr_load_cable_opex_per_year
            state['gd_mine_demo']['sh_load_cable_opex_per_year'] = action.payload.data.sh_load_cable_opex_per_year

            state['gd_mine_demo']['total_opex_capex_whole_system'] = action.payload.data.total_opex_capex_whole_system

        },

        [call_api_steady_state_analysis_qdver_backend.fulfilled]: (state, action) => {
            // update the value of json files
            // state['gd_mine_demo']['pp_file_name'] = action.payload.data.full_ppfile_name


            state['gd_mine_demo']['optimal_voltage_rating'] = action.payload.data.optimal_voltage_rating
            state['gd_mine_demo']['bus_name'] = action.payload.data.bus_name
            state['gd_mine_demo']['line_name'] = action.payload.data.line_name
            state['gd_mine_demo']['cable_design_results'] = action.payload.data.cable_design_results

            state['gd_mine_demo']['volt_profile_max_res_max_load'] = action.payload.data.volt_profile_max_res_max_load
            state['gd_mine_demo']['volt_profile_max_res_min_load'] = action.payload.data.volt_profile_max_res_min_load
            state['gd_mine_demo']['volt_profile_max_res_zero_load'] = action.payload.data.volt_profile_max_res_zero_load
            state['gd_mine_demo']['volt_profile_min_res_max_load'] = action.payload.data.volt_profile_min_res_max_load
            state['gd_mine_demo']['volt_profile_min_res_min_load'] = action.payload.data.volt_profile_min_res_min_load
            state['gd_mine_demo']['volt_profile_min_res_zero_load'] = action.payload.data.volt_profile_min_res_zero_load

            state['gd_mine_demo']['current_max_res_max_load'] = action.payload.data.current_max_res_max_load
            state['gd_mine_demo']['current_max_res_min_load'] = action.payload.data.current_max_res_min_load
            state['gd_mine_demo']['current_max_res_zero_load'] = action.payload.data.current_max_res_zero_load
            state['gd_mine_demo']['current_min_res_max_load'] = action.payload.data.current_min_res_max_load
            state['gd_mine_demo']['current_min_res_min_load'] = action.payload.data.current_min_res_min_load
            state['gd_mine_demo']['current_min_res_zero_load'] = action.payload.data.current_min_res_zero_load

        },

        [call_api_data_conversion_qdver_backend.fulfilled]: (state, action) => {
            // update the value of json files
            // state['gd_mine_demo']['pp_file_name'] = action.payload.data.full_ppfile_name

            state['gd_mine_demo']['grid_design_results_file_path'] = action.payload.data.grid_design_results_file_path
            state['gd_mine_demo']['grid_design_results_DGS_file_path'] = action.payload.data.grid_design_results_DGS_file_path
            state['gd_mine_demo']['indicator_data_conversion_finish'] = action.payload.data.indicator_data_conversion_finish

        },


            //TESSSST
        
    }
})

const { updateTopNavi } = siteSlice.actions
const { updateTopGraph } = siteSlice.actions
const { updateSimNavi } = siteSlice.actions

export { fetchSite, updateTopNavi, updateTopGraph, updatesSite, updateSimNavi, fetchVehicleToGridMode, minExample, exportPNG, exportReport, download, basicOptimization, downloadWeatherData, load_constructor, load_summation, load_summation_hours, dewatering, hoisting, ventilation, comminution, super_load_constructor, super_dewatering, super_hoisting, super_ventilation, super_comminution }


// quick-and-dirty version 

export { load_loaddata_from_DS_backend, load_gendata_from_DS_backend }

export { call_api_cable_assign_qdver_backend, call_api_grid_optimize_qdver_backend, call_api_steady_state_analysis_qdver_backend, call_api_data_conversion_qdver_backend }
export { call_api_power_loss_qdver_backend }

export default siteSlice.reducer

