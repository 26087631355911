import { configureStore } from '@reduxjs/toolkit'
import identReducer from './slices/identSlice'
import siteReducer from './slices/siteSlice'
import timeseriesFilesReducer from './slices/timeseriesFilesSlice'

export default configureStore({
    reducer: {
        ident: identReducer,
        site: siteReducer,
        timeseriesFiles: timeseriesFilesReducer,
    },
    devTools: process.env.NODE_ENV !== 'production'
})