const sectors = [
    {
        'name': 'Industrial',
        'subsectors': [
            'Chemicals',
            'Electronics', 
            'Food & Beverages', 
            'Leather',
            'Metals', 
            'Minerals', 
            'Mining',
            'Pulp & Paper',
            'Rubber & Plastic',
            'Textiles',
            'Vehicles',
            'Water & Waste',
            'Wood',
            'Other'
        ]
    }, 
    {
        'name': 'Commercial',
        'subsectors': [
            'Entertainment', 
            'Hospital',
            'Hotel',
            'Municipial',
            'Offices',
            'Restaurant',
            'Retail',
            'School'
        ]
    },
    {
        'name': 'Residential',
        'subsectors': ['Residential']
    }
]

export { sectors }