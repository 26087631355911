import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updatesSite, load_constructor } from '../../slices/siteSlice'
import { emptyStringToNull } from './../resources/utilities'
import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css'
import Plot from 'react-plotly.js'
import question from './../resources/Question.PNG'

const Transport = () => {
    const dispatch = useDispatch()
    const ident = useSelector(state => state.site.ident)
    const id = ident.id

    const mine_layout = useSelector(state => state.site.mine_layout)

    // update data of site in backend
    const updateDataBackend = (site, update) => {
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': site, ...update }] }))
    }

    var xData = [NaN]
    var yData = [NaN]
    var title = "No Data (generate transport timeseries)"

    // get visualisation data
    if (mine_layout.transport_load !== null) {
        var xData = [...Array(mine_layout.transport_load.length).keys()]
        var yData = mine_layout.transport_load
        var title = 'Transport load series'
    }

    const callLoadConstructor = (a, b, c, d, name, e) => {


        var num_processes = parseFloat(a)
        var avg = parseFloat(b)
        var std = parseFloat(c)
        var tons_per_day = parseFloat(d)
        var element_name = name
        var resolution = e


        dispatch(load_constructor({
            'num_processes': num_processes, 'avg': avg, 'std': std, 'element_name': element_name, 'tons_per_day': tons_per_day, 'resolution': mine_layout.hours
        }))

    }
    const explanation_text = "Transport is modelled by a Normal distribution with average energy consumption μ " +
        "and standard deviation σ = deviation[%] *μ. A more detailed model is planned for future extensions. " +
        "Transport is assumed with an average of μ = 14 kWh/t (surface transport) + 6 (underground transport) kWh/t. \n Reference: Benchmarking the Energy Consumption of Canadian Underground Bulk Mines, " +
        "Candadian Industry Program for Energy Conservation, 2005. A conversion factor of 2 has been used to transform given kWhe/ton to kWh/ton."

    // useEffect(() => {
    //     updateDataBackend('mine_layout', { key: 'transport_tons_hours', value: mine_layout.transport_tons / 24 })
    // }, [mine_layout.transport_tons])

    // useEffect(() => {
    //     updateDataBackend('mine_layout', { key: 'transport_tons', value: mine_layout.transport_tons_hours * 24 })
    // }, [mine_layout.transport_tons_hours])

    return (
        <div className="fullHeight content" id='export_transport'>
            <div className="fleft" >

                <div className="heading-question">
                    <div className="fleft-heading">
                        <ABB.Heading level={4} text='Enter the daily throughput of the mine' style={{ 'marginLeft': '16px' }} />
                    </div>
                    <div className="fright-heading">
                        <img src={question} style={{ width: '25%' }} onClick={() => window.alert(explanation_text)} />
                    </div>
                </div>
                <div className="heading-question">

                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Transported mass per day [t]'}
                        value={mine_layout.transport_tons}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'transport_tons', value: emptyStringToNull(parseInt(v)) })}
                    />

                    <ABB.Heading level={4} text='Enter Transport Parameters' style={{ 'marginLeft': '16px' }} />

                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Number of transport processes'}
                        value={mine_layout.transport_processes}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'transport_processes', value: emptyStringToNull(parseInt(v)) })}
                    /* onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid)}
                    }} */
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                    />

                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Overall average energy consumption [kWh/t]'}
                        value={mine_layout.transport_avg}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'transport_avg', value: emptyStringToNull(parseFloat(v)) })}
                    /* onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid)}
                    }} */
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                    />

                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Deviation of average energy consumption [%]'}
                        value={mine_layout.transport_std}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'transport_std', value: emptyStringToNull(parseFloat(v)) })}
                    /* onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid)}
                    }} */
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                    />


                    {mine_layout.transport_std != null && mine_layout.transport_avg != null && mine_layout.transport_processes !== null &&
                        <ABB.Button type="primary-red" shape="pill" text="Generate Transport Timeseries" style={{ 'margin-top': '32px', 'margin-left': '50px' }} onClick={() => callLoadConstructor(mine_layout.transport_processes, mine_layout.transport_avg, mine_layout.transport_std, mine_layout.transport_tons, 'transport')} />
                    }
                </div>
            </div>

            <div className="fright" >
                <Plot
                    data={[
                        {
                            x: xData,
                            y: yData,
                            type: 'scatter',
                            name: 'Optimized',
                            marker: { color: '#3366FF' }
                        },
                    ]}
                    layout={{ xaxis: { title: mine_layout.hours }, yaxis: { title: 'Energy [MWh]' }, title: title, width: '90%', height: '90%' }}
                />

            </div>

        </div>
    )
}

export default Transport