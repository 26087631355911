import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updatesSite, hoisting } from '../../slices/siteSlice'
import { emptyStringToNull } from './../resources/utilities'
import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css'
import Plot from 'react-plotly.js'
import question from './../resources/Question.PNG'



const Hoisting = () => {
    const dispatch = useDispatch()
    const ident = useSelector(state => state.site.ident)
    const id = ident.id
    const mine_layout = useSelector(state => state.site.mine_layout)




    var xData = [NaN]
    var yData = [NaN]
    var title = "No Data (generate hoisting timeseries)"

    // get visualisation data
    if (mine_layout.hoisting_load !== null) {
        var xData = [...Array(mine_layout.hoisting_load.length).keys()]
        var yData = mine_layout.hoisting_load
        var title = 'Hoisting load series'
    }



    // update data of site in backend
    const updateDataBackend = (site, update) => {
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': site, ...update }] }))
    }


    const callHoisting = (a, b, c, d) => {


        var depth = parseFloat(a)
        var std = parseFloat(b)
        var mass = parseFloat(c)
        var resolution = d


        dispatch(hoisting({
            'daily_production': mass, 'std': std, 'depth': depth, 'resolution': mine_layout.hours
        }))

    }

    const explanation_text = "The hosting energy consumption model is derived from the 'travel diagram' and associated 'cycle time'. " +
        "The 'travel diagram' is obtained by assuminmg the parameter related to loading, dumping, creep, acceleration and deceleration. This leads to a cycle time which is used " +
        "to calculate the total payload. Rope selection calulation are also included here. As a final step, the effective motor power and energy consumption per hoisted ton is calculated.\n" +
        "Reference: Advances in Mining Science and Technology, Elsevier, Volume 6, 1990, Pages 141 - 159, ISSN 0921 - 8602, \nISBN 9780444882721,\nhttps://doi.org/10.1016/B978-0-444-88272-1.50009-1"

    // useEffect(() => {
    //     updateDataBackend('mine_layout', { key: 'hoisting_hourly_mass', value: mine_layout.hoisting_daily_mass / 24 })
    // }, [mine_layout.hoisting_daily_mass])

    // useEffect(() => {
    //     updateDataBackend('mine_layout', { key: 'hoisting_daily_mass', value: mine_layout.hoisting_hourly_mass * 24 })
    // }, [mine_layout.hoisting_hourly_mass])
    return (
        <div className="fullHeight content" id='export_hoisting'>

            <div className="fleft" >

                <div className="heading-question">
                    <div className="fleft-heading">
                        <ABB.Heading level={4} text='Enter hoisting parameters' style={{ 'marginLeft': '16px' }} />
                    </div>
                    <div className="fright-heading">
                        <img src={question} style={{ width: '25%' }} onClick={() => window.alert(explanation_text)} />
                    </div>
                </div>
                <div className="heading-question">

                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Daily Hoisting Mass [t]'}
                        value={mine_layout.hoisting_daily_mass}
                        onValueChange={v => [updateDataBackend('mine_layout', { key: 'hoisting_daily_mass', value: emptyStringToNull(parseFloat(v)) })]}
                    />

                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Hoisting Depth [m]'}
                        value={mine_layout.hoisting_depth}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'hoisting_depth', value: emptyStringToNull(parseFloat(v)) })}
                    /* onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid)}
                    }} */
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                    />

                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Deviation of average energy consumption [%]'}
                        value={mine_layout.hoisting_std}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'hoisting_std', value: emptyStringToNull(parseFloat(v)) })}
                    /* onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid)}
                    }} */
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                    />



                    {mine_layout.hoisting_std != null && mine_layout.hoisting_depth != null && mine_layout.hoisting_daily_mass !== null &&
                        <ABB.Button type="primary-red" shape="pill" text="Generate Hoisting Timeseries" style={{ 'margin-top': '32px', 'margin-left': '50px' }} onClick={() => callHoisting(mine_layout.hoisting_depth, mine_layout.hoisting_std, mine_layout.hoisting_daily_mass)} />
                    }
                </div>
            </div>
            <div className="fright" >
                <Plot
                    data={[
                        {
                            x: xData,
                            y: yData,
                            type: 'scatter',
                            name: 'Optimized',
                            marker: { color: '#3366FF' }
                        },
                    ]}
                    layout={{ xaxis: { title: 'Days' }, yaxis: { title: 'Energy [MWh]' }, title: title, width: '90%', height: '90%' }}
                />

            </div>

        </div>
    )
}

export default Hoisting