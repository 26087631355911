import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { register,updateIdent } from '../../slices/identSlice'
import { withRouter } from 'react-router-dom'
import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css'


const Register_skip = (props) => {

    const dispatch = useDispatch()
    const [warnings, setWarnings] = useState([])

    const ident=useSelector(state => state.ident)  

    useEffect(() => {
        if (ident.id) {
            props.history.push('/' + ident.id)
        }
    }, [ident.id, props.history])

    const handleSubmitSkip = ident => {
        const _warnings = []

        setWarnings(_warnings)
        if (_warnings.length < 1) {
            dispatch(register(ident))
        }        
    }

    return (
        <div class="button-container">
            <button type='submit' text='skip.' onClick={(dialog) => {handleSubmitSkip(ident)}
                }/>
        </div>
    )
}

export default withRouter(Register_skip)