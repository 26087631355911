import React, { useEffect, useState } from 'react'
import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css'
import html2canvas from 'html2canvas'


function captureElementAsBase64(elementId) {
    return new Promise((resolve, reject) => {
        // Get the element you want to capture
        var container = document.getElementById(elementId);
        //imgData.width = 500

        // Use html2canvas to capture the element
        html2canvas(container).then(function (canvas) {
            // Convert the canvas to a base64 PNG image
            var base64String = canvas.toDataURL('image/png');

            resolve(base64String); // Resolve the Promise with the base64 string
        });
    });
}
//const export_png = (name) => {
    
//    function captureElementAsBase64(elementId) {
//        return new Promise((resolve, reject) => {
//            // Get the element you want to capture
//            var container = document.getElementById(elementId);
//            //imgData.width = 500

//            // Use html2canvas to capture the element
//            html2canvas(container).then(function (canvas) {
//                // Convert the canvas to a base64 PNG image
//                var base64String = canvas.toDataURL('image/png');

//                resolve(base64String); // Resolve the Promise with the base64 string
//            });
//        });
//    }
//    // Usage example:
//    var base64String = 'test'

//    base64String = captureElementAsBase64(name).then(function (base64String) {
//        console.log(base64String)
//        return base64String
//    });
//    var test = 'this is a test'
//    console.log(base64String)
//    return base64String

//}
export default captureElementAsBase64