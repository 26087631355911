import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updatesSite, dewatering } from '../../slices/siteSlice'
import { emptyStringToNull } from './../resources/utilities'
import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css'
import Plot from 'react-plotly.js'
import question from './../resources/Question.PNG'


const Dewatering = () => {
    const dispatch = useDispatch()
    const ident = useSelector(state => state.site.ident)
    const id = ident.id

    const mine_layout = useSelector(state => state.site.mine_layout)

    // update data of site in backend
    const updateDataBackend = (site, update) => {
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': site, ...update }] }))
    }

    // get visualisation data
    if (mine_layout.dewatering_load !== null) {
        var xData = [...Array(mine_layout.dewatering_load.length).keys()]
        var yData = mine_layout.dewatering_load
        var title = 'Dewatering load series'
    }

    const callDewatering = (a, b, c, d) => {


        var height = parseFloat(a)
        var std = parseFloat(b)
        var mass = parseFloat(c)
        var resolution = d



        dispatch(dewatering({
            'mass': mass, 'std': std, 'height': height, 'resolution': mine_layout.hours
        }))

    }

    const explanation_text = "Power consumption of dewatering system based pumping station head. Input parameters: \n" +
        "M_d: mass of water pumped per day kg\n" +
        "H_tot: total head of pumping station m\n" +
        "g: gravit acceleration constant m / s ^ 2\n" +
        "Reference: https://ieeexplore.ieee.org/document/8636600\n" +
        "In order to align this model with the amount of tonnage to be hoisted, a dewatering mass of 0.03 tonne per hoisted tonne is assumed. Therewith, the energy demand is aligned with \n" +
        "the following reference: Benchmarking the Energy Consumption of Canadian Underground Bulk Mines, " +
        "Candadian Industry Program for Energy Conservation, 2005."

    return (
        <div className="fullHeight content" id='export_dewatering'>
            <div className="fleft" >

                <div className="heading-question">
                    <div className="fleft-heading">
                        <ABB.Heading level={4} text='Enter the daily water throughput the mine' style={{ 'marginLeft': '16px' }} />
                    </div>
                    <div className="fright-heading">
                        <img src={question} style={{ width: '25%' }} onClick={() => window.alert(explanation_text)} />
                    </div>
                </div>
                <div className="heading-question">

                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Dewatering Water Mass [t]'}
                        value={mine_layout.dewatering_mass}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'dewatering_mass', value: emptyStringToNull(parseInt(v)) })}
                    /* onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid)}
                    }} */
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                    />

                    <ABB.Heading level={4} text='Enter Dewatering Parameters' style={{ 'marginLeft': '16px' }} />

                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Dewatering Height'}
                        value={mine_layout.dewatering_height}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'dewatering_height', value: emptyStringToNull(parseInt(v)) })}
                    /* onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid)}
                    }} */
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                    />

                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Dewatering Standard Deviation [MWh/t]'}
                        value={mine_layout.dewatering_std}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'dewatering_std', value: emptyStringToNull(parseFloat(v)) })}
                    /* onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid)}
                    }} */
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                    />




                    {mine_layout.dewatering_std != null && mine_layout.dewatering_height != null && mine_layout.dewatering_mass !== null &&
                        <ABB.Button type="primary-red" shape="pill" text="Generate Dewatering Timeseries" style={{ 'margin-top': '32px', 'margin-left': '50px' }} onClick={() => callDewatering(mine_layout.dewatering_height, mine_layout.dewatering_std, mine_layout.dewatering_mass)} />
                    }
                </div>
            </div>

            <div className="fright" >

                <Plot
                    data={[
                        {
                            x: xData,
                            y: yData,
                            type: 'scatter',
                            name: 'Optimized',
                            marker: { color: '#3366FF' }
                        },
                    ]}
                    layout={{ xaxis: { title: mine_layout.hours }, yaxis: { title: 'Energy [MWh]' }, title: title, width: '90%', height: '90%' }}
                />

            </div>
        </div>
    )
}

export default Dewatering