import React, { useState, useEffect } from 'react'
import '../../App.css'
import { useSelector, useDispatch } from 'react-redux'
import * as Plotly from '../../../node_modules/plotly.js'
import Plot from '../../../node_modules/react-plotly.js'
import '@abb/abb-common-ux-react/styles.css'
import sizeMe from '../../../node_modules/react-sizeme'

//colors
var pv_opex_color = '#FFBC42'
var pv_capex_color = '#D81159'
var wind_opex_color = '#0d31bf'
var wind_capex_color = '#73D2DE'
var el_cost_color = '#218380'

var res_l_color = '#FFBC42'
var pv_l__color = '#D81159'
var wind_l_color = '#218380'
var base_l_color = '#73D2DE'
var diesel_l_color = '#70e09d'

// helper functions


//function to create matrix arrays
const stackArr = (a, b) => {
    let arr = []
    var i = 0
    for (i = 0; i < a.length; i++) {
        arr.push(a[i])
        arr.push(b[i])
    }
    return arr
}

//function for cumulative sum
const cumulativeSum = (x) => {
    let arr = Array(x.length).fill(0)
    var i = 0
    for (i = 0; i < x.length; i++) {
        if (i == 0) {
            arr[i] = x[i]
        }
        else {
            arr[i] = arr[i - 1] + x[i]
        }
    }
    return arr
}

const getXTicks = (lifetime, y_lim) => {
    var x_lab = []
    var x_tick = []
    var x_tick2 = []
    var x_tick_grid = []
    var y_tick_grid = []
    var i = 0
    for (i = 0; i < lifetime + 1; i++) {
        x_lab.push("RES")
        x_lab.push("<br> " + String(i))
        x_lab.push("BAU")
        x_tick2.push(i)
        x_tick2.push(i + 0.25)
        x_tick2.push(i + 0.5)
        x_tick.push(i)
        x_tick.push(i + 0.5)
        x_tick_grid.push(i + 0.75)
        x_tick_grid.push(i + 0.75)
        x_tick_grid.push(i + 1)
        y_tick_grid.push(0)
        y_tick_grid.push(y_lim)
        y_tick_grid.push(NaN)
    }
    return { x_lab, x_tick, x_tick2, x_tick_grid, y_tick_grid }
}



//graph A
const GraphA = (props) => {
    const BO = useSelector(state => state.site.basicOptimization)
    const mine_layout = useSelector(state => state.site.mine_layout)

    const getLoadX = () => {

        console.log(mine_layout.total_load)
        if (!(mine_layout.total_load == undefined)){
            if (mine_layout.total_load.length) {
                var x = mine_layout.total_load.values.length
            }
            else {
                var x = []
            }
        }
        else {
            var x = []
        }
        return x
    }

    const data = 
        [
        {
            x: getLoadX(),
            y: BO.series_load,
            //fill: 'tozeroy',
            type: 'scatter',
            name: 'Base Load',
            marker: { color: base_l_color },
        },
        {
            x: getLoadX(),
            y: BO.series_pv,
            //fill: 'tozeroy',
            type: 'scatter',
            name: 'PV Production',
            marker: { color: pv_l__color }
        },
        {
            x: getLoadX(),
            y: BO.series_wind,
            //fill: 'tozeroy',
            type: 'scatter',
            name: 'Wind Production',
            marker: { color: wind_l_color }
        },
        {
            x: getLoadX(),
            y: BO.series_el,
            //fill: 'tozeroy',
            type: 'scatter',
            name: 'Grid Production',
            marker: { color: res_l_color }
        },
        {
            x: getLoadX(),
            y: BO.series_diesel,
            //fill: 'tozeroy',
            type: 'scatter',
            name: 'Diesel Production',
            marker: { color: diesel_l_color }
            },
        ]
    const layout = {
        legend: {
            font: { family: 'sans-serif' },
        },
        xaxis: { title: mine_layout.hours, titlefont: { }, },
        yaxis: {
            title: 'Energy [MWh]',
                titlefont: { },
        },
        yaxis2: {
            title: 'Cost [Euro]',
                titlefont: { },
            tickfont: { },
            overlaying: 'y',
                side: 'right'
        },
        //title: 'Load Distribution per year', 
        width: props.size.width,
            autosize: props.size.autosize,
        }


    return (
        <div id='export_plot_LoadDistribution' >
            <div>
                <Plot
                    data={data}
                    layout={layout}
                />
                <div id="graph" style={{ display: "none" }}></div>
            </div>
        </div>
    );

}


const GraphB = (props) => {

    //get parameters
    const BO = useSelector(state => state.site.basicOptimization)
    const lifetime = BO.lifetime
    var details_bau = BO.costs_bau_detail
    var details_res = BO.costs_res_detail

    if (details_res == null || details_bau == null) {
        details_res = Array(6).fill(NaN)
        details_bau = Array(6).fill(NaN)
    }

    //get pv-opex
    const pv_opex_all = stackArr(details_res[0], details_bau[0])

    //get pv-capex    
    const pv_capex_all = stackArr(details_res[1], details_bau[1])

    //get wind-opex
    const wind_opex_all = stackArr(details_res[2], details_bau[2])

    //get wind-capex    
    const wind_capex_all = stackArr(details_res[3], details_bau[3])


    //get electricity costs
    const el_cost_all = stackArr(details_res[4], details_bau[4])

    //get diesel costs
    const diesel_cost_all = stackArr(details_res[5], details_bau[5])

    //define graph maximum
    var sum_bau = 0
    var sum_res = 0
    var i = 0
    var n = 0
    for (n = 0; n < lifetime + 1; n++) {
        const res = pv_opex_all[i] + pv_capex_all[i] + wind_opex_all[i] + wind_capex_all[i] + el_cost_all[i] + diesel_cost_all[i]
        if (res > sum_res) {
            sum_res = res
        }
        const bau = pv_opex_all[i + 1] + pv_capex_all[i + 1] + wind_opex_all[i + 1] + wind_capex_all[i + 1] + el_cost_all[i + 1] + diesel_cost_all[i + 1]
        if (bau > sum_bau) {
            sum_bau = bau
        }
        i = i + 2
    }

    const y_lim = Math.max(sum_bau, sum_res)

    //define x-axis
    const tmp7 = getXTicks(lifetime, y_lim)
    const x_lab = tmp7.x_lab
    const x_tick = tmp7.x_tick
    const x_tick2 = tmp7.x_tick2
    const x_tick_grid = tmp7.x_tick_grid
    const y_tick_grid = tmp7.y_tick_grid

    return (
        <div id='export_plot_CostPerYear' >
            <Plot 
                data={[
                    {
                        x: x_tick_grid,
                        y: y_tick_grid,
                        type: "line",
                        name: "",
                        marker: {
                            color: "#CAC6C6",
                            opacity: 0.8,
                        },
                        line: { width: 0.8 },
                        mode: "lines",
                        showlegend: false,
                    },
                    {
                        x: x_tick,
                        y: pv_opex_all,
                        type: "bar",
                        name: "PV Opex",
                        marker: { color: pv_opex_color }
                    },
                    {
                        x: x_tick,
                        y: pv_capex_all,
                        type: "bar",
                        name: "PV Capex",
                        marker: { color: pv_capex_color }
                    },
                    {
                        x: x_tick,
                        y: wind_opex_all,
                        type: "bar",
                        name: "Wind Opex",
                        marker: { color: wind_opex_color }
                    },
                    {
                        x: x_tick,
                        y: wind_capex_all,
                        type: "bar",
                        name: "Wind Capex",
                        marker: { color: wind_capex_color }
                    },
                    {
                        x: x_tick,
                        y: el_cost_all,
                        type: "bar",
                        name: "Electricity Cost",
                        marker: {
                            color: el_cost_color,
                            opacity: 0.8,
                        }

                    },
                    {
                        x: x_tick,
                        y: diesel_cost_all,
                        type: "bar",
                        name: "Diesel Cost",
                        marker: {
                            color: diesel_l_color,
                            opacity: 0.8,
                        }
                    },
                ]}
                layout={{
                    barmode: "stack",
                    title: 'Renewable Scenario (RES) vs. Business as usual (BAU)',
                    xaxis: {
                        type: 'linear', //'multicategory',
                        title: 'Years',
                        tickvals: x_tick2,
                        ticktext: x_lab,
                    },
                    width: props.size.width,
                    autosize: props.size.autosize,

                    yaxis: {
                        type: '',
                        name: 'Value [Euro]',
                        title: 'Cost [Euro]',
                    }
                }
                }
            />
        </div>

    )
}

const GraphC = (props) => {

    //get parameters
    const BO = useSelector(state => state.site.basicOptimization)
    const lifetime = BO.lifetime

    var details_bau = BO.costs_bau_detail
    var details_res = BO.costs_res_detail

    if (details_res == null || details_bau == null) {
        details_res = Array(6).fill(NaN)
        details_bau = Array(6).fill(NaN)
    }

    //get pv-opex
    const pv_opex_all_cum = stackArr(cumulativeSum(details_res[0]), cumulativeSum(details_bau[0]))

    //get pv-capex    
    const pv_capex_all_cum = stackArr(cumulativeSum(details_res[1]), cumulativeSum(details_bau[1]))

    //get wind-opex
    const wind_opex_all_cum = stackArr(cumulativeSum(details_res[2]), cumulativeSum(details_bau[2]))

    //get wind-capex    
    const wind_capex_all_cum = stackArr(cumulativeSum(details_res[3]), cumulativeSum(details_bau[3]))


    //get electricity costs
    const el_cost_all_cum = stackArr(cumulativeSum(details_res[4]), cumulativeSum(details_bau[4]))

    //get diesel costs
    const diesel_cost_all_cum = stackArr(cumulativeSum(details_res[5]), cumulativeSum(details_bau[5]))


    //// const break-even
    const bep = BO.break_even
    var bep_col = "#ff0000"
    if (bep > BO.lifetime) {
        bep_col = "#ffffff"
    }

    let sum_bau = []
    let sum_res = []
    var i = 0
    var n = 0
    for (n = 0; n < lifetime + 1; n++) {
        const res = pv_opex_all_cum[i] + pv_capex_all_cum[i] + wind_opex_all_cum[i] + wind_capex_all_cum[i] + el_cost_all_cum[i] + diesel_cost_all_cum[i]
        sum_res.push(res)
        const bau = pv_opex_all_cum[i + 1] + pv_capex_all_cum[i + 1] + wind_opex_all_cum[i + 1] + wind_capex_all_cum[i + 1] + el_cost_all_cum[i + 1] + diesel_cost_all_cum[i + 1]
        sum_bau.push(bau)
        i = i + 2
    }
    const y_lim = Math.max(sum_bau[lifetime], sum_res[lifetime]) * 1.1

    //define x-axis
    const tmp7 = getXTicks(lifetime, y_lim)
    const x_lab = tmp7.x_lab
    const x_tick = tmp7.x_tick
    const x_tick2 = tmp7.x_tick2
    const x_tick_grid = tmp7.x_tick_grid
    const y_tick_grid = tmp7.y_tick_grid

    return (
        <div id='export_plot_CumulativeCost' >
            <Plot
                data={[

                    {
                        x: x_tick_grid,
                        y: y_tick_grid,
                        type: "line",
                        name: "",
                        marker: {
                            color: "#CAC6C6",
                            opacity: 0.8,
                        },
                        line: { width: 0.8 },
                        mode: "lines",
                        showlegend: false,
                    },
                    {
                        x: x_tick,
                        y: pv_opex_all_cum,
                        type: "bar",
                        name: "PV Opex",
                        marker: { color: pv_opex_color }
                    },
                    {
                        x: x_tick,
                        y: pv_capex_all_cum,
                        type: "bar",
                        name: "PV Capex",
                        marker: { color: pv_capex_color }
                    },
                    {
                        x: x_tick,
                        y: wind_opex_all_cum,
                        type: "bar",
                        name: "wind opex",
                        marker: { color: wind_opex_color }
                    },
                    {
                        x: x_tick,
                        y: wind_capex_all_cum,
                        type: "bar",
                        name: "wind capex",
                        marker: { color: wind_capex_color }
                    },
                    {
                        x: x_tick,
                        y: el_cost_all_cum,
                        type: "bar",
                        name: "electricity cost",
                        marker: {
                            color: el_cost_color,
                            opacity: 0.8,
                        }

                    },
                    {
                        x: x_tick,
                        y: diesel_cost_all_cum,
                        type: "bar",
                        name: "diesel cost",
                        marker: {
                            color: diesel_l_color,
                            opacity: 0.8,
                        }
                    },

                    {
                        x: [bep + 0.75, bep + 0.75],
                        y: [0, y_lim],
                        type: "line",
                        name: "break-even point",

                        marker: {
                            color: bep_col,
                            opacity: 0.8,
                        },
                        mode: "lines"
                    },
                ]}
                layout={{
                    barmode: "stack",
                    title: 'Renewable Scenario (RES) vs. Business as usual (BAU) (Cumulative)',
                    xaxis: {
                        type: 'linear', //'multicategory',
                        title: 'Years',
                        tickvals: x_tick2,
                        ticktext: x_lab,
                        limit: [-0.5, lifetime + 0.5]
                    },

                    yaxis: {
                        type: '',
                        name: 'Value [Euro]',
                        title: 'Cost [Euro]',
                        limit: y_lim,
                    },
                    width: props.size.width,
                    autosize: props.size.autosize,
                }
                }
                useResizeHandler
            />
    </div>
    )
}

const GraphD = (props) => {

    //get parameters
    const BO = useSelector(state => state.site.basicOptimization)
    const mine_layout = useSelector(state => state.site.mine_layout)
    const lifetime = BO.lifetime

    const sLabels = (mode, load, mine, name) => {
        const load_mwh = (load / 365).toFixed(1)
        const load_mwht = (load_mwh / mine.tons_produced_all * 1000).toFixed(1)

        if (mode == "det") {
            return name + " " + load_mwh.toString() + " MWh" + "(" + load_mwht.toString() + "kWh/ton)"
        }
        if (mode == "raw") {
            return name + " " + load_mwh.toString() + " MWh"
        }
    }

    var res_l_color = '#FFBC42'
    var pv_l__color = '#D81159'
    var wind_l_color = '#218380'
    var base_l_color = '#73D2DE'
    var diesel_l_color = '#70e09d'

    if (mine_layout.drilling_load != null && mine_layout.drilling_color == true) { var drill_total = mine_layout.drilling_load.reduce((a, b) => a + b, 0) }
    else { var drill_total = 0 }

    if (mine_layout.dewatering_load != null && mine_layout.dewatering_color == true) { var dewatering_total = mine_layout.dewatering_load.reduce((a, b) => a + b, 0) }
    else { var dewatering_total = 0 }

    if (mine_layout.crushing_load != null && mine_layout.crushing_color == true) { var crushing_total = mine_layout.crushing_load.reduce((a, b) => a + b, 0) }
    else { var crushing_total = 0 }

    if (mine_layout.transport_load != null && mine_layout.transport_color == true) { var transport_total = mine_layout.transport_load.reduce((a, b) => a + b, 0) }
    else { var transport_total = 0 }

    if (mine_layout.hoisting_load != null && mine_layout.hoisting_color == true) { var hoisting_total = mine_layout.hoisting_load.reduce((a, b) => a + b, 0) }
    else { var hoisting_total = 0 }

    if (mine_layout.ventilation_load != null && mine_layout.ventilation_color == true) { var ventilation_total = mine_layout.ventilation_load.reduce((a, b) => a + b, 0) }
    else { var ventilation_total = 0 }

    if (mine_layout.comminution_load != null && mine_layout.comminution_color == true) { var comminution_total = mine_layout.comminution_load.reduce((a, b) => a + b, 0) }
    else { var comminution_total = 0 }


    if (mine_layout.total_load != null) { var total_load = mine_layout.total_load.reduce((a, b) => a + b, 0) }
    if (BO.series_diesel != null) { var diesel_total = BO.series_diesel.reduce((a, b) => a + b, 0) }
    if (BO.series_el != null) { var el_total = BO.series_el.reduce((a, b) => a + b, 0) }
    if (BO.series_pv != null) { var pv_total = BO.series_pv.reduce((a, b) => a + b, 0) }
    if (BO.series_wind != null) { var wind_total = BO.series_wind.reduce((a, b) => a + b, 0) }


    //if (BO.pv_real_install != null) {
    //    var pv_label = "PV"+" "+Math.round(BO.pv_real_install).toString()+"MW"
    //}else{
    //    var pv_label = "PV"
    //}
    //if (BO.wind_real_install != null) {
    //    var wind_label = "WIND"+" "+Math.round(BO.wind_real_install).toString()+"MW"
    //}else{
    //    var wind_label = "WIND"
    //}

    //var year_tons = mine_layout.tons_produced*365


    var rest_total = (total_load - (drill_total + dewatering_total + crushing_total + transport_total + hoisting_total + ventilation_total + comminution_total))
    var curtailment = (el_total + diesel_total + pv_total + wind_total) - total_load


    var data = {
        type: "sankey",
        orientation: "h",
        node: {
            pad: 15,
            thickness: 30,
            line: {
                color: "black",
                width: 2
            },
            label: [sLabels("raw", el_total, mine_layout, "GRID"), sLabels("raw", diesel_total, mine_layout, "DIESEL"), sLabels("raw", pv_total, mine_layout, "PV"), sLabels("raw", wind_total, mine_layout, "WIND"),
                "MINE GRID", sLabels("det", rest_total, mine_layout, "REST"), sLabels("det", dewatering_total, mine_layout, 'DEWATERING'), sLabels("det", hoisting_total, mine_layout, "HOISTING"),
            sLabels("det", transport_total, mine_layout, "TRANSPORT"), sLabels("det", crushing_total, mine_layout, "CRUSHING"), sLabels("det", drill_total, mine_layout, "DRILLING"), sLabels("det", ventilation_total, mine_layout, 'VENTILATION'),
            sLabels("det", comminution_total, mine_layout, 'COMMINUTION'), sLabels("det", curtailment, mine_layout, 'CURTAILMENT')],
            color: [res_l_color, diesel_l_color, pv_l__color, wind_l_color, "white", "lightgrey", "lightgrey", "lightgrey", "lightgrey", "lightgrey", "lightgrey", "lightgrey", "lightgrey", "lightgrey"]
        },

        link: {
            source: [0, 1, 2, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4],
            target: [4, 4, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
            value: [el_total, diesel_total, pv_total, wind_total, rest_total, dewatering_total, hoisting_total, transport_total, crushing_total, drill_total, ventilation_total, comminution_total, curtailment]
        }
    }

    var Data = [data]



    return (
        <div id='export_plot_EnergyFlows' >
            <Plot
                data={Data}
                layout={{
                    width: props.size.width,
                    autosize: props.size.autosize,
                    title: "Average energy flow per day"
                }}

            />
        </div>
    )


}

const GraphAs = sizeMe()(GraphA)
const GraphBs = sizeMe()(GraphB)
const GraphCs = sizeMe()(GraphC)
const GraphDs = sizeMe()(GraphD)


export { GraphAs, GraphBs, GraphCs, GraphDs }




