import React, { useState } from 'react'                // react components
import { useSelector, useDispatch } from 'react-redux'                // ...
import '@abb/abb-common-ux-react/styles.css'                          // ...
import '../../App.css'                                                   // ...
import example from './mine_layout.JPG'
import zoom from './../resources/zoom.JPG'
import { exportPNG, exportReport, download, updatesSite} from '../../slices/siteSlice'         // ...
import RC from './resultsContainer'
import { GraphAs, GraphBs, GraphCs, GraphDs } from './graphs'
import { Sim1_part1, Sim1_part2 } from './results_modules'
import * as ABB from '@abb/abb-common-ux-react'                       // ABB components
import '@abb/abb-common-ux-react/styles.css'                          // ...
import Res from './res'
import Drilling from './../mining_components/drilling'
import Crushing from './../mining_components/crushing'
import Transport from './../mining_components/transport'
import Dewatering from './../mining_components/dewatering'
import Hoisting from './../mining_components/hoisting'
import Ventilation from './../mining_components/ventilation'
import Comminution from './../mining_components/comminution'

import User_steps from './user_steps'
import captureElementAsBase64 from './../pdf_export/png_export_function'
import activateTopGraphItem from './resultsContainer'


const Backpage = (props) => {

    // state variables
    const dispatch = useDispatch()
    const ident = useSelector(state => state.site.ident)
    const id = ident.id
    const [warnings, setWarnings] = useState([])

    // content variables
    const grid = useSelector(state => state.site.grid)
    const wind = useSelector(state => state.site.wind)
    const BO = useSelector(state => state.site.basicOptimization)
    const mine_layout = useSelector(state => state.site.mine_layout)
    const ex = useSelector(state => state.site.export)
    const topGraphStates = useSelector(state => state.site.topGraphStates)

    const simStates = useSelector(state => state.site.simStates)

    const getInputButton = (cond1, cond2) => {
        var buttonState = "empty"
        var buttonCol = "gray"
        var buttonOp = "0.4"


        if (cond1 == true) {
            buttonState = "info"
            buttonCol = "red"
            buttonOp = "0.4"
        }
        if (cond1 == true && cond2 == true) {
            buttonState = "ready"
            buttonCol = "red"
            buttonOp = "0.8"
        }

        return { buttonState, buttonCol, buttonOp }
    }


    const updateDataBackend = (site, update) => {
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': site, ...update }] }))
    }

   
    const export_png = (id, name) => {
        var base64String = 'not loaded yet'
        base64String = captureElementAsBase64(name).then(function (base64String) {
            dispatch(exportPNG({ 'id': id, 'name': name, 'img_string': base64String }))
            return base64String
        });
    }

    const downloadDocuments = (path_new, path_system) => {
        dispatch(download({ 'path_new': path_new, 'path_system': path_system }))
    }


    const createFinalReport = (id) => {
        dispatch(exportReport({ 'id': id }))
    }

    const getSubmitButton = (load) => {
        if (load !== null) {
            var submit_button_style = { text: 'Submit', shape: 'pill', type: 'primary-red', handler: (dialog) => dialog.close() }
        }
        else {
            var submit_button_style = { text: 'Submit', shape: 'pill', type: 'gray' }
        }
        return submit_button_style
    }

    // get button for wind
    var { buttonState, buttonCol, buttonOp } = getInputButton(mine_layout.res_color, (wind.wind_series_selector != null && grid.pv_series_selector != null))
    var buttonWind = buttonState; var colWind = buttonCol; var opWind = buttonOp;
    // get button for drilling
    var { buttonState, buttonCol, buttonOp } = getInputButton(mine_layout.drilling_color, (mine_layout.drilling_load != null))
    var buttonDrilling = buttonState; var colDrilling = buttonCol; var opDrilling = buttonOp;
    // get button for crushing
    var { buttonState, buttonCol, buttonOp } = getInputButton(mine_layout.crushing_color, (mine_layout.crushing_load != null))
    var buttonCrushing = buttonState; var colCrushing = buttonCol; var opCrushing = buttonOp;
    // get button for transport
    var { buttonState, buttonCol, buttonOp } = getInputButton(mine_layout.transport_color, (mine_layout.transport_load != null))
    var buttonTransport = buttonState; var colTransport = buttonCol; var opTransport = buttonOp;
    // get button for dewatering
    var { buttonState, buttonCol, buttonOp } = getInputButton(mine_layout.dewatering_color, (mine_layout.dewatering_load != null))
    var buttonDewatering = buttonState; var colDewatering = buttonCol; var opDewatering = buttonOp;
    // get button for hoisting
    var { buttonState, buttonCol, buttonOp } = getInputButton(mine_layout.hoisting_color, (mine_layout.hoisting_load != null))
    var buttonHoisting = buttonState; var colHoisting = buttonCol; var opHoisting = buttonOp;
    // get button for Ventilation
    var { buttonState, buttonCol, buttonOp } = getInputButton(mine_layout.ventilation_color, (mine_layout.ventilation_load != null))
    var buttonVentilation = buttonState; var colVentilation = buttonCol; var opVentilation = buttonOp;
    // get button for Comminution
    var { buttonState, buttonCol, buttonOp } = getInputButton(mine_layout.comminution_color, (mine_layout.comminution_load != null))
    var buttonComminution = buttonState; var colComminution = buttonCol; var opComminution = buttonOp;

   
    var bep = BO.break_even
    bep = Math.round(bep)
    if (bep > BO.lifetime) {
        bep = NaN
    }

    var lcoe_res = BO.lcoe
    if (lcoe_res == null) {
        lcoe_res = NaN
    }
    else {
        lcoe_res = Math.round(lcoe_res[0])
    }

    //function for cumulative sum
    const cumulativeSum = (x) => {
        let arr = Array(x.length).fill(0)
        var i = 0
        var total = 0
        for (i = 0; i < x.length; i++) {
            if (i == 0) {
                arr[i] = x[i]
            }
            else {
                arr[i] = arr[i - 1] + x[i]
            }
            total = arr[i]
        }
        
        return total
    }

    var costs_detail = BO.costs_res_detail
    if (costs_detail == null) {
        costs_detail = Array(6).fill(NaN)
    }

    const pv_opex_all_cum = (cumulativeSum(costs_detail[0]) + cumulativeSum(costs_detail[2]) + cumulativeSum(costs_detail[4]) + cumulativeSum(costs_detail[5]))/1000000
    const pv_capex_all_cum = (cumulativeSum(costs_detail[1]) + cumulativeSum(costs_detail[3]))/1000000

    //layout option
    var pop_up_style = { top: "10%", left: "40%", margin: "0 0 0 0px", width: "110%", height: "100%", overflow: "scroll" }

    //define buttons on pop-up windows
    var submit_button_crushing = getSubmitButton(mine_layout.crushing_load)
    var submit_button_hoisting = getSubmitButton(mine_layout.hoisting_load)
    var submit_button_drilling = getSubmitButton(mine_layout.drilling_load)
    var submit_button_dewatering = getSubmitButton(mine_layout.dewatering_load)
    var submit_button_comminution = getSubmitButton(mine_layout.comminution_load)
    var submit_button_transport = getSubmitButton(mine_layout.transport_load)
    var submit_button_ventilation = getSubmitButton(mine_layout.ventilation_load)

    return (
        < div className="fullBox" >
            <div className="contentBox" >   {/* create a text content box as specified in src/App.css */}
                <div className='content_top'>  {/* create input box on top  */}

                    {/*<ABB.Button shape="pill" style={{ 'margin-top': '20px', 'margin-left': '2%', 'background-color': ex.download_col, 'color': 'white' }}*/}
                    {/*    text="Download Results" onClick={() => downloadDocuments(id + '.json', 'data/' + id + '.json')} />*/}
                    <div className='fright-shift' id='export_overview_figure'>

                        <div class="button-container" >
                            {/* <img src={example} width="90%" height="10%"  /> */}
                            <img src={example} style={{ width: '100%' }} />
                            <div class="dialog-container-res">
                                {/* dialog box for res*/}
                                <ABB.WithDialog>
                                    {/* <ABB.Button style={{  background: colWind, opacity: opWind, padding: "40% 40%" }} /> */}
                                    <ABB.Button style={{ border: '5px solid', borderColor: colWind, backgroundColor: 'transparent', borderRadius: '15px', opacity: opWind, width: '300%', height: '95px' }} />
                                    <ABB.Dialog showCloseButton={true} closeOnEscape={false} closeOnLostFocus={false} hideBackground={false}
                                        standardButtonsOnBottom={[{ text: 'Submit', shape: 'pill', type: 'primary-red', handler: (dialog) => dialog.close() },]}
                                        /* title="Input Site Data" */ style={{ top: "10%", left: "40%", margin: "0 0 0 0px", width: "110%", height: "100%", overflow: "scroll" }}
                                        onClose={() => export_png(id,'export_res')}>
                                        <main> <div> <Res /></div> </main>
                                    </ABB.Dialog>
                                </ABB.WithDialog>
                            </div>
                            <div class="dialog-container-drilling">
                                {/* dialog box for load*/}
                                <ABB.WithDialog>
                                    <ABB.Button style={{ border: '5px solid', borderColor: colDrilling, backgroundColor: 'transparent', borderRadius: '15px', opacity: opDrilling, width: '250%', height: '80px' }} />
                                    <ABB.Dialog showCloseButton={true} closeOnEscape={true} closeOnLostFocus={true} hideBackground={false}
                                        standardButtonsOnBottom={[submit_button_drilling,]}
                                        onClose={() => export_png(id, 'export_drilling')}
                                        /* title="Input Site Data" */ style={pop_up_style} >
                                        <main> <div> <Drilling /></div> </main>
                                    </ABB.Dialog>
                                </ABB.WithDialog>
                            </div>
                            <div class="dialog-container-crushing">
                                {/* dialog box for load*/}
                                <ABB.WithDialog>
                                    <ABB.Button style={{ border: '5px solid', borderColor: colCrushing, backgroundColor: 'transparent', borderRadius: '15px', opacity: opCrushing, width: '250%', height: '80px' }} />
                                    <ABB.Dialog showCloseButton={true} closeOnEscape={false} closeOnLostFocus={false} hideBackground={false}
                                        standardButtonsOnBottom={[submit_button_crushing,]}
                                        onClose={() => export_png(id, 'export_crushing')}
                                        /* title="Input Site Data" */ style={pop_up_style} >
                                        <main> <div> <Crushing /></div> </main>
                                    </ABB.Dialog>
                                </ABB.WithDialog>
                            </div>
                            <div class="dialog-container-transport">
                                {/* dialog box for load*/}
                                <ABB.WithDialog>
                                    <ABB.Button style={{ border: '5px solid', borderColor: colTransport, backgroundColor: 'transparent', borderRadius: '15px', opacity: opTransport, width: '250%', height: '80px' }} />
                                    <ABB.Dialog showCloseButton={true} closeOnEscape={false} closeOnLostFocus={false} hideBackground={false}
                                        standardButtonsOnBottom={[submit_button_transport,]}
                                        onClose={() => export_png(id, 'export_transport')}
                                        /* title="Input Site Data" */ style={pop_up_style} >
                                        <main> <div> <Transport /></div> </main>
                                    </ABB.Dialog>
                                </ABB.WithDialog>
                            </div>
                            
                            <div class="dialog-container-dewatering">
                                {/* dialog box for load*/}
                                <ABB.WithDialog>
                                    <ABB.Button style={{ border: '5px solid', borderColor: colDewatering, backgroundColor: 'transparent', borderRadius: '15px', opacity: opDewatering, width: '250%', height: '95px' }} />
                                    <ABB.Dialog showCloseButton={true} closeOnEscape={false} closeOnLostFocus={false} hideBackground={false}
                                        standardButtonsOnBottom={[submit_button_dewatering,]}
                                        onClose={() => export_png(id, 'export_dewatering')}
                                        /* title="Input Site Data" */ style={pop_up_style} >
                                        <main> <div> <Dewatering /></div> </main>
                                    </ABB.Dialog>
                                </ABB.WithDialog>
                            </div>
                            <div class="dialog-container-hoisting">
                                {/* dialog box for load*/}
                                <ABB.WithDialog>
                                    <ABB.Button style={{ border: '5px solid', borderColor: colHoisting, backgroundColor: 'transparent', borderRadius: '15px', opacity: opHoisting, width: '250%', height: '95px' }} />
                                    <ABB.Dialog showCloseButton={true} closeOnEscape={false} closeOnLostFocus={false} hideBackground={false}
                                        standardButtonsOnBottom={[submit_button_hoisting,]}
                                        onClose={() => export_png(id, 'export_hoisting')}
                                        /* title="Input Site Data" */ style={pop_up_style} >
                                        <main> <div> <Hoisting /></div> </main>
                                    </ABB.Dialog>
                                </ABB.WithDialog>
                            </div>
                            <div class="dialog-container-ventilation" >
                                {/* dialog box for load*/}
                                <ABB.WithDialog>
                                    <ABB.Button style={{ border: '5px solid', borderColor: colVentilation, backgroundColor: 'transparent', borderRadius: '15px', opacity: opVentilation, width: '250%', height: '90px' }} />
                                    <ABB.Dialog showCloseButton={true} closeOnEscape={false} closeOnLostFocus={false} hideBackground={false}
                                        standardButtonsOnBottom={[submit_button_ventilation,]}
                                        onClose={() => export_png(id, 'export_ventilation')}
                                        /* title="Input Site Data" */ style={pop_up_style} >
                                        <main> <div> <Ventilation /></div> </main>
                                    </ABB.Dialog>
                                </ABB.WithDialog>
                            </div>
                            <div class="dialog-container-comminution" >
                                {/* dialog box for load*/}
                                <ABB.WithDialog>
                                    <ABB.Button style={{ border: '5px solid', borderColor: colComminution, backgroundColor: 'transparent', borderRadius: '15px', opacity: opComminution, width: '250%', height: '95px' }} />
                                    <ABB.Dialog showCloseButton={true} closeOnEscape={false} closeOnLostFocus={false} hideBackground={false}
                                        standardButtonsOnBottom={[submit_button_comminution,]}
                                        onClose={() => export_png(id, 'export_comminution')}
                                        /* title="Input Site Data" */ style={pop_up_style} >
                                        <main> <div> <Comminution /></div> </main>
                                    </ABB.Dialog>
                                </ABB.WithDialog>
                            </div>

                        </div>
                    </div>
                    <div className='fleft'>

                        {/* This is the place for results buttons */}
                        <div className='contentBox'>
                            {/* <h1> Data Input</h1> */}
                            {/* <ABB.Heading level={2} text='Data Input' style={{ 'marginLeft': '0px' }} /> */}
                            <main> <div>
                                <User_steps />
                                
                            </div>
                            </main>
                        </div>

                    </div>
                </div>

                {simStates.simulation1_state == "sim" &&
                    <div className='content_bottom'>
                        <div className='fleft2'>
                            < Sim1_part2 />
                            < Sim1_part1 />
                        </div>
                        <div className='fright2'>
                            {(simStates.simulation1_state == 'sim' || simStates.simulation2_state == 'sim' || simStates.simulation3_state == 'sim')  && 
                                <div>                                      

                                    <div className='landing_bottom' style={{ 'background': 'white' }} id='export_final_figures'>

                                        <RC />
                                        <div class="button-container2">
                                            <img src={zoom} width="27px" height="25px" />
                                            <div class="dialog-container-zoom">
                                                <ABB.WithDialog>
                                                    <ABB.Button style={{ border: '0px', borderColor: colDrilling, backgroundColor: 'transparent', opacity: opDrilling, width: '100%', height: '200%' }} />
                                                    <ABB.Dialog showCloseButton={true} closeOnEscape={true} closeOnLostFocus={true} hideBackground={false}
                                                        style={{ width: "1600px", height: "700px", margin: "0px 0px 0px 800px", backgroundColor: '#EFF3F3' }} >
                                                        <RC />
                                                        <div class="dialog-container-zoom-distance">
                                                            {topGraphStates.graphA && <GraphAs />}
                                                            {topGraphStates.graphB && <GraphBs />}
                                                            {topGraphStates.graphC && <GraphCs />}
                                                            {topGraphStates.graphD && <GraphDs />}
                                                        </div>
                                                    </ABB.Dialog>
                                                </ABB.WithDialog>
                                            </div>
                                        </div>
                                        {topGraphStates.graphA && <GraphAs />}
                                        {topGraphStates.graphB && <GraphBs />}
                                        {topGraphStates.graphC && <GraphCs />}
                                        {topGraphStates.graphD && <GraphDs />}
                                    </div>
                                    <div className='landing_bottom' style={{ 'background': 'white' }} id='export_result_numbers'>
                                        <div className="content_res"> PV Capacity: {Math.round(BO.pv_real_install)} MW  </div>
                                        <div className="content_res"> Wind Capacity: {Math.round(BO.wind_real_install)} MW</div>
                                        <div className="content_res"> Break-Even Point: {bep} Year(s) </div>
                                        <div className="content_res">
                                            <div class="myDIV">LCOE of RES: {lcoe_res} EUR/MWh (without financing)</div>
                                            <div class="hide">Levelized Cost of Energy of Scenario with Renewable Energy Sources.</div>
                                        </div>
                                        <div className="content_res"> OPEX (including Grid & Diesel): {Math.round(pv_opex_all_cum)} MEUR </div>
                                        <div className="content_res"> CAPEX: {Math.round(pv_capex_all_cum)} MEUR </div>
                                       
                                    </div>

                                    
                                    <div className='landing_very_bottom' >
                                        <div className="content_res_long">
                                            <div class="myDIV"> Export of results (DEMO), see limitations HERE</div>
                                            <div class="hide"> (1) Downloaded PDF document currently needs to be opened with LibreOffice after downloading (PDF format crash).
                                                               (2) Libre Office is not stable and does not render all images!
                                                               (3) Only "Demand & Supply" analysis included so far. "Grid Analysis" to be included soon.  </div>
                                        </div>
                                        <ABB.Button shape="pill" style={{ 'margin-top': '20px', 'margin-left': '32%', 'color': ex.report_col , 'background-color': 'red' }}
                                            text={ex.button_name ?? "Create Report"} onClick={() => [export_png(id, 'export_plot_LoadDistribution'),
                                                                                 createFinalReport(id)]} />
                                                                       

                                    <ABB.Button shape="pill" style={{ 'margin-top': '20px', 'margin-left': '2%', 'background-color': ex.download_col, 'color': 'white' }} 
                                            text="Download Results"
                                            onClick={() => [downloadDocuments(id + '.json', 'data/' + id + '.json'),
                                                downloadDocuments('FAME_Report_' + id + '.pdf', 'assessments/' + id + '/FAME_Report.pdf')]} />

                                </div> 
                                </div>
                               
                            }
                        </div>
                    </div>
                }
            </div>
        </div>

    )
}

export default Backpage