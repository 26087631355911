import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { updateTopNavi, fetchSite, fetchVehicleToGridMode } from '../../slices/siteSlice'

import Backpage from './../ds_frontend/ds_mainpage'

// from Boda
import GridDesignDemoPage from './../gdd_frontend/mainGridDesignDemo'

import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css'

const Site = (props) => {

    const dispatch = useDispatch()
    const siteStatus = useSelector(state => state.site.status)
    const [loadingStatus, setLoadingStatus] = useState('uninitialized')
    const topNaviStates = useSelector(state => state.site.topNaviStates)

    //const [topNaviStates, setTopNaviStates] = useState({
    //    'overview':false,
    //    'mine':false,
    //    'res':false,
    //    'results':false,
    //    'timeseries': false,
    //    'loads': false,
    //    'generation': false,
    //    'storages': false,
    //    'grid': false,
    //    'defaults': false,
    //    'assessment': false,
    //    'play': false,
    //    'drilling': false,
    //    'mainDisplay': true
    //})



    useEffect(() => {
        dispatch(fetchSite(props.match.url))
    }, [props.match.url, dispatch])

    useEffect(() => {
        dispatch(fetchVehicleToGridMode())
    }, [dispatch])

    useEffect(() => {
        const status = [
            siteStatus
        ]
        let newstatus = 'loaded'
        status.forEach(s => {
            if (s !== 'loaded' && newstatus !== 'error') {
                newstatus = s
            }
        })
        setLoadingStatus(newstatus)
    }, [siteStatus])


    const activateTopNaviItem = item => {
        //setTopNaviStates(s => (Object.entries(s).reduce((acc, cur) => ({...acc, [cur[0]]: cur[0]===item}), {})))
        dispatch(updateTopNavi(item))
    }

    if (loadingStatus === 'error') {
        return (
            <ABB.AppContent>
                <ABB.AppMainContent>
                    <div className="fullHeight content">
                        <div className="contentBox" >
                            <p>There was an error connecting to the server. Please try again later.</p>
                        </div>
                    </div>
                </ABB.AppMainContent>
            </ABB.AppContent>
        )
    } else if (loadingStatus === 'loaded') {
        return (
            <React.Fragment>
                <ABB.AppHeader>
                    <ABB.AppTopNavi>
                        <ABB.AppTopNaviItem text="D&S"
                            active={topNaviStates.mainDisplay2} onClick={() => activateTopNaviItem('mainDisplay2')} />

                        <ABB.AppTopNaviItem text="Grid Design Demo"
                            active={topNaviStates.mainGridDesignDemo} onClick={() => activateTopNaviItem('mainGridDesignDemo')} />

                        {/* <ABB.AppTopNaviItem text="SSA Page"  //k_c2
                               active={topNaviStates.mainDisplaySSA} onClick={() => activateTopNaviItem('mainDisplaySSA')} /> */}

                    </ABB.AppTopNavi>
                </ABB.AppHeader>
                <ABB.AppContent>
                    <ABB.AppMainContent>
                        {topNaviStates.mainDisplay2 && <Backpage />}
                        {topNaviStates.mainGridDesignDemo && <GridDesignDemoPage />}
                        {/* { topNaviStates.mainDisplaySSA&& <SSApage/> }k_c3 */}
                    </ABB.AppMainContent>
                </ABB.AppContent>
            </React.Fragment>
        )
    } else {
        return (
            <ABB.AppContent>
                <ABB.AppMainContent>
                    <div className="fullHeight content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <ABB.Spinner color="blue" sizeClass="large" />
                    </div>
                </ABB.AppMainContent>
            </ABB.AppContent>
        )
    }
}

export default withRouter(Site)