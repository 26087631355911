import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { api_url } from '../config.js'

const initialState = {
    timeseries: [],
    status: 'uninitialized',
    uploadstatus: 'idle'
}

const fetchTimeseriesFiles = createAsyncThunk(
    'fetchTimeseriesFiles',
    async id => {
        const response = await axios.get(api_url + '/timeseriesFiles' + id)
        return response.data
    }
)

const upload = createAsyncThunk(
    'uploadTimeseries',
    async payload => {
        const response = await axios.post(api_url + '/upload', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
        return response.data
    }
)

const upload_filename = createAsyncThunk(
    'upload_filename',
    async updates => {
        const response = await axios.post(api_url + '/upload_filename', updates)
        return response.data
    }
)

const timeSeriesDelete = createAsyncThunk(
    'timeSeriesDelete',
    async id => {
        const response = await axios.get(api_url + '/timeseriesFilesDelete' + id)
        return response.data
    }
)

const deleteLocalFiles = createAsyncThunk(
    'deleteLocalFiles',
    async id => {
        const response = await axios.get(api_url + '/deleteFiles' + id)
        return response.data
    }
)


const timeseriesFilesSlice = createSlice({
    name: 'timeseriesFiles',
    initialState,
    reducers: {
        setUploadStatus(state, action) {
            state.uploadstatus = action.payload.uploadstatus
        }
    },
    extraReducers: {
        [upload.pending]: (state, action) => { state.uploadstatus = 'loading' },
        [upload.fulfilled]: (state, action) => {
            state.uploadstatus = 'idle'
            state.timeseries = action.payload.timeseries
        },
        [upload.rejected]: (state, action) => { state.uploadstatus = 'error' },
        [fetchTimeseriesFiles.pending]: (state, action) => { state.status = 'loading' },
        [fetchTimeseriesFiles.fulfilled]: (state, action) => {
            state.timeseries = action.payload.timeseries
            state.status = 'loaded'
        },
        [fetchTimeseriesFiles.rejected]: (state, action) => { state.status = 'error' },
        [timeSeriesDelete.fulfilled]: (state, action) => {
            state.status = 'loaded'
            state.timeseries = action.payload.timeseries
        },
        [deleteLocalFiles.fulfilled]: (state, action) => {
            state.uploadstatus = 'idle'
            state.timeseries = action.payload.timeseries
            state.status = 'loaded'
        },

        [upload_filename.pending]: (state, action) => { state.uploadstatus = 'loading' },
        [upload_filename.fulfilled]: (state, action) => {
            state.uploadstatus = 'loaded'
            state.timeseries = action.payload.timeseries
        },
        [upload_filename.rejected]: (state, action) => { state.uploadstatus = 'error' },
    }
})

const { setUploadStatus } = timeseriesFilesSlice.actions
export { fetchTimeseriesFiles, upload, setUploadStatus, upload_filename, timeSeriesDelete, deleteLocalFiles }
export default timeseriesFilesSlice.reducer