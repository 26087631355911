import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { updateTopNavi, updatesSite, downloadWeatherData } from '../../slices/siteSlice'
import TimeseriesDropzoneSelector from './../resources/timeseriesDropzoneSelector'
import { upload_filename } from '../../slices/timeseriesFilesSlice'
import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css'
import Plot from 'react-plotly.js'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.min.css";
import question from './../resources/Question.PNG'


const Res = () => {

    const dispatch = useDispatch()
    const ident = useSelector(state => state.site.ident)
    const id = ident.id
    const timeseriesFiles = useSelector(state => state.timeseriesFiles)
    const mine_layout = useSelector(state => state.site.mine_layout)
    const wind = useSelector(state => state.site.wind)
    const grid = useSelector(state => state.site.grid)
    const res = useSelector(state => state.site.res)
    const uploadstatus = useSelector(state => state.timeseriesFiles.uploadstatus)

    //to enable timeseries selection in the graph
    const fileList = timeseriesFiles.status == 'loaded' ? timeseriesFiles.timeseries.map((t, i) => ({ lab: t.filename, val: i })) : [{ lab: 'empty', val: 0 }]

    const updateDataBackend = (site, update) => {
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': site, ...update }] }))
    }

    const activateTopNaviItem = item => {
        dispatch(updateTopNavi(item))
    }

    const getDataX = (v, wert) => {

        const filenames = timeseriesFiles.status == 'loaded' ? timeseriesFiles.timeseries.map((t) => (t.filename)) : [""]
        const filePosition = filenames.indexOf(wert)

        if (v.timeseries.length > 0 && filePosition >= 0) {
            var x = timeseriesFiles.timeseries[filePosition].values.length
        }
        else {
            var x = []
        }
        return x
    }

    const getDataY = (v, wert) => {

        const filenames = timeseriesFiles.status == 'loaded' ? timeseriesFiles.timeseries.map((t) => (t.filename)) : [""]
        const filePosition = filenames.indexOf(wert)

        if (v.timeseries.length > 0 && filePosition >= 0) {
            var y = Object.values(timeseriesFiles.timeseries[filePosition].values)
        }
        else {
            var y = []
        }
        return y
    }

    const getTitle = (v, wert) => {
        const filenames = timeseriesFiles.status == 'loaded' ? timeseriesFiles.timeseries.map((t) => (t.filename)) : [""]
        const filePosition = filenames.indexOf(wert)

        if (v.timeseries.length > 0 && filePosition >= 0) {
            var t = v.timeseries[filePosition].filename
        }
        else {
            var t = 'no data yet'
        }
        return t
    }

    const adaptTimeInterval = (v, mode) => {
        //adapt format
        var date = new Date(v);
        var year = date.getFullYear();
        var dateVal = date.getDate();
        var formatDate = dateVal + '/' + (date.getMonth() + 1) + '/' + year;

        if (mode == 'start') {//get end date - fixed to 1 year after start date
            formatDateStart = formatDate
            var numberOfMlSeconds = date.getTime();
            var addMlSeconds = 1000 * (364 * 24 * 60 * 60); //+364 days
            var stopDate = new Date(numberOfMlSeconds + addMlSeconds);
            var formatDateEnd = stopDate.getDate() + '/' + (stopDate.getMonth() + 1) + '/' + stopDate.getFullYear();
        }
        if (mode == 'stop') {//get start date - fixed to 1 year before start date
            var stopDate = date
            formatDateEnd = formatDate
            var numberOfMlSeconds = date.getTime();
            var addMlSeconds = 1000 * (364 * 24 * 60 * 60); //+364 days
            var startDate = new Date(numberOfMlSeconds - addMlSeconds);
            var formatDateStart = startDate.getDate() + '/' + (startDate.getMonth() + 1) + '/' + startDate.getFullYear();
        }
        //check that no date is selected in the future
        if (stopDate > Date.now()) {
            alert('No date allowed in the future!')
            formatDateStart = '01/01/2022'
            formatDateEnd = '31/12/2022'
        }
        updateDataBackend('res', { key: 'res_start_interval', value: formatDateStart })
        updateDataBackend('res', { key: 'res_stop_interval', value: formatDateEnd })
    }

    const getLongitude = (v) => {
        var long = parseFloat(v)
        if (long < -180 || long > 180) {
            alert('Invalid value for longitude! -180....180')
            long = 0
        }
        updateDataBackend('res', { key: 'res_api_longitude', value: long })
    }
    const getLatitude = (v) => {
        var lat = parseFloat(v)
        if (lat < -90 || lat > 90) {
            alert('Invalid value for longitude! -90....90')
            lat = 0
        }
        updateDataBackend('res', { key: 'res_api_latitude', value: lat })
    }

    const startWeatherAPI = (res, id) => {
        var startDate = res.res_start_interval
        var stopDate = res.res_stop_interval

        var lat = res.res_api_latitude
        var lon = res.res_api_longitude
        var turbine_type = res.res_windturbine_model
        var pv_type = res.res_pv_model
        var resolution = mine_layout.hours
        dispatch(downloadWeatherData({ 'startDate': startDate, 'stopDate': stopDate, 'latitude': lat, 'longitude': lon, 'id': id, 'turbine_type': turbine_type, 'pv_type': pv_type, 'resolution' : resolution }))
    }


    //define file list for upload 
    var file_list = new Array(2)
    if (res.res_api_state >= 2) {
        var file_list = res.res_api_filenames
    }

    //define color for API button
    var api_col = 'lightgray'

    if (res.res_api_state == 1) {
        api_col = 'crimson'
    }
    if (res.res_api_state >= 2) {
        api_col = 'limegreen'
        if (res.res_api_state == 2) {
            dispatch(upload_filename({ 'files': file_list, 'id': id }))
            updateDataBackend('res', { key: 'res_api_state', value: 3 })
        }
    }

    const explanation_pv = "Global Horizontal Irradiance (GHI) is converted to power as power=A*eta*GHI*PR, with A being the area of the solar panel, " +
        "eta=0.19 being the efficiency of the panel and PR = 0.75 being the production ratio. \nThe dropdown options are created by adapting the panel" +
        "size to the rated power of the wind turbine. A standard panel of 400W for an area of 1mx2.1x, 144 HC cells is used for scaling.\n" +
        "Reference: https://www.cleanenergyreviews.info/blog/most-efficient-solar-panels"

    const explanation_wind = "Exemplary wind turbine models are loaded from [1]. Wind speed is first converted from downloaded speed at 50m to hub height. " +
        "The respective power curve of the turbine model is then used to convert wind speed to power.\n" +
        "Reference: https://openenergy-platform.org/dataedit/view/supply/wind_turbine_library"

    const explanation_api = "The weather API downloads data from 'https://power.larc.nasa.gov/data-access-viewer/."

    const api_error = res.res_api_message
    if (api_error.length > 0) {
        window.alert(api_error)
        updateDataBackend('res', { key: 'res_api_message', value: '' })
    }

    return (
        <div className="fullHeight content">

            <div className="rowC" id = 'export_res'>
                <div className="contentBox" >
                    <ABB.Heading level={3} text='Please upload the PV and Wind potential' />

                    <div style={{ 'width': '100%', 'float': 'left' }}>
                        <div style={{ 'float': 'left', 'width': '58%', 'height': '100%' }} >
                            <ABB.Dropdown label={"Do you want to download wind and solar data via API?"} style={{ 'width': '100%' }} required={true} searchable={true}
                                value={{ label: res.res_use_api }}
                                onChange={v => updateDataBackend('res', { key: 'res_use_api', value: v[0].value })}
                            >
                                <ABB.DropdownOption label={'yes'} value={'yes'} />
                                <ABB.DropdownOption label={'no'} value={'no'} />
                            </ABB.Dropdown>
                        </div>
                        <div style={{ 'float': 'left', 'width': '4%', 'height': '100%', 'margin-top': '3%', 'margin-left': '1%' }}>
                            <img src={question} style={{ width: '100%' }} onClick={() => window.alert(explanation_api)} />
                        </div>
                    </div>


                    {res.res_use_api == 'yes' &&
                        <p> Please select a time interval (365 days) and a location:
                            <div style={{ "width": "85%" }}>
                                <div style={{ "width": "90%", "margin-top": "2%", "color": "#696969" }}>
                                    <div style={{ "float": "left", "width": "39%" }}>
                                        Start Date:
                                        <DatePicker
                                            name='test'
                                            value={res.res_start_interval}
                                            onChange={(v) => adaptTimeInterval(v, 'start')}
                                        />
                                    </div>
                                    <div style={{ "float": "left", "width": "40%", "color": "#696969", "fontSize": "14px" }}>
                                        End Date:
                                        <DatePicker
                                            name='test'
                                            value={res.res_stop_interval}
                                            onChange={(v) => adaptTimeInterval(v, 'stop')}
                                        />
                                    </div>
                                </div>
                                <div style={{ "width": "90%" }}>
                                    <div style={{ "float": "left", "width": "80%" }}>
                                        <ABB.Input required={true} dataType='number'
                                            style={{ "float": "left", "width": "45%", "margin-top": '10px' }}
                                            label={'Latitude'}
                                            value={res.res_api_latitude}
                                            onValueChange={v => getLatitude(v)}
                                        />
                                        <ABB.Input required={true} dataType='number'
                                            style={{ "float": "left", "width": "45%", "margin-left": "3%", "margin-top": '10px' }}
                                            label={'Longitude'}
                                            value={res.res_api_longitude}
                                            onValueChange={v => getLongitude(v)}
                                        />
                                    </div>
                                </div>
                                <div style={{ "float": "right", "width": "10%" }}>
                                    <ABB.Button style={{ "border-radius": "10px", "border-color": api_col, "border-width": "3px" }}
                                        text="Start API" onClick={() => startWeatherAPI(res, id)} />
                                </div>
                            </div>
                            <div style={{ "float": "left", "width": "85%", "margin-top": "10px" }}>
                                <div style={{ "float": "left", "width": "85%", "margin-top": "10px" }}>
                                    <div style={{ 'float': 'left', 'width': '80%', 'height': '100%' }} >
                                        <ABB.Dropdown label={"Select the wind turbine model used for scaling:"} style={{ 'width': "100%" }} required={true} searchable={true}
                                            value={{ label: res.res_windturbine_model }}
                                            onChange={v => updateDataBackend('res', { key: 'res_windturbine_model', value: v[0].value })}
                                        >
                                            <ABB.DropdownOption label={'E-101/3050 E2, roughness=0.25'} value={'E-101/3050 E2, roughness=0.25'} />
                                            <ABB.DropdownOption label={'E-53/800, roughness=0.25'} value={'E-53/800, roughness=0.25'} />
                                            <ABB.DropdownOption label={'V90-2.0 MW, roughness=0.25'} value={'V90-2.0 MW, roughness=0.25'} />
                                            <ABB.DropdownOption label={'SWT-2.3-113, roughness=0.25'} value={'SWT-2.3-113, roughness=0.25'} />
                                        </ABB.Dropdown>
                                    </div>
                                    <div style={{ 'float': 'left', 'width': '5%', 'height': '100%', 'margin-top': '4.5%', 'margin-left': '2%' }}>
                                        <img src={question} style={{ width: '100%' }} onClick={() => window.alert(explanation_wind)} />
                                    </div>
                                    <div style={{ 'float': 'left', 'width': '80%', 'height': '100%' }} >
                                        <ABB.Dropdown label={"Select the solar panel model used for scaling:"} style={{ 'width': "100%" }} required={true} searchable={true}
                                            value={{ label: res.res_pv_model }}
                                            onChange={v => updateDataBackend('res', { key: 'res_pv_model', value: v[0].value })}
                                        >
                                            <ABB.DropdownOption label={'P = 3.0MW (A=16053, efficiency = 0.19), PR = 0.75'} value={'P = 3.0MW (A=16053, efficiency = 0.19), PR = 0.75'} />
                                            <ABB.DropdownOption label={'P = 0.8MW (A= 4211, efficiency = 0.19), PR = 0.75'} value={'P = 0.8MW (A= 4211, efficiency = 0.19), PR = 0.75'} />
                                            <ABB.DropdownOption label={'P = 2.0MW (A=10526, efficiency = 0.19), PR = 0.75'} value={'P = 2.0MW (A=10526, efficiency = 0.19), PR = 0.75'} />
                                            <ABB.DropdownOption label={'P = 2.3MW (A=12105, efficiency = 0.19), PR = 0.75'} value={'P = 2.3MW (A=12105, efficiency = 0.19), PR = 0.75'} />
                                        </ABB.Dropdown>
                                    </div>
                                    <div style={{ 'float': 'left', 'width': '5%', 'height': '100%', 'margin-top': '4.5%', 'margin-left': '2%' }}>
                                        <img src={question} style={{ width: '100%' }} onClick={() => window.alert(explanation_pv)} />
                                    </div>
                                </div>
                            </div>
                        </p>}

                    <div style={{ "float": "left", "width": "100%" }}>
                        <TimeseriesDropzoneSelector onlyDropzone={true} key={mine_layout.hours} value={mine_layout.hours} />
                    </div>


                    {/*........................*/}
                    <div style={{ "float": "left", "width": "100%" }}>
                        <ABB.Heading level={3} text='Please assign PV and Wind data' />
                        <ABB.Dropdown style={{ width: "100%" }} label="Please select a PV  timeseries for subsequent simulations." required={false} searchable={true}
                            value={(grid.pv_series_selector !== null && timeseriesFiles.status == 'loaded') ? [{ label: grid.pv_series_selector }] : ['no data selected']}
                            onChange={(v) => updateDataBackend('grid', { key: 'pv_series_selector', value: v[0].label })}
                        >
                            {fileList.map((s) => (
                                <ABB.DropdownOption key={s.val} label={s.lab} value={s.val} />
                            ))}
                        </ABB.Dropdown>

                        <ABB.Dropdown style={{ width: "100%" }} label="Please select a WIND timeseries for subsequent simulations." required={false} searchable={true}
                            value={(wind.wind_series_selector !== null && timeseriesFiles.status == 'loaded') ? [{ label: wind.wind_series_selector }] : ['no data selected']}
                            onChange={(v) => updateDataBackend('wind', { key: 'wind_series_selector', value: v[0].label })}
                        >
                            {fileList.map((s) => (
                                <ABB.DropdownOption key={s.val} label={s.lab} value={s.val} />
                            ))}
                        </ABB.Dropdown>
                    </div>
                </div>

                <div className="contentBox" >
                    <   ABB.Heading level={3} text='Visualisation of timeseries' />

                    <div style={{ 'maxWidth': '800px' }}>
                    </div>
                    <ABB.Dropdown style={{ width: "100%" }} label="Please select the PV timeseries you want to visualize" required={false} searchable={true}
                        value={(grid.pv_visualisation_selector !== null && timeseriesFiles.status == 'loaded') ? [{ label: grid.pv_visualisation_selector }] : ['no data selected']}
                        onChange={(v) => updateDataBackend('grid', { key: 'pv_visualisation_selector', value: v[0].label })}
                    >
                        {fileList.map((s) => (
                            <ABB.DropdownOption key={s.val} label={s.lab} value={s.val} />
                        ))}
                    </ABB.Dropdown>
                    <ABB.Dropdown style={{ width: "100%" }} label="Please select the WIND timeseries you want to visualize" required={false} searchable={true}
                        value={(wind.wind_visualisation_selector !== null && timeseriesFiles.status == 'loaded') ? [{ label: wind.wind_visualisation_selector }] : ['no data selected']}
                        onChange={(v) => updateDataBackend('wind', { key: 'wind_visualisation_selector', value: v[0].label })}
                    >
                        {fileList.map((s) => (
                            <ABB.DropdownOption key={s.val} label={s.lab} value={s.val} />
                        ))}
                    </ABB.Dropdown>

                    {
                        <Plot
                            data={[
                                {
                                    x: getDataX(timeseriesFiles, grid.pv_visualisation_selector),
                                    y: getDataY(timeseriesFiles, grid.pv_visualisation_selector),
                                    type: 'scatter',
                                    fill: 'tozeroy',
                                    name: 'PV',
                                    marker: { color: '#fc6d2b' }

                                }
                            ]}
                            layout={{ xaxis: { title: mine_layout.hours }, yaxis: { title: 'Energy [MWh], scaled to 1MW capacity' }, title: getTitle(timeseriesFiles, grid.pv_visualisation_selector), width: 500, height: 600 }}
                        />
                    }

                    {
                        <Plot
                            data={[
                                {
                                    x: getDataX(timeseriesFiles, wind.wind_visualisation_selector),
                                    y: getDataY(timeseriesFiles, wind.wind_visualisation_selector),
                                    fill: 'tozeroy',
                                    type: 'scatter',
                                    name: 'Wind ',
                                    marker: { color: '#3366FF' }
                                },
                            ]}
                            layout={{ xaxis: { title: mine_layout.hours }, yaxis: { title: 'Energy [MWh], scaled to 1MW capacity' }, title: getTitle(timeseriesFiles, wind.wind_visualisation_selector), width: 500, height: 600 }}
                        />



                    }
                </div>

            </div>
            {/* insert 'back to frontpage' button */}
            {/* <div style={{ 'width': '500px', 'margin-top': '50px' }}  >
                <ABB.Button type="primary-red" shape="pill" text="Go to overview" onClick={() => activateTopNaviItem('mainDisplay')} />
            </div> */}
        </div>


    )
}

export default Res