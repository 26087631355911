import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updatesSite, ventilation } from '../../slices/siteSlice'
import { emptyStringToNull } from './../resources/utilities'
import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css'
import Plot from 'react-plotly.js'
import question from './../resources/Question.PNG'


const Ventilation = () => {
    const dispatch = useDispatch()
    const ident = useSelector(state => state.site.ident)
    const id = ident.id
    const mine_layout = useSelector(state => state.site.mine_layout)
    const mine_load_ventilation = mine_layout.ventilation_load_assumed
    const ventilation_volume_factor = mine_layout.ventilation_volume_factor

    // update data of site in backend
    const updateDataBackend = (site, update) => {
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': site, ...update }] }))
    }

    var xData = [NaN]
    var yData = [NaN]
    var title = "No Data (generate ventilation timeseries)"

    // get visualisation data
    if (mine_layout.ventilation_load !== null) {
        var xData = [...Array(mine_layout.ventilation_load.length).keys()]
        var yData = mine_layout.ventilation_load
        var title = 'Ventilation load series'
    }

    const callVentilation = (a, b, c, d, e) => {


        var depth = parseFloat(a)
        var std = parseFloat(b)
        var volume = parseFloat(c)
        var mass = parseFloat(d)
        var resolution = e

        dispatch(ventilation({
            'volume': volume, 'std': std, 'depth': depth, 'mass': mass, 'resolution': mine_layout.hours
        }))

    }

    const explanation_text = "The energy consumption model for mine ventilation is based on an estimation of the airflow requirement and the total pressure drop that need to overcome by the ventilation system. " +
        "The airflow requirement is estimated based on the total diesel capacity (kW), often a statutory requirement, i.e., 0.063 - 0.075 m3 / s per kW of rated diesel engine power [1]. " +
        "The total pressure drop is calculated by  using the Square Law of mine ventilation that gives the relationship between airflow and Atkinson resistance. If a simplified mine diagram is available, " +
        "the Atkinson resistance can be estimated accordingly [2].\n" +
        "The ventilation volume is calculated by multiplying the hoisted mass per day [t] with the assumed load produced underground [kW/t] and the diesel airflow requirement [m^3/s]. We assume an amount of \n" +
        "33 kWh/t for underground load [3] and a diesel airflow requirement of 0.05 m^3/s [2]\n" +
        "Sources:\n[1] Varaschin, J., and E.De Souza. Economics of diesel fleet replacement by electric mining equipment. 15th North American Mine Ventilation Symposium.Vol. 8. 2015.\n " +
        "[2] Tahmasebi, Shahab.Ventilation and cooling comparison between diesel and electric mining equipment. McGill University(Canada), 2019.\n" +
        "[3] Benchmarking the Energy Consumption of Canadian Underground Bulk Mines, Candadian Industry Program for Energy Conservation, 2005."

    // useEffect(() => {
    //     updateDataBackend('mine_layout', { key: 'ventilation_tons_hours', value: mine_layout.ventilation_tons / 24 })
    // }, [mine_layout.ventilation_tons])

    // useEffect(() => {
    //     updateDataBackend('mine_layout', { key: 'ventilation_tons', value: mine_layout.ventilation_tons_hours * 24 })
    // }, [mine_layout.ventilation_tons_hours])

    return (
        <div className="fullHeight content" id='export_ventilation'>
            <div className="fleft" >

                <div className="heading-question">
                    <div className="fleft-heading">
                        <ABB.Heading level={4} text='Enter the daily ventilation need of the mine' style={{ 'marginLeft': '16px' }} />
                    </div>
                    <div className="fright-heading">
                        <img src={question} style={{ width: '25%' }} onClick={() => window.alert(explanation_text)} />
                    </div>
                </div>
                <div className="heading-question">

                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Hoisted mass per day [t]'}
                        value={mine_layout.ventilation_tons}
                        onValueChange={v => [updateDataBackend('mine_layout', { key: 'ventilation_tons', value: emptyStringToNull(parseInt(v)) }),
                        updateDataBackend('mine_layout', { key: 'ventilation_volume', value: Math.round(emptyStringToNull(parseFloat(v)) * mine_load_ventilation / 24 * ventilation_volume_factor) })]}

                    />






                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Ventilation Need [m3/s]'}
                        value={mine_layout.ventilation_volume}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'ventilation_volume', value: emptyStringToNull(parseInt(v)) })}

                    />


                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Ventilation depth [m]'}
                        value={mine_layout.ventilation_depth}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'ventilation_depth', value: emptyStringToNull(parseInt(v)) })}

                    />

                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Deviation of average energy consumption [%]'}
                        value={mine_layout.ventilation_std}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'ventilation_std', value: emptyStringToNull(parseFloat(v)) })}

                    />




                    {mine_layout.ventilation_std != null && mine_layout.ventilation_depth != null && mine_layout.ventilation_volume !== null &&
                        <ABB.Button type="primary-red" shape="pill" text="Generate Ventilation Timeseries" style={{ 'margin-top': '32px', 'margin-left': '50px' }} onClick={() => callVentilation(mine_layout.ventilation_depth, mine_layout.ventilation_std, mine_layout.ventilation_volume, mine_layout.ventilation_tons)} />
                    }
                </div>
            </div>
            <div className="fright" >

                <Plot
                    data={[
                        {
                            x: xData,
                            y: yData,
                            type: 'scatter',
                            name: 'Optimized',
                            marker: { color: '#3366FF' }
                        },
                    ]}
                    layout={{ xaxis: { title: mine_layout.hours }, yaxis: { title: 'Energy [MWh]' }, title: title, width: '90%', height: '90%' }}
                />
            </div>
        </div>
    )
}

export default Ventilation