import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updatesSite, comminution } from '../../slices/siteSlice'
import { emptyStringToNull } from './../resources/utilities'
import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css'
import Plot from 'react-plotly.js'
import question from './../resources/Question.PNG'


const Comminution = () => {
    const dispatch = useDispatch()
    const ident = useSelector(state => state.site.ident)
    const id = ident.id
    const mine_layout = useSelector(state => state.site.mine_layout)

    // update data of site in backend
    const updateDataBackend = (site, update) => {
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': site, ...update }] }))
    }

    var xData = [NaN]
    var yData = [NaN]
    var title = "No Data (generate comminution timeseries)"

    // get visualisation data
    if (mine_layout.comminution_load !== null) {
        var xData = [...Array(mine_layout.comminution_load.length).keys()]
        var yData = mine_layout.comminution_load
        var title = 'Comminution load series'
    }

    const callComminution = (a, b, c, p, f, g) => {
        var tonnes = parseFloat(a)
        var std = parseFloat(b)
        var M = parseFloat(c)
        var P = parseFloat(p)
        var F = parseFloat(f)
        var resolution = g

        dispatch(comminution({
            'tonnes': tonnes, 'std': std, 'M': M, 'F': F, 'P': P, 'resolution': mine_layout.hours
        }))
    }

    // useEffect(() => {
    //     updateDataBackend('mine_layout', { key: 'comminution_tons_hours', value: mine_layout.comminution_tons / 24 })
    // }, [mine_layout.comminution_tons])

    // useEffect(() => {
    //     updateDataBackend('mine_layout', { key: 'comminution_tons', value: mine_layout.comminution_tons_hours * 24 })
    // }, [mine_layout.comminution_tons_hours])


    const explanation_text = "The model is based SMC Test benchmark for calculating the power consumption. Input parameters: \n" +
        "work_index: work index kWh / tonne\n" +
        "F_size: feed particle size micrometer\n" +
        "P_size: product particle size micrometer\n" +
        "tonnes: tonnes per unit time feed into machine\n" +
        "K: model constant\n" +
        "Reference: Using the SMC Test to Predict Comminution Circuit Performance (smctesting.com) "

    return (
        <div className="fullHeight content" id='export_comminution'>
            <div className="fleft" >

                <div className="heading-question">
                    <div className="fleft-heading">
                        <ABB.Heading level={4} text='Enter the daily comminution need of the mine' style={{ 'marginLeft': '16px' }} />
                    </div>
                    <div className="fright-heading">
                        <img src={question} style={{ width: '25%' }} onClick={() => window.alert(explanation_text)} />
                    </div>
                </div>
                <div className="heading-question">
                    {/* {mine_layout.hours == 'no' ? <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Produced tons per day [t]'}
                        value={mine_layout.comminution_tons}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'comminution_tons', value: emptyStringToNull(parseInt(v)) })}
                    /> : <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Produced tons per hour [t]'}
                        value={mine_layout.comminution_tons_hours}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'comminution_tons_hours', value: emptyStringToNull(parseInt(v)) })}
                    
                    />} */}

                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Produced tons per day [t]'}
                        value={mine_layout.comminution_tons}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'comminution_tons', value: emptyStringToNull(parseInt(v)) })}
                    />



                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'fine ore work index (kWh/tonne) '}
                        value={mine_layout.comminution_m}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'comminution_m', value: emptyStringToNull(parseInt(v)) })}

                    />


                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Product size [micrometer]'}
                        value={mine_layout.comminution_p}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'comminution_p', value: emptyStringToNull(parseInt(v)) })}

                    />

                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Feed size [micrometer]'}
                        value={mine_layout.comminution_f}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'comminution_f', value: emptyStringToNull(parseInt(v)) })}

                    />

                    <ABB.Input required={true} dataType='number'
                        style={{ 'marginLeft': '16px', 'marginRight': '16px', 'marginBottom': '16px', 'width': '90%' }}
                        label={'Comminution Standard Deviation [MWh/t]'}
                        value={mine_layout.comminution_std}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'comminution_std', value: emptyStringToNull(parseFloat(v)) })}

                    />




                    {mine_layout.comminution_std != null && mine_layout.comminution_m != null && mine_layout.comminution_p !== null && mine_layout.comminution_f !== null &&
                        <ABB.Button type="primary-red" shape="pill" text="Generate Comminution Timeseries" style={{ 'margin-top': '32px', 'margin-left': '50px' }} onClick={() => callComminution(mine_layout.comminution_tons, mine_layout.comminution_std, mine_layout.comminution_m, mine_layout.comminution_p, mine_layout.comminution_f)} />
                    }
                </div>
            </div>
            <div className="fright" >

                <Plot
                    data={[
                        {
                            x: xData,
                            y: yData,
                            type: 'scatter',
                            name: 'Optimized',
                            marker: { color: '#3366FF' }
                        },
                    ]}
                    layout={{ xaxis: { title: mine_layout.hours }, yaxis: { title: 'Energy [MWh]' }, title: title, width: '90%', height: '90%' }}
                />


            </div>
        </div>
    )
}

export default Comminution