import React, { useEffect, useCallback, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSelector, useDispatch } from 'react-redux'
import { fetchTimeseriesFiles, upload, setUploadStatus } from '../../slices/timeseriesFilesSlice'
import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css';

const Timeseries = (props) => {

    const dispatch = useDispatch()
    const ident = useSelector(state => state.site.ident)
    const id = ident.id
    const timeseriesFiles = useSelector(state => state.timeseriesFiles.timeseries)
    const status = useSelector(state => state.timeseriesFiles.status)
    const uploadstatus = useSelector(state => state.timeseriesFiles.uploadstatus)
    let errorDivRef = useRef(null);
    const mine_layout = useSelector(state => state.site.mine_layout)
    const units = []
    var files = []
    // const mine_layout = useSelector(state => state.site.mine_layout)
    // const selectionUnits = mine_layout.hours == 'days' ? 365 : 8762




    if (props.priceseries) {
        units.push({ symbol: ident.currency.symbol + '/kWh', code: ident.currency.code + '/kWh' })
        units.push({ symbol: ident.currency.symbol + '/MWh', code: ident.currency.code + '/MWh' })
        units.push({ symbol: ident.currency.symbol + '/Wh', code: ident.currency.code + '/Wh' })
    }
    else {
        units.push({ symbol: 'kW', code: 'kW' })
        units.push({ symbol: 'MW', code: 'MW' })
        units.push({ symbol: 'W', code: 'W' })
    }

    // function validator(acceptedFiles) {
    //     acceptedFiles.filter((file,index))
    // }

    const onDrop = useCallback(acceptedFiles => {
        // console.log(`Accepted Files ${acceptedFiles}`);
        // console.log(`Accepted Files ${Object.entries(acceptedFiles[1])}`);
        console.log(`Stringified accepted files ${JSON.stringify(acceptedFiles)}`);
        // console.log(`acceptedfile[0] ${acceptedFiles[0]}`)
        acceptedFiles[0].text().then(function (text) {
            // console.log(`.text used content ${text}`);
            const numRows = text.split('\n').length
            // console.log(`The file resolution ${numRows}`);
            // console.log(`The user resolution ${props.value}`);
            let resolution = (props.value == 'days') ? 367 : 8762
            // console.log(resolution);
            if (numRows == resolution) {
                files = acceptedFiles[0]
                if (uploadstatus === 'idle') {
                    let formData = new FormData();
                    formData.append('id', id)
                    acceptedFiles.forEach((file, index) => {
                        formData.append(`file${index}`, file)
                    })
                    dispatch(upload(formData))
                }
            } else {
                if (numRows==367 && resolution==8762) {
                    alert('User resolution is in "Hours", please upload file(s) in "Hourly" resolution.')
                }
                else if (numRows==8762 && resolution==367) {
                    alert('User resolution is in "Days", please upload file(s) in "Daily" resolution.')
                }
            }
        })
    }, [uploadstatus, id, dispatch])

    // const onDrop = useCallback(acceptedFiles => {
    //     console.log(`Accepted Files ${acceptedFiles}`);
    //     console.log(`Accepted Files ${Object.entries(acceptedFiles[1])}`);
    //     console.log(`Stringified accepted files ${JSON.stringify(acceptedFiles)}`);
    //     console.log(`acceptedfile[0] ${acceptedFiles[0]}`)
    //     acceptedFiles[0].text().then(function (text) {
    //         console.log(`.text used content ${text}`);
    //         const rows = text.split('\n')
    //         const numRows = rows.length
    //         console.log(`The rows of the input layer ${numRows}`);
    //         if (numRows == 367) {
    //             if (uploadstatus === 'idle') {
    //         //         let formData = new FormData();
    //         //         formData.append('id', id)
    //         //         acceptedFiles[0].filter((file, index) => {
    //         //             formData.append(`file${index}`, file)
    //         //         })
    //                 dispatch(upload(formData))}
    //             }
    //         }, [uploadstatus, id, dispatch])
    //     })
    //     // console.log(numRows);
    //     acceptedFiles.filter((file, index) => {
    //         console.log(`filtered accepted files${JSON.stringify(file)} and index ${index}`);
    //     })
    //     if (uploadstatus === 'idle') {
    //         let formData = new FormData();
    //         formData.append('id', id)
    //         files.filter((file, index) => {
    //             // console.log(typeof (file));
    //             // console.log(Object.keys(file));
    //             formData.append(`file${index}`, file)
    //         })
    //         dispatch(upload(formData))
    //         // alert(formData)
    //     }
    // }, [uploadstatus, id, dispatch])

    useEffect(() => {
        if (status === 'uninitialized') {
            dispatch(fetchTimeseriesFiles('/' + id))
        }
    }, [status, dispatch, id])

    useEffect(() => {
        if (uploadstatus === 'error') {
            errorDivRef.current.click()
        }
    }, [uploadstatus])







    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const createTimeseriesDescription = t => {
        const description = t.filename + " (" + t.start.slice(0, 10) + " -- " + t.end.slice(0, 10) + ")"
        return description
    }

    const dismissError = (dialog) => {
        dispatch(setUploadStatus({ 'uploadstatus': 'idle' }))
        dialog.close()
    }


    // timeseriesFiles.filter((file, index) => {
    //     console.log(file.values.length)
    // })

    const setTimeseries = (v) => {
        if (timeseriesFiles.filter(t => t.filename === v[0].value)[0].columns.length === 1) {
            props.onChange({ filename: v[0].value, column: timeseriesFiles.filter(t => t.filename === v[0].value)[0].columns[0], unit: null })
        } else {
            props.onChange({ filename: v[0].value, column: null, unit: null })
        }
    }

    // const map = new Map(Object.values(timeseriesFiles))
    // console.log(map);
    // console.log(Object.entries(Object.entries(timeseriesFiles)[0]))
    // let array = timeseriesFiles;
    // for (let i = 0; i < array.length; i++) {
    //     let dictionaryLength = Object.keys(array[i]).length;
    //     console.log(`dictionary ${i} has ${dictionaryLength} keys.`);
    // }
    return (
        <div>
            <ABB.WithDialog>
                <div ref={errorDivRef}></div>
                <ABB.Dialog
                    showCloseButton={false}
                    closeOnEscape={false}
                    closeOnLostFocus={false}
                    hideBackground={true}
                    title="Error"
                    standardButtonsOnBottom={[
                        { text: 'OK', type: 'primary-red', handler: (dialog) => dismissError(dialog) },
                    ]}
                    style={{ width: "800px" }}
                >
                    <main>
                        <p>An error occured while uploading. Did you select a valid CSV file?</p>
                    </main>
                </ABB.Dialog>
            </ABB.WithDialog>
            {!props.hideDropzone &&
                <div>
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        {timeseriesFiles == 0 && <p>To upload timeseries from CSV/TSV files, click or drag files.</p>}
                        {/* {status === 'idle' && uploadstatus === 'idle' && <p>To upload timeseries from CSV/TSV files, click or drag files.</p>} */}
                        {status === 'loaded' && uploadstatus === 'loading' && <ABB.LoadingIndicator color='grey' text='Upload running' />}
                        {status === 'loading' && <ABB.Spinner color='grey' size='large' />}
                        {status === 'error' && <p>There was an error connecting to the server. Please try again later.</p>}
                        {timeseriesFiles.length > 0 &&
                            <aside>
                                <p>Sucessfully uploaded {timeseriesFiles.length} {timeseriesFiles.length > 1 ? "files" : "file"}.</p>
                            </aside>}
                    </div>
                </div>}
            {(timeseriesFiles.length > 0 && !props.onlyDropzone) &&
                <div>
                    <ABB.Dropdown style={{ width: "100%" }} label={<React.Fragment>
                        Please select timeseries for {props.short}. {<ABB.Button style={{ marginTop: '8px' }} sizeClass='small' type='discreet-blue'
                            onClick={props.deleteSeries}
                            text={"Delete " + props.short + "."} />}
                    </React.Fragment>} required={true}
                        value={[{ value: props.value.filename }]}
                        onChange={setTimeseries}
                    >
                        {timeseriesFiles.map((t) => (
                            <ABB.DropdownOption key={t.filename} label={createTimeseriesDescription(t)} value={t.filename} />
                        ))}
                    </ABB.Dropdown>
                    {props.value.filename && timeseriesFiles.filter(t => t.filename === props.value.filename).length > 0 && timeseriesFiles.filter(t => t.filename === props.value.filename)[0].columns.length > 0 &&
                        <ABB.Dropdown style={{ width: "100%" }} required={true}
                            label={"Please select timeseries column from file " + props.value.filename}
                            value={[{ value: props.value.column }]}
                            onChange={v => props.onChange({ filename: props.value.filename, column: v[0].value, unit: props.value.unit })}
                        >
                            {timeseriesFiles.filter(t => t.filename === props.value.filename)[0].columns.map((c) => (
                                <ABB.DropdownOption key={c} label={c} value={c} />
                            ))}
                        </ABB.Dropdown>
                    }
                    {props.value.filename &&
                        <ABB.Dropdown style={{ width: "100%" }} label={"Please select timeseries unit"} required={true}
                            value={[{ value: props.value.unit }]}
                            onChange={v => props.onChange({ filename: props.value.filename, column: props.value.column, unit: v[0].value })}
                        >
                            {units.map((u) => (
                                <ABB.DropdownOption key={u} label={u.symbol} value={u.code} />
                            ))}
                        </ABB.Dropdown>
                    }
                </div>
            }
        </div>
    )
}

export default Timeseries