import React, { useState, useEffect } from 'react'
import {withRouter} from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { updateTopGraph } from '../../slices/siteSlice'
import { exportPNG } from '../../slices/siteSlice'

import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css'
import captureElementAsBase64 from './../pdf_export/png_export_function'

const RC = (props) => {

    const dispatch = useDispatch()
    const ident = useSelector(state => state.site.ident)
    const id = ident.id
    const topGraphStates = useSelector(state => state.site.topGraphStates)

    const export_png = (id, name) => {
        var base64String = 'not loaded yet'
        base64String = captureElementAsBase64(name).then(function (base64String) {
            dispatch(exportPNG({ 'id': id, 'name': name, 'img_string': base64String }))
            return base64String
        });
    }

    const activateTopGraphItem = item => {
        dispatch(updateTopGraph(item))
    
        // add delay and export
        setTimeout(() => {

            // now export figure
            if (item == 'graphA') {
                export_png(id, 'export_plot_LoadDistribution')
            }
            if (item == 'graphB') {
                export_png(id, 'export_plot_CostPerYear')
            }
            if (item == 'graphC') {
                export_png(id, 'export_plot_CumulativeCost')
            }
            if (item == 'graphD') {
                export_png(id, 'export_plot_EnergyFlows')
            }
        }, 100);
    }


    return (
        <ABB.AppTopNavi >
            <ABB.AppTopNaviItem text="Load Distribution"
                active={topGraphStates.graphA} onClick={() => activateTopGraphItem('graphA')} />
            <ABB.AppTopNaviItem text="Cost per Year"
                active={topGraphStates.graphB} onClick={() => activateTopGraphItem('graphB')} />
            <ABB.AppTopNaviItem text="Cumulative Cost"
                active={topGraphStates.graphC} onClick={() => activateTopGraphItem('graphC')} />   
            <ABB.AppTopNaviItem text="Energy Flows"
            active={topGraphStates.graphD} onClick={() => activateTopGraphItem('graphD')} /> 
        </ABB.AppTopNavi >
    )
}

export default withRouter(RC)