import React from 'react'
import '../../App.css'
import { useSelector} from 'react-redux'
import Plot from 'react-plotly.js'
import '@abb/abb-common-ux-react/styles.css'                     


//colors
var pv_l__color = '#D81159'

// helper functions

const GroupedBarChartTest = (props) =>{
    var plot1 = {
        x: ["Microwave", "Washing Machine", "Tv", "Vacuum Cleaner", "Hair Dryer"],
        y: [4, 5, 6, 1, 4],
        name: "2016",
        type: "bar",
        color: pv_l__color
    };
  
    var plot2 = {
        x: ["Microwave", "Washing Machine", "Tv", "Vacuum Cleaner", "Hair Dryer"],
        y: [12, 3, 5, 6, 2],
        name: "2017",
        type: "bar",
    };

  var data = [plot1, plot2];
    return(
        <Plot
        data={data}
        layout={ {width: 500, height: 500, title: 'Electronics Prices 2016/2017'} } 
        />
    )
}


const GroupedBarVolt = (props) =>{
    const gd_var = useSelector(state => state.site.gd_mine_demo)
    var volt_profile_max_res_max_load = gd_var.volt_profile_max_res_max_load
    var volt_profile_max_res_min_load = gd_var.volt_profile_max_res_min_load
    var volt_profile_max_res_zero_load = gd_var.volt_profile_max_res_zero_load
    var volt_profile_min_res_max_load = gd_var.volt_profile_min_res_max_load
    var volt_profile_min_res_min_load = gd_var.volt_profile_min_res_min_load
    var volt_profile_min_res_zero_load = gd_var.volt_profile_min_res_zero_load

    var bus_name = gd_var.bus_name

    var plot1 = {
        x: bus_name,
        y: volt_profile_max_res_max_load,
        name: "Sce: Max renew max load",
        type: "bar",
        marker: {color: '99d98c',}
    };

    var plot2 = {
        x: bus_name,
        y: volt_profile_max_res_min_load,
        name: "Sce: Max renew min load",
        type: "bar",
        marker: {color: '76c893',}
    };

    var plot3 = {
        x: bus_name,
        y: volt_profile_max_res_zero_load,
        name: "Sce: Max renew zero load",
        type: "bar",
        marker: {color: '52b69a',}
    };

    var plot4 = {
        x: bus_name,
        y: volt_profile_min_res_max_load,
        name: "Sce: Min renew max load",
        type: "bar",
        marker: {color: '34a0a4',}
    };

    var plot5 = {
        x: bus_name,
        y: volt_profile_min_res_min_load,
        name: "Sce: Min renew min load",
        type: "bar",
        marker: {color: '168aad',}
    };

    var plot6 = {
        x: bus_name,
        y: volt_profile_min_res_zero_load,
        name: "Sce: Min renew zero load",
        type: "bar",
        marker: {color: '1a759f',}
    };

    var data = [plot1, plot2, plot3, plot4, plot5, plot6];
    return(
        <Plot
        data={data}
        layout={ 
            {
                width: 1500, height: 500, 
                xaxis:{'title':'Bus name', 'range':bus_name},
                yaxis:{'title':'Voltage (p.u.)', 'range':[0.9, 1.1]},                
                title: 'Voltage profiles in different cases'}
         } 
        />
    )

}

const GroupedBarCurrent = (props) =>{
    const gd_var = useSelector(state => state.site.gd_mine_demo)


    var current_max_res_max_load = gd_var.current_max_res_max_load
    var current_max_res_min_load = gd_var.current_max_res_min_load
    var current_max_res_zero_load = gd_var.current_max_res_zero_load
    var current_min_res_max_load = gd_var.current_min_res_max_load
    var current_min_res_min_load = gd_var.current_min_res_min_load
    var current_min_res_zero_load = gd_var.current_min_res_zero_load

    var line_name = gd_var.line_name

    var plot1 = {
        x: line_name,
        y: current_max_res_max_load,
        name: "Sce: Max renew max load",
        type: "bar",
        marker: {color: '99d98c',}
    };

    var plot2 = {
        x: line_name,
        y: current_max_res_min_load,
        name: "Sce: Max renew min load",
        type: "bar",
        marker: {color: '76c893',}
    };

    var plot3 = {
        x: line_name,
        y: current_max_res_zero_load,
        name: "Sce: Max renew zero load",
        type: "bar",
        marker: {color: '52b69a',}
    };

    var plot4 = {
        x: line_name,
        y: current_min_res_max_load,
        name: "Sce: Min renew max load",
        type: "bar",
        marker: {color: '34a0a4',}
    };

    var plot5 = {
        x: line_name,
        y: current_min_res_min_load,
        name: "Sce: Min renew min load",
        type: "bar",
        marker: {color: '168aad',}
    };

    var plot6 = {
        x: line_name,
        y: current_min_res_zero_load,
        name: "Sce: Min renew zero load",
        type: "bar",
        marker: {color: '1a759f',}
    };

    var data = [plot1, plot2, plot3, plot4, plot5, plot6];
    return(
        <Plot
        data={data}
        layout={ 
            {
                width: 1500, height: 500, 
                yaxis:{'title':'Load percentage (%)', 'range':[0, 100]},
                xaxis:{'title':'Line name', 'range':line_name},
                title: 'Load percentage of each line in different cases'}
         } 
        />
    )

}




export {GroupedBarChartTest, GroupedBarVolt, GroupedBarCurrent}