// Import necessary React components and hooks
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css'
import { updateIdent } from '../../slices/identSlice'

// Create a functional component for the login page
const LoginPage = () => {

  const dispatch = useDispatch()
  
  // State variables to store user input (username and password)
  const [role, setRole] = useState('');
  const ident = useSelector(state => state.ident)   

  //List of default roles
  const defaultRoles = ['Sales', 'Engineering', 'Developer'];

  // Function to handle form submission
  const handleRole = (e) => {
    e.preventDefault();

    // Perform registration here (e.g., send data to a server)
    dispatch(updateIdent({key: 'role', value: role}))

  };

  return (
    
    <div>
      <h2>Registration</h2>
      {/* Form for user input */}
      <form onInput={handleRole}>
        {/* Username input */}

        <ABB.Input dataType="text" label="User" placeholder="Name"
            value={ident.userName} required={true}
            onValueChange={e => dispatch(updateIdent({key: 'userName', value: e}))}/>

        {/* Role input with dropdown menu*/}
        <ABB.Dropdown label={"Role"} style={{ 'width': '100%' }} placeholder="Please select a role" required={true} searchable={true}
            value={role}
            onChange={(e) => setRole(e)}
        >
            <ABB.DropdownOption label={'Sales'} value={'Sales'} />
            <ABB.DropdownOption label={'Engineering'} value={'Engineering'} />
                  <ABB.DropdownOption label={'Developer'} value={'Developer'} />
                  <ABB.DropdownOption label={'Other'} value={'Other'} />
        </ABB.Dropdown>
        
        {/* User e-mail input */}
        <ABB.Input dataType="text" label="E-mail" placeholder="e-mail "
            value={ident.email} required={true}
            onValueChange={e => dispatch(updateIdent({key: 'email', value: e}))}/>

        {/* Customer Company input */}
        <ABB.Input dataType="text" label="Company" placeholder="Company"
            value={ident.customerCompany} required={true}
            onValueChange={e => dispatch(updateIdent({key: 'customerCompany', value: e}))}/>

        {/* Customer name input */}
        {/*<ABB.Input dataType="text" label="Responsible" placeholder="Name"*/}
        {/*    value={ident.customerName} required={true}*/}
        {/*    onValueChange={e => dispatch(updateIdent({key: 'customerName', value: e}))}/>*/}

        {/* Customer email input */}
        {/*<ABB.Input dataType="text" label="E-mail" placeholder="e-mail"*/}
        {/*    value={ident.customerEmail} required={true}*/}
        {/*    onValueChange={e => dispatch(updateIdent({key: 'customerEmail', value: e}))}/>*/}

        {/* Customer Mine Name input */}
        <ABB.Input dataType="text" label="Mine" placeholder="Name"
            value={ident.siteName} required={true}
            onValueChange={e => dispatch(updateIdent({key: 'siteName', value: e}))}/>

        {/* Mine Location input */}
        <ABB.Input dataType="text" label="Location" placeholder="Country"
            value={ident.siteCountry} required={true}
            onValueChange={e => dispatch(updateIdent({key: 'siteCountry', value: e}))}/>
      </form>
    </div>
 
  );
};

// Export the component for use in other parts of the application
export default LoginPage;